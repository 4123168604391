import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { IonSkeletonText, IonButton } from "@ionic/react";
import React, { useCallback, useState } from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import useTranslation from "../../context/LanguageProvider";
import { CaseViewDto } from "../../models/Case";
import Icon from "../Icon";

interface Props {
  $case: CaseViewDto;
  onChange: () => void;
}

const AcceptDeclineCase: React.FC<Props> = ({ $case, onChange }) => {
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const acceptCase = useCallback(() => {
    setLoading(true);
    return apiPost<boolean>(`case/acceptCase?id=${$case.id}`, {})
      .then(onChange)
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [$case.id, apiPost, handleError, onChange]);

  const declineCase = useCallback(() => {
    setLoading(true);
    return apiPost<boolean>(`case/declineCase?id=${$case.id}`, {})
      .then(onChange)
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [$case.id, apiPost, handleError, onChange]);

  return (
    <>
      {loading ? (
        <IonSkeletonText animated />
      ) : (
        <>
          <div className="ion-text-center ion-margin-top">
            <h4>{t("cases.newOrder")}</h4>
            <IonButton onClick={acceptCase} color="success">
              <Icon icon={faCheck} />
              {t("accept")}
            </IonButton>
            <IonButton onClick={declineCase} color="danger">
              <Icon icon={faTimes} />
              {t("decline")}
            </IonButton>
          </div>
        </>
      )}
    </>
  );
};

export default AcceptDeclineCase;
