import { IonGrid, IonCol, IonRow, IonSkeletonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import useTranslation from "../../context/LanguageProvider";
import { ProductionLogTableUrlProps } from "../../models/UrlProps";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { Pie } from "@ant-design/charts";
import useCurrency from "../../hooks/useCurrency";
import { ProductionLogStatsDto, TaskCountDto } from "../../models/Case";
import useColorPalette from "../../hooks/useColorPalette";
import NoResults from "../NoResults";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";

const pieChartConfig = {
  appendPadding: 10,
  radius: 0.8,
  label: { type: "outer" },
  interactions: [{ type: "element-active" }]
};

interface Props extends ProductionLogTableUrlProps {
  hidden?: boolean;
}

const ProductionLogsStats: React.FC<Props> = React.memo(
  ({ hidden, ...urlProps }) => {
    const [getColor, setValues] = useColorPalette<TaskCountDto>(t => t.name);
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [productionLogs, setProductionLogs] =
      useState<ProductionLogStatsDto>();
    const { t } = useTranslation();
    const { apiPost } = useApi();
    const { handleError } = useNotificationContext();
    const { formatWithCurrencySign } = useCurrency();

    useEffect(() => {
      setLoadingLogs(true);
      apiPost<ProductionLogStatsDto>("productionLog/getStats", urlProps)
        .then(setProductionLogs)
        .catch(handleError)
        .finally(() => setLoadingLogs(false));
    }, [
      urlProps.fromDate,
      urlProps.toDate,
      urlProps.doctorId,
      urlProps.employeeId,
      urlProps.search,
      urlProps.taskId,
      urlProps.status,
      apiPost,
      handleError
    ]);

    useEffect(() => {
      if (!productionLogs) return;

      setValues(productionLogs.tasks);
    }, [productionLogs, setValues]);

    return (
      <IonGrid hidden={hidden}>
        {productionLogs && !loadingLogs ? (
          <>
            <IonRow class="box" hidden={productionLogs.tasks.length === 0}>
              {productionLogs.tasks.filter(v => v.total).length > 0 && (
                <IonCol size-xs="12" size-md="6">
                  <h3 className="ion-text-center">
                    {t("workLog.totalPieceRate")}
                  </h3>
                  <Pie
                    {...pieChartConfig}
                    angleField="total"
                    colorField="name"
                    color={getColor}
                    data={productionLogs.tasks.filter(v => v.total)}
                    meta={{
                      total: {
                        alias: t("workLog.totalPieceRate"),
                        formatter: v => formatWithCurrencySign(v)
                      },
                      name: { alias: t("task.title") }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              )}
              {productionLogs.tasks.length > 0 && (
                <IonCol size-xs="12" size-md="6">
                  <h3 className="ion-text-center">
                    {t("workLog.finishedSteps")}
                  </h3>
                  <Pie
                    {...pieChartConfig}
                    angleField="count"
                    colorField="name"
                    color={getColor}
                    data={productionLogs.tasks}
                    meta={{
                      count: { alias: t("workLog.finishedSteps") },
                      name: { alias: t("task.title") }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              )}
            </IonRow>
          </>
        ) : (
          <IonSkeletonText animated />
        )}
        {productionLogs && productionLogs.tasks.length === 0 && (
          <NoResults title={t("noData")} />
        )}
      </IonGrid>
    );
  },
  (pe, ne) =>
    pe.fromDate === ne.fromDate &&
    pe.toDate === ne.toDate &&
    pe.employeeId === ne.employeeId &&
    pe.doctorId === ne.doctorId &&
    pe.search === ne.search &&
    pe.taskId === ne.taskId &&
    pe.status === ne.status &&
    pe.hidden === ne.hidden
);

export default withPermission(
  ProductionLogsStats,
  Permission.ProductionLogsTableRead
);
