import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const getKeyValue = (location: string, key: string) =>
  new URLSearchParams(location).get(key) ?? "";

const useUrlSearchParams = (key: string) => {
  const location = useLocation();
  const [value, setValue] = useState(getKeyValue(location.search, key));

  useEffect(() => {
    setValue(getKeyValue(location.search, key));
  }, [key, location.search]);

  return value;
};

export default useUrlSearchParams;
