import { IonGrid, IonCol, IonRow, IonSkeletonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import useTranslation from "../../context/LanguageProvider";
import { MaterialsUrlProps } from "../../models/UrlProps";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { Column, Pie } from "@ant-design/charts";
import useColorPalette, { colorPalette } from "../../hooks/useColorPalette";
import NoResults from "../NoResults";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import { useAuthContext } from "../../context/AuthProvider";
import {
  MaterialsChartPointDto,
  MaterialsStatsDto
} from "../../models/Materials";
import useLab from "../../context/LabProvider";

const pieChartConfig = {
  appendPadding: 10,
  angleField: "quantity",
  colorField: "name",
  radius: 0.8,
  label: { type: "outer" },
  interactions: [{ type: "element-active" }]
};

interface Props extends MaterialsUrlProps {
  hidden?: boolean;
}

const MaterialsStats: React.FC<Props> = React.memo(
  ({ hidden, ...urlProps }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<MaterialsStatsDto>();
    const { t } = useTranslation();
    const { apiPost } = useApi();
    const { user } = useAuthContext();
    const { handleError } = useNotificationContext();
    const [getColor, setValues] = useColorPalette<MaterialsChartPointDto>(
      t => t.name
    );
    const { materials } = useLab();

    useEffect(() => {
      setLoading(true);
      apiPost<MaterialsStatsDto>("material/getStats", urlProps)
        .then(setData)
        .catch(handleError)
        .finally(() => setLoading(false));
    }, [
      urlProps.fromDate,
      urlProps.toDate,
      urlProps.doctorId,
      urlProps.materialId,
      apiPost,
      handleError
    ]);

    useEffect(() => {
      setValues(materials.map(material => ({ ...material, quantity: 0 })));
    }, [setValues, materials]);

    return (
      <IonGrid hidden={hidden}>
        {data && !loading ? (
          <>
            {data.totals.length > 0 && (
              <IonRow class="box">
                {data.totals.map(total => (
                  <IonCol
                    size-xs="12"
                    size-md={data.totals.length === 1 ? "12" : "6"}
                    size-xl={data.totals.length === 1 ? "12" : "4"}
                  >
                    <h3 className="ion-text-center">
                      {t("total")} ({total.unit})
                    </h3>
                    <Pie
                      loading={loading}
                      {...pieChartConfig}
                      data={total.materials}
                      color={getColor}
                      meta={{
                        quantity: { alias: total.unit },
                        name: { alias: t("name") }
                      }}
                      legend={{
                        layout: "horizontal",
                        position: "top"
                      }}
                    />
                  </IonCol>
                ))}
              </IonRow>
            )}

            {data.byDate.length > 0 && (
              <IonRow class="box">
                {data.byDate.map(total => (
                  <IonCol
                    size-xs="12"
                    size-md={data.byDate.length === 1 ? "12" : "6"}
                    size-xl={data.byDate.length === 1 ? "12" : "4"}
                  >
                    <h3 className="ion-text-center">
                      {t("materials.title")} ({total.unit})
                    </h3>
                    <Column
                      xField="date"
                      yField="quantity"
                      isGroup
                      seriesField="name"
                      data={total.materials}
                      color={getColor}
                      meta={{
                        quantity: { alias: total.unit },
                        date: { alias: t("date") },
                        name: { alias: t("name") }
                      }}
                      legend={{
                        layout: "horizontal",
                        position: "top"
                      }}
                    />
                  </IonCol>
                ))}
              </IonRow>
            )}

            {!user?.doctorId && data.byDoctor.length > 0 && (
              <IonRow class="box">
                <IonCol size-xs="12" size-md="12">
                  <h3 className="ion-text-center">
                    {t("stats.materialsByDoctor")}
                  </h3>
                  <Pie
                    {...pieChartConfig}
                    data={data.byDoctor}
                    color={colorPalette}
                    meta={{
                      quantity: { alias: t("quantity") },
                      name: { alias: t("doctor.title") }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              </IonRow>
            )}

            {data && data.byDate.length === 0 && (
              <NoResults title={t("noData")} />
            )}
          </>
        ) : (
          <IonSkeletonText animated />
        )}
      </IonGrid>
    );
  },
  (pe, ne) =>
    pe.fromDate === ne.fromDate &&
    pe.toDate === ne.toDate &&
    pe.doctorId === ne.doctorId &&
    pe.materialId === ne.materialId &&
    pe.hidden === ne.hidden
);

export default withPermission(MaterialsStats, Permission.MaterialsRead);
