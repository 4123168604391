import React from "react";
import { useAuthContext } from "../../context/AuthProvider";

interface Props {
  children: React.ReactNode;
}

const HideForDoctor: React.FC<Props> = ({ children }) => {
  const { user } = useAuthContext();

  // return component if it is not a doctor
  if (user && !user.doctorId) return <>{children}</>;

  return null;
};

export default HideForDoctor;
