import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import ModalWrapper from "../ModalWrapper";
import useLab from "../../context/LabProvider";
import { CaseStatus } from "../../models/Case";
import Icon from "../Icon";
import { faSms } from "@fortawesome/free-solid-svg-icons";
import InfoBox from "../InfoBox";

interface Props {
  caseId: number;
  caseStatus: CaseStatus;
  drAddress: string;
  drClinicName: string;
  drName: string;
  onCancel: () => void;
  showCaseCourierModal: boolean;
}

const CaseCourierModal: React.FC<Props> = ({
  caseId,
  caseStatus,
  drAddress,
  drClinicName,
  drName,
  onCancel,
  showCaseCourierModal
}) => {
  const { handleError, showSuccessToast } = useNotificationContext();
  const { apiPost } = useApi();
  const { t } = useTranslation();
  const { couriers, lab } = useLab();
  // const { user } = useAuthContext();

  const [courierId, setCourierId] = useState<number>();
  const [sms, setSms] = useState<string>("");

  useEffect(() => {
    if (couriers.length > 0) setCourierId(couriers[0].id);
  }, [couriers]);

  useEffect(() => {
    let sms = couriers.find(c => c.id === courierId)?.smsTemplate ?? "";
    if (lab) {
      const labInfo = lab.address ? lab.name + ", " + lab.address : lab.name;
      const drInfoName = drClinicName !== "" ? drClinicName : drName;
      const drInfo = drAddress ? drInfoName + ", " + drAddress : drInfoName;

      if (caseStatus === CaseStatus.ReadyForShippingToDr)
        setSms(
          sms
            .replace("[sender_address]", labInfo)
            .replace("[receiver_address]", drInfo)
        );
      else if (caseStatus === CaseStatus.ReadyForShippingToLab) {
        setSms(
          sms
            .replace("[sender_address]", drInfo)
            .replace("[receiver_address]", labInfo)
        );
      }
    }
  }, [courierId, lab, drAddress, drName, drClinicName, caseStatus, couriers]);

  const sendSms = () => {
    apiPost("case/notifyCourier", {
      caseId: caseId,
      courierId: courierId,
      notificationMethod: 1, //1 == sms, 2 = email
      message: sms
    })
      .then(() => {
        showSuccessToast(t("courier.smsSent"));
        onCancel();
      })
      .catch(handleError)
      .finally();
  };
  return (
    <ModalWrapper
      modalOpened={showCaseCourierModal}
      dismiss={onCancel}
      modal="caseCourier"
    >
      <IonModal isOpen={showCaseCourierModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>{t("courier.notifyCourier")}</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={sendSms}>{t("courier.sendSms")}</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem className="ion-no-padding ion-margin-top">
            <IonLabel position="stacked">{t("courier.chooseCourier")}</IonLabel>
            <IonSelect
              interface="action-sheet"
              value={courierId}
              onIonChange={e => setCourierId(e.detail.value)}
            >
              {couriers.map(c => (
                <IonSelectOption key={c.id} value={c.id}>
                  {c.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItem className="wion-margin-top">
            <IonLabel position="stacked">
              <p>{t("courier.smsContent")} *</p>
            </IonLabel>
            <IonTextarea
              value={sms}
              rows={6}
              onIonChange={e => setSms(e.detail.value!)}
            />
          </IonItem>
          {lab && lab.address === "" && (
            <InfoBox text={t("courier.emptyLabAddress")} />
          )}
          {drAddress === "" && <InfoBox text={t("courier.emptyDrAddress")} />}

          <div className="ion-margin-top ion-text-center">
            <IonButton
              color="secondary"
              disabled={sms.length === 0}
              fill="solid"
              onClick={e => {
                sendSms();
              }}
            >
              <Icon icon={faSms} />
              {t("courier.sendSms")}
            </IonButton>
            <IonButton color="secondary" fill="outline" onClick={onCancel}>
              {t("cancel")}
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default CaseCourierModal;
