import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonButton
} from "@ionic/react";
import React from "react";
import useTranslation from "../context/LanguageProvider";

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Error 404</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h2>{t("error.404")}</h2>
        <IonButton routerLink="/" className="ion-justify-content-center">
          {t("goHome")}
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
