import {
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  useIonViewWillEnter,
  IonText,
  IonSkeletonText
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { faPlus, faTruckMoving } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import { Permission } from "../../models/Permissions";
import withPermission from "../../data/withPermission";
import Can from "../Can";
import SearchPage from "../SearchPage";
import useTranslation from "../../context/LanguageProvider";
import NoResults from "../NoResults";
import CourierUpsertModal from "./CourierUpsertModal";
import {
  CourierListDto,
  CourierNotificationMethod
} from "../../models/Courier";
import InfoBox from "../InfoBox";

const initialModalData = {
  id: 0,
  name: "",
  notificationMethod: CourierNotificationMethod.Sms,
  active: true,
  phone: "",
  email: ""
};

const Couriers: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [couriesrs, setCouriers] = useState<CourierListDto[]>();

  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const [modalData, setModalData] = useState({
    ...initialModalData,
    emailTemplate: t("courier.defaultEmailTemplate"),
    smsTemplate: t("courier.defaultSmsTemplate")
  });
  const fetchData = useCallback(() => {
    setCouriers(undefined);
    apiGet<CourierListDto[]>(`courier/getAll`)
      .then(data => {
        setCouriers(data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch(handleError);
  }, [apiGet, handleError]);

  useIonViewWillEnter(() => fetchData());

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const hideModal = useCallback(() => {
    setShowUpsertModal(false);
    setModalData({
      ...initialModalData,
      emailTemplate: t("courier.defaultEmailTemplate"),
      smsTemplate: t("courier.defaultSmsTemplate")
    });
  }, [t]);

  return (
    <IonPage>
      <SearchPage
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        title={t("courier.titlePlural")}
        addNewPermission={Permission.LabUpdate}
        onAddClicked={() => setShowUpsertModal(true)}
        hideMenuButton
      >
        <IonList className="box lines" hidden={!couriesrs?.length}>
          {couriesrs ? (
            couriesrs.map((d, i) => (
              <IonItem
                button
                detail
                key={i}
                onClick={() => {
                  setModalData({
                    id: d.id,
                    name: d.name,
                    notificationMethod: d.notificationMethod,
                    active: d.active,
                    email: d.email ?? "",
                    phone: d.phone ?? "",
                    emailTemplate:
                      d.emailTemplate ?? t("courier.defaultEmailTemplate"),
                    smsTemplate:
                      d.smsTemplate ?? t("courier.defaultSmsTemplate")
                  });
                  setShowUpsertModal(true);
                }}
              >
                <Icon
                  slot="start"
                  className={d.active ? "successColor" : "warningColor"}
                  icon={faTruckMoving}
                />
                <IonLabel className="ion-text-wrap">
                  <IonText title={d.name}>{d.name}</IonText>
                  <IonText color="medium">
                    {/* <p>{d.description}</p> */}
                  </IonText>
                </IonLabel>
              </IonItem>
            ))
          ) : (
            <IonSkeletonText animated />
          )}
        </IonList>
        <InfoBox text={t("courier.notificationsInfo")}></InfoBox>

        <NoResults
          title={couriesrs && couriesrs.length === 0 ? t("courier.noData") : ""}
        >
          <Can permission={Permission.LabUpdate}>
            <IonButton
              color="secondary"
              onClick={() => setShowUpsertModal(true)}
            >
              <Icon icon={faPlus} />
              {t("courier.addCourier")}
            </IonButton>
          </Can>
        </NoResults>

        <CourierUpsertModal
          initialData={modalData}
          showModal={showUpsertModal}
          onSuccess={() => {
            fetchData();
            showSuccessToast(t("saved"));
            hideModal();
          }}
          onCancel={hideModal}
        />
      </SearchPage>
    </IonPage>
  );
};

export default withPermission(Couriers, Permission.LabUpdate);
