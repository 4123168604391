import React, { useEffect, useState } from "react";
import { OptionsType, OptionTypeBase } from "react-select";
import Creatable from "react-select/creatable";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";
import useTranslation from "../context/LanguageProvider";
import PagedData from "../models/PagedData";
import { useNotificationContext } from "../context/NotificationProvider";
import { IonSkeletonText } from "@ionic/react";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

interface SelectOption extends OptionTypeBase {
  label: string;
  value: string;
}

interface Props<T> {
  value?: number;
  initialId?: number;
  defaultOptions?: T[];
  pageSize?: number;
  placeholder: string;
  create?: (inputValue: string) => void;
  loadOptions: (search: string, page: number) => Promise<PagedData<T>>;
  getOption?: (id: number) => Promise<T>;
  onChange: (id: number | undefined) => void;
  getId: (data: T) => number;
  getLabel: (data: T) => string;
}

const PaginateSelect: <T>(p: Props<T>) => React.ReactElement<Props<T>> = <T,>({
  value,
  initialId,
  defaultOptions,
  placeholder,
  pageSize,
  create,
  loadOptions,
  getOption,
  onChange,
  getId,
  getLabel
}: Props<T>) => {
  const { t } = useTranslation();
  const { handleError } = useNotificationContext();

  const [options, setOptions] = useState<OptionsType<SelectOption>>();
  const [initialData, setInitialData] = useState<SelectOption>();
  const [selectedData, setSelectedData] = useState<SelectOption>();

  const [isLoading, setIsLoading] = useState(false);

  const valueToOption = (e: T) => ({
    label: getLabel(e),
    value: getId(e).toString()
  });

  useEffect(() => {
    if (initialId && getOption)
      getOption(initialId)
        .then(d => setInitialData(valueToOption(d)))
        .catch(handleError);
  }, [initialId]);

  useEffect(() => {
    if (value && getOption)
      getOption(value)
        .then(d => {
          setSelectedData(valueToOption(d));
          onChange(value);
        })
        .catch(handleError);
  }, [value]);

  useEffect(
    () => defaultOptions && setOptions(defaultOptions.map(valueToOption)),
    [defaultOptions]
  );

  const handleLoadOptions = (search: string, previousOptions: any) => {
    setIsLoading(true);

    return loadOptions(
      search,
      previousOptions.length > 0
        ? previousOptions.length / (pageSize ?? 10) + 1
        : 1
    )
      .then(data => ({
        options: data.data.map(valueToOption),
        hasMore: data.page < data.pageCount
      }))
      .finally(() => setIsLoading(false));
  };

  const handleOnCreate = (inputValue: string) => {
    create && create(inputValue);
    return Promise.resolve();
  };

  const handleChange = (newValue: any) => {
    setSelectedData(newValue);
    onChange(newValue ? parseInt(newValue.value) : undefined);
  };

  const props = {
    placeholder,
    classNamePrefix: "rselect",
    isClearable: true,
    cacheOptions: true,
    defaultValue: initialData,
    defaultOptions: options,
    value: selectedData,
    isLoading: isLoading,
    loadOptions: handleLoadOptions,
    onChange: handleChange,
    noOptionsMessage: () => t("noRecords"),
    loadingMessage: () => t("loading")
  };
  return initialId && !initialData ? (
    <IonSkeletonText animated />
  ) : create ? (
    <CreatableAsyncPaginate
      {...props}
      SelectComponent={Creatable}
      onCreateOption={handleOnCreate}
      formatCreateLabel={(o: any) => `${t("permissions.types.create")}: "${o}"`}
    />
  ) : (
    <AsyncPaginate {...props} />
  );
};

export default PaginateSelect;
