import {
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  useIonViewWillEnter
} from "@ionic/react";
import React, { useCallback, useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { faPlus, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import { searchMatch } from "../../data/stringHelpers";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import { MaterialDto } from "../../models/Materials";
import SearchPage from "../SearchPage";
import useTranslation from "../../context/LanguageProvider";
import NoResults from "../NoResults";
import MaterialUpsertModal from "./MaterialUpsertModal";

const initialData = {
  id: 0,
  name: "",
  code: "",
  unit: "#"
};

const Materials: React.FC = () => {
  const [showNoResults, setShowNoResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);

  const [materials, setMaterials] = useState<MaterialDto[]>([]);
  const { showSuccessToast } = useNotificationContext();

  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const { t } = useTranslation();

  const fetchMaterials = useCallback(() => {
    apiGet<MaterialDto[]>("material/getAll")
      .then(materials => {
        if (materials.length === 0) {
          setShowNoResults(true);
        } else {
          setShowNoResults(false);
          setMaterials(materials);
        }
      })
      .catch(handleError);
  }, [apiGet, handleError]);

  useIonViewWillEnter(() => {
    fetchMaterials();
  });

  return (
    <IonPage>
      <SearchPage
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        title={t("materials.title")}
        addNewPermission={Permission.MaterialsCreate}
        onAddClicked={() => setShowAddModal(true)}
        hideMenuButton
      >
        <IonList className="box lines" hidden={showNoResults}>
          {materials
            .filter(d => searchMatch(searchTerm, d.name))
            .map(d => (
              <IonItem key={d.id} button detail routerLink={`material/${d.id}`}>
                <Icon slot="start" icon={faPuzzlePiece} />
                <IonLabel>{d.name}</IonLabel>
                <p slot="end">{d.code !== undefined && d.code}</p>
              </IonItem>
            ))}
        </IonList>
        <NoResults title={showNoResults ? t("materials.noData") : ""} />
        <Can permission={Permission.MaterialsCreate}>
          <div className="ion-text-center">
            <IonButton onClick={() => setShowAddModal(true)}>
              <Icon icon={faPlus} />
              {t("materials.new")}
            </IonButton>
          </div>
        </Can>

        <MaterialUpsertModal
          showModal={showAddModal}
          onCancel={() => setShowAddModal(false)}
          onSuccess={() => {
            fetchMaterials();
            showSuccessToast(t("materials.added"));
            setShowAddModal(false);
          }}
          initialData={initialData}
        />
      </SearchPage>
    </IonPage>
  );
};

export default withPermission(Materials, Permission.MaterialsRead);
