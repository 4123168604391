import { IonGrid, IonCol, IonRow, IonSkeletonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import useTranslation from "../../context/LanguageProvider";
import { CasesUrlProps } from "../../models/UrlProps";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { Column, Pie } from "@ant-design/charts";
import { CasesStatsDto } from "../../models/Case";
import { colorPalette } from "../../hooks/useColorPalette";
import NoResults from "../NoResults";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import { useAuthContext } from "../../context/AuthProvider";

const pieChartConfig = {
  appendPadding: 10,
  angleField: "count",
  colorField: "name",
  radius: 0.8,
  label: { type: "outer" },
  interactions: [{ type: "element-active" }]
};

interface Props extends CasesUrlProps {
  hidden?: boolean;
}

const CasesStats: React.FC<Props> = React.memo(
  ({ hidden, ...urlProps }) => {
    const [loadingCases, setLoadingCases] = useState(false);
    const [cases, setCases] = useState<CasesStatsDto>();
    const { t, tProductType } = useTranslation();
    const { apiPost } = useApi();
    const { user } = useAuthContext();
    const { handleError } = useNotificationContext();

    useEffect(() => {
      setLoadingCases(true);
      apiPost<CasesStatsDto>("case/getStats", urlProps)
        .then(setCases)
        .catch(handleError)
        .finally(() => setLoadingCases(false));
    }, [
      urlProps.fromDate,
      urlProps.toDate,
      urlProps.doctorId,
      urlProps.search,
      urlProps.status,
      apiPost,
      handleError
    ]);

    return (
      <IonGrid hidden={hidden}>
        {cases && !loadingCases ? (
          <>
            {cases.byDate.length > 0 && (
              <IonRow class="box">
                <IonCol size-xs="12" size-md="12">
                  <h3 className="ion-text-center">
                    {t("stats.numberOfCases")}
                  </h3>
                  <Column
                    xField="name"
                    yField="count"
                    data={cases.byDate}
                    color={colorPalette}
                    meta={{
                      count: { alias: t("quantity") },
                      name: { alias: t("date") }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              </IonRow>
            )}
            {!user?.doctorId && cases.byDoctor.length > 0 && (
              <IonRow class="box">
                <IonCol size-xs="12" size-md="12">
                  <h3 className="ion-text-center">
                    {t("stats.casesByDoctor")}
                  </h3>
                  <Pie
                    {...pieChartConfig}
                    data={cases.byDoctor}
                    color={colorPalette}
                    meta={{
                      count: { alias: t("quantity") },
                      name: { alias: t("doctor.title") }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              </IonRow>
            )}
            {cases.products.length > 0 && (
              <IonRow class="box">
                <IonCol size-xs="12" size-md="12">
                  <h3 className="ion-text-center">{t("products.title")}</h3>
                  <Pie
                    {...pieChartConfig}
                    data={cases.products}
                    color={colorPalette}
                    meta={{
                      count: { alias: t("quantity") },
                      name: { alias: t("products.title") }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              </IonRow>
            )}
            {cases.extras.length > 0 && (
              <IonRow class="box">
                <IonCol size-xs="12" size-md="12">
                  <h3 className="ion-text-center">{t("extras.title")}</h3>
                  <Pie
                    {...pieChartConfig}
                    data={cases.extras}
                    color={colorPalette}
                    meta={{
                      count: { alias: t("quantity") },
                      name: { alias: t("extras.title") }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              </IonRow>
            )}
            {cases.productTypes.length > 0 && (
              <IonRow class="box">
                <IonCol size-xs="12" size-md="12">
                  <h3 className="ion-text-center">
                    {t("products.productTypes")}
                  </h3>
                  <Pie
                    {...pieChartConfig}
                    data={cases.productTypes.map(s => ({
                      count: s.count,
                      name: tProductType(s.type)
                    }))}
                    color={colorPalette}
                    meta={{
                      count: { alias: t("quantity") },
                      name: { alias: t("products.productTypes") }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              </IonRow>
            )}
            {cases && cases.byDate.length === 0 && (
              <NoResults title={t("noData")} />
            )}
          </>
        ) : (
          <IonSkeletonText animated />
        )}
      </IonGrid>
    );
  },
  (pe, ne) =>
    pe.fromDate === ne.fromDate &&
    pe.toDate === ne.toDate &&
    pe.doctorId === ne.doctorId &&
    pe.search === ne.search &&
    pe.status === ne.status &&
    pe.hidden === ne.hidden
);

export default withPermission(CasesStats, Permission.CasesTableRead);
