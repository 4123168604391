import React from "react";
import { EventContentArg } from "@fullcalendar/core";
import CalendarCaseDto, {
  CalendarProductionLogDto
} from "../../models/CalendarDto";
import { CaseStatus } from "../../models/Case";
import CaseEvent from "./CaseEvent";
import CaseGroupEvent from "./CaseGroupEvent";
import PlEvent from "./PlEvent";
import { GroupEvents } from "./useCalendar";

interface Props extends EventContentArg {
  onStatusChange: (caseId: number, status: CaseStatus) => void;
}
const CalendarEvent: React.FC<Props> = React.memo(
  (e: Props) => {
    const caseData =
      e.event.extendedProps.type === "case_event"
        ? (e.event.extendedProps as CalendarCaseDto)
        : undefined;
    const plData =
      e.event.extendedProps.type === "pl_event"
        ? (e.event.extendedProps as CalendarProductionLogDto)
        : undefined;
    const groupData =
      e.event.extendedProps.events !== undefined
        ? (e.event.extendedProps as GroupEvents)
        : undefined;

    return (
      <>
        {groupData ? (
          <CaseGroupEvent
            groupData={groupData}
            onStatusChange={e.onStatusChange}
          />
        ) : plData ? (
          <PlEvent plData={plData} />
        ) : caseData ? (
          <CaseEvent
            caseData={caseData}
            date={e.event.extendedProps.date}
            onStatusChange={e.onStatusChange}
          />
        ) : null}
      </>
    );
  },
  (pe, ne) => {
    if (
      pe.event.extendedProps.type === "case_event" &&
      ne.event.extendedProps.type === "case_event"
    ) {
      const pData = pe.event.extendedProps as CalendarCaseDto;
      const nData = ne.event.extendedProps as CalendarCaseDto;
      return pData === nData;
    }
    if (
      pe.event.extendedProps.type === "pl_event" &&
      ne.event.extendedProps.type === "pl_event"
    ) {
      const pData = pe.event.extendedProps as CalendarProductionLogDto;
      const nData = ne.event.extendedProps as CalendarProductionLogDto;
      return pData === nData;
    }
    if (
      pe.event.extendedProps.events !== undefined &&
      ne.event.extendedProps.events !== undefined
    ) {
      const pData = pe.event.extendedProps as GroupEvents;
      const nData = ne.event.extendedProps as GroupEvents;
      return pData === nData;
    }
    return false;
  }
);

export default CalendarEvent;
