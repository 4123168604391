import React, { useEffect, useRef } from "react";
import { ProductType, ToothProps } from "../../models/Teeth";
import {
  BridgeSvgPaths,
  VisilLowerJaw,
  VisilUpperJaw
} from "../../data/arches/SvgPaths";
import { CaseProductDto } from "../../models/Case";
import BridgeConnection from "./BridgeConnection";
import { toothIdInUpper } from "../../hooks/useDentalNotation";
import ToothComp from "./Tooth";

interface Props {
  products: CaseProductDto[];
  teeth: ToothProps[];
  exposeGetSvg?: (getSvg: () => string | undefined) => void;
  onToothClick?: (toothId: number) => void;
}

const ArchesSvg: React.FC<Props> = ({
  products,
  teeth,
  exposeGetSvg,
  onToothClick
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (exposeGetSvg) exposeGetSvg(() => svgRef.current?.outerHTML);
  }, []);

  return (
    <svg ref={svgRef} version="1.1" x="0px" y="0px" viewBox="0 0 535 712">
      {products
        .filter(p => p.productTypeId === ProductType.Bridge)
        .map(bridge =>
          bridge.toothIds.map((t, i) => (
            <BridgeConnection
              key={bridge.toothIds[i] + bridge.toothIds[i + 1]}
              path={BridgeSvgPaths[bridge.toothIds[i] + bridge.toothIds[i + 1]]}
            />
          ))
        )}
      {products
        .filter(p => p.productTypeId === ProductType.Visil)
        .map(visil => (
          <path
            key={visil.toothIds[0]}
            d={
              toothIdInUpper(visil.toothIds[0]) ? VisilUpperJaw : VisilLowerJaw
            }
            strokeWidth={1}
            stroke="#eee"
            fill="#C0C0C0"
            strokeLinecap="round"
          />
        ))}
      {teeth.map(tooth => (
        <ToothComp
          key={tooth.id}
          {...tooth}
          onClick={id => onToothClick && onToothClick(id)}
        />
      ))}
    </svg>
  );
};

export default ArchesSvg;
