import React, { useEffect, useRef } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { buildTeeth } from "./archesReducer";
import useDentalNotation from "../../hooks/useDentalNotation";
import CaseViewDto, { CaseFormDto } from "../../models/Case";
import ProductList from "./ProductList";
import ArchesSvg from "./ArchesSvg";
import ConditionList from "./ConditionList";

interface Props {
  $case: CaseViewDto | CaseFormDto;
  exposeGetSvg: (getSvg: () => string | undefined) => void;
}

const ArchesView: React.FC<Props> = ({ $case, exposeGetSvg }) => {
  const { teeth: starterTeeth } = useDentalNotation();
  const svgRef = useRef<SVGSVGElement>(null);
  const { teeth, products, teethConditions } = buildTeeth(
    {
      teeth: starterTeeth,
      products: $case.products,
      teethConditions: $case.teethCondition,
      anySelected: false,
      productSelected: 0,
      anyConditionSelected: false,
      canBuildBridge: false
    },
    false
  );

  useEffect(() => {
    if (exposeGetSvg) exposeGetSvg(() => svgRef.current?.outerHTML);
  }, []);

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol
            size-xs="12"
            size-sm="12"
            size-md="6"
            size-xl="3"
            className="ion-no-padding"
          >
            <ArchesSvg
              products={products}
              teeth={teeth}
              exposeGetSvg={exposeGetSvg}
            />
          </IonCol>
          <IonCol
            size-xs="12"
            size-sm="12"
            size-md="6"
            size-xl="9"
            className="ion-no-padding"
          >
            <ProductList products={products} showActions={false} />
            <ConditionList conditions={teethConditions} showActions={false} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default ArchesView;
