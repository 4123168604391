import {
  IonContent,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonSelectOption
} from "@ionic/react";
import React, { useCallback, useState } from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import useTranslation from "../../context/LanguageProvider";
import { MaterialDto } from "../../models/Materials";
import ModalWrapper from "../ModalWrapper";
import ButtonTextIcon from "../ButtonTextIcon";
import Form from "../form/Form";
import FormInput from "../form/FormIonInput";
import FormIonSelect from "../form/FormIonSelect";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showModal: boolean;
  initialData: MaterialDto;
}

const MaterialUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showModal
}) => {
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tRequired, tPlaceholder } = useTranslation();

  const [submit, setSubmit] = useState<() => void>(() => {});
  const [isSubmitting, setSubmitting] = useState(false);

  const upsert = useCallback(
    (material: MaterialDto) => {
      setSubmitting(true);
      apiPost<number>("material/upsert", material)
        .then(onSuccess)
        .catch(handleError)
        .finally(() => setSubmitting(false));
    },
    [apiPost, handleError]
  );

  return (
    <ModalWrapper
      modalOpened={showModal}
      dismiss={onCancel}
      modal="MaterialUpsert"
    >
      <IonModal isOpen={showModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            {initialData && (
              <IonTitle>
                {initialData.id > 0 ? t("materials.edit") : t("materials.new")}
              </IonTitle>
            )}
            <IonButtons slot="primary">
              <IonButton onClick={submit} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Form
            initialData={initialData}
            onSubmit={upsert}
            submitButtonText={t("save")}
            exposeSubmit={setSubmit}
            setSubmitting={setSubmitting}
          >
            <FormInput hidden name="id" label="id" />
            <FormInput
              name="name"
              label={t("name")}
              rules={{
                required: tRequired("name")
              }}
              placeholder={tPlaceholder("name")}
            />
            <FormInput
              name="code"
              label={t("materials.code")}
              placeholder={tPlaceholder("materials.code")}
            />
            <FormIonSelect
              name="unit"
              label={t("materials.unit")}
              rules={{
                required: tRequired("materials.unit")
              }}
              placeholder={tPlaceholder("materials.unit")}
              interface="action-sheet"
            >
              {["ml", "dl", "g", "#"].map(unit => (
                <IonSelectOption key={unit} value={unit}>
                  {unit}
                </IonSelectOption>
              ))}
            </FormIonSelect>
            <FormInput
              name="description"
              label={t("description")}
              placeholder={tPlaceholder("description")}
            />
          </Form>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default MaterialUpsertModal;
