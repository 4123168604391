import React from "react";
import useTranslation from "../../context/LanguageProvider";
import FormInput from "./FormIonInput";

interface Props {
  required?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  hidden?: boolean;
}

const EmailFormItem: React.FC<Props> = ({
  required,
  disabled,
  hideLabel,
  hidden
}) => {
  const { t, tInterpolated, tRequired, tPlaceholder } = useTranslation();

  return (
    <FormInput
      hidden={hidden ?? false}
      name="email"
      label={hideLabel ? "" : t("email")}
      rules={{
        required: required ? tRequired("email") : undefined,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: tInterpolated("invalid", {
            prop: t("emailAddress")
          })
        }
      }}
      disabled={disabled}
      placeholder={tPlaceholder("email")}
      type="email"
      inputmode="email"
    />
  );
};

export default EmailFormItem;
