export const isNumber = (x: any): x is number => typeof x === "number";

export const parseNumber = (input: string | null | undefined) => {
  if (!input) return undefined;
  const result = parseFloat(input);
  if (isNaN(result)) return undefined;

  return result;
};

export const getEnumValues = <TEnum>(enumObj: TEnum) =>
  (Object.keys(enumObj) as Array<keyof TEnum>)
    .filter(p => typeof enumObj[p] === "number")
    .map(p => enumObj[p]);

export const getEnumValuesUnfiltered = <TEnum>(enumObj: TEnum) =>
  (Object.keys(enumObj) as Array<keyof TEnum>).map(p => enumObj[p]);

/**
 * Devides y by x and returns both the quotient and the remainder.
 * @param x The divisor.
 * @param y The dividend.
 */
export const divRem = (x: number, y: number) => {
  var div = Math.trunc(y / x);
  var rem = y % x;

  return { div, rem };
};

/**
 * Creates a random integer number between min and max
 * @param min The minimum number allowed
 * @param max The maximum number, but excluded
 */
export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

/**
 * Creates a random integer number that can be used as an ID
 * @param min List of existing IDs that makes sure the new ID does not repeat
 */
export const getRandomId = (existingIds: number[]) => {
  while (true) {
    const suggestion = getRandomInt(1, 2000000000);
    if (!existingIds.includes(suggestion)) return suggestion;
  }
};
