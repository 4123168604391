import React from "react";
import { ProductType, ToothCondition, ToothProps } from "../../models/Teeth";

interface Props extends ToothProps {
  onClick: (id: number) => void;
}

const getFill = (type?: ProductType, condition?: ToothCondition) => {
  switch (type) {
    case ProductType.Crown:
    case ProductType.Inlay:
      return "#1EAE91";
    case ProductType.Bridge:
      return "#00ADB5";
    case ProductType.Denture:
      return "#6D75CA";
    case ProductType.Visil:
      return "#92949C";
    case ProductType.Miscellaneous:
      return "#E6B40F";
    default:
      return condition === ToothCondition.NoTooth ||
        condition === ToothCondition.DeadTooth ||
        condition === ToothCondition.Implant
        ? "#ffffff"
        : "#d5d5d5";
  }
};

const Tooth: React.FC<Props> = ({
  onClick,
  id,
  name,
  selected,
  type,
  condition,
  path,
  gum,
  deadTooth,
  implant,
  nameTextTransform
}) => {
  const pathData = path;
  const stroke = selected
    ? "#810c0c"
    : type === ProductType.Bridge && condition !== ToothCondition.NoTooth
    ? ""
    : "#333";
  const strokeWidth =
    selected || condition === ToothCondition.NoTooth ? "3" : "1";
  const strokeDasharray =
    condition === ToothCondition.NoTooth || condition === ToothCondition.Implant
      ? "3, 3"
      : "";
  const fillOpacity = condition === ToothCondition.NoTooth ? "1" : "1";
  const fill = getFill(type, condition);

  return (
    <>
      {type === ProductType.Denture && (
        <path
          d={gum}
          fill="#ffc1cc"
          opacity={fillOpacity}
          onClick={() => onClick(id)}
        >
          <title>{name}</title>
        </path>
      )}
      {type === ProductType.Visil && (
        <path d={gum} fill="#C0C0C0" onClick={() => onClick(id)}>
          <title>{name}</title>
        </path>
      )}
      <path
        d={pathData}
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
        strokeDasharray={strokeDasharray}
        // fillOpacity={0.8}
        onClick={() => onClick(id)}
      >
        <title>{name}</title>
      </path>
      {condition === ToothCondition.DeadTooth && (
        <path
          d={deadTooth}
          fill="#d5d5d5"
          stroke={stroke}
          onClick={() => onClick(id)}
        >
          <title>{name}</title>
        </path>
      )}
      {condition === ToothCondition.Implant && (
        <path d={implant} stroke={stroke} onClick={() => onClick(id)}>
          <title>{name}</title>
        </path>
      )}
      <text xmlns="http://www.w3.org/2000/svg" transform={nameTextTransform}>
        {name}
      </text>
    </>
  );
};

export default Tooth;
