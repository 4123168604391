import FullCalendar, { EventInput } from "@fullcalendar/react";
import { RefresherEventDetail } from "@ionic/react";
import moment from "moment";
import { useCallback, useReducer, useRef, useState } from "react";
import useLab from "../../context/LabProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import { getCaseColor, getPlDate } from "../../data/calendarColorHelpers";
import useLocalStorage from "../../hooks/useLocalStorage";
import CalendarCaseDto, {
  CalendarProductionLogDto
} from "../../models/CalendarDto";
import { CaseStatus, ProductionLogStatus } from "../../models/Case";

export type CalendarType = "cases" | "productionLogs";

export interface ShowDates {
  shipDate: boolean;
  appointmentDate: boolean;
}

export interface PlUrlProps {
  employeeId?: number;
  status?: ProductionLogStatus;
  taskId?: number;
  getUnassigned?: boolean;
}

export interface CalendarEventInput extends EventInput {
  eventGroup?: FilteredEvent;
  caseEvent?: CalendarCaseDto;
  plEvent?: CalendarProductionLogDto;
}

export interface FilteredEvent {
  time: string;
  events: CalendarEventInput[];
}
export interface GroupEvents extends EventInput {
  time: string;
  caseColors: string[];
  events: CalendarEventInput[];
}

export type CalendarView = "timeGridWeek" | "dayGridMonth";

type CalendarActions =
  | {
      type: "setEvents";
      events: CalendarEventInput[];
    }
  | { type: "selectDate"; date: Date }
  | { type: "changeEventStatus"; id: number; status: CaseStatus };

const toTimeString = (s: string) => moment(s).format("HH:mm");

interface CalendarData {
  selectedDate: Date;
  events?: CalendarEventInput[];
  groupEvents?: GroupEvents[];
  filteredEvents?: FilteredEvent[];
}

const createCaseEvents = (data: CalendarCaseDto[], showDates: ShowDates) =>
  data
    .filter(e => e.status !== CaseStatus.Cancelled)
    .flatMap<CalendarEventInput>(e => {
      const dates = Object.entries(showDates)
        .filter(d => d[1] === true)
        .map(d => {
          return {
            type: d[0],
            date: d[0] === "appointmentDate" ? e.appointmentDate : e.shipDate
          };
        });

      return dates
        .filter(d => d.date)
        .map(d => {
          const caseEvent = {
            ...e,
            appointmentDate:
              d.type === "appointmentDate" ? e.appointmentDate : undefined,
            shipDate: d.type !== "appointmentDate" ? e.shipDate : undefined
          };
          return {
            title: `${e.caseName}`,
            allDay: false,
            date: d.date,
            id: `${d.date}-${d.type}-${e.caseId}`,
            caseEvent,
            extendedProps: {
              ...caseEvent,
              date: d.date,
              dateType: d.type,
              type: "case_event"
            }
          };
        });
    });

const createPlEvents = (data: CalendarProductionLogDto[]) =>
  data.map<CalendarEventInput>(e => ({
    title: e.task ? e.task.name : e.notes ? e.notes : "",
    allDay: false,
    date: getPlDate(e),
    id: `${getPlDate(e)}-pl_event-${e.id}`,
    plEvent: { ...e, date: getPlDate(e) },
    extendedProps: { ...e, date: getPlDate(e), type: "pl_event" }
  }));

const createGroupEvents = (events: CalendarEventInput[]) =>
  events
    .filter(e => e.caseEvent)
    .sort((a, b) => {
      return (
        new Date(a.date as string).getTime() -
        new Date(b.date as string).getTime()
      );
    })
    .reduce<GroupEvents[]>((result, item) => {
      const time = toTimeString(item.date as string);
      // ignore seconds
      const date = (item.date as string).slice(0, -4).trim() + ":00Z";

      const group = result.find(g => g.date === date);
      if (group)
        return [
          ...result.filter(g => g.date !== date),
          { ...group, events: [...group.events, item] }
        ];

      return [
        ...result,
        {
          time: time,
          title: "",
          allDay: false,
          date: date,
          id: `${date}`,
          events: [item],
          caseColors: []
          // extendedProps: { ...item, type: "group_event" }
        }
      ];
    }, [])
    .map(e => ({
      ...e,
      extendedProps: e,
      caseColors: e.events.map(cd => getCaseColor(cd.caseEvent!).bgClass)
    }));

const createFilteredEvents = (
  events: CalendarEventInput[],
  selectedDate: Date
) =>
  events
    .filter(
      e =>
        new Date(e.date as string).toDateString() ===
        selectedDate.toDateString()
    )
    .sort(
      (a, b) =>
        new Date(a.date as string).getTime() -
        new Date(b.date as string).getTime()
    )
    .reduce<FilteredEvent[]>((result, item) => {
      const time = toTimeString(item.date as string);

      const group = result.find(g => g.time === time);
      if (group)
        return [
          ...result.filter(g => g.time !== time),
          { ...group, events: [...group.events, item] }
        ];

      return [...result, { time, events: [item] }];
    }, []);

const groupAndFilterEvents = (
  events: CalendarEventInput[],
  selectedDate: Date
) => {
  const groupEvents = createGroupEvents(events);
  const filteredEvents = createFilteredEvents(events, selectedDate);
  return { events, groupEvents, filteredEvents };
};

const changeEventStatus = (
  events: CalendarEventInput[],
  action: { id: number; status: CaseStatus }
) =>
  events.map(e =>
    e.caseEvent?.caseId === action.id
      ? {
          ...e,
          caseEvent: { ...e.caseEvent, status: action.status },
          extendedProps: { ...e.extendedProps, status: action.status }
        }
      : e.plEvent?.caseId === action.id
      ? {
          ...e,
          plEvent: {
            ...e.plEvent,
            case: { ...e.plEvent.case, status: action.status },
            extendedProps: {
              ...e.extendedProps,
              case: { ...e.extendedProps?.case, status: action.status }
            }
          }
        }
      : e
  );

const calendarReducer = (state: CalendarData, action: CalendarActions) => {
  switch (action.type) {
    case "setEvents":
      return {
        ...state,
        ...groupAndFilterEvents(action.events, state.selectedDate)
      };
    case "selectDate":
      const sdFilteredEvents = state.events
        ? createFilteredEvents(state.events, action.date)
        : undefined;
      return {
        ...state,
        selectedDate: action.date,
        filteredEvents: sdFilteredEvents
      };
    case "changeEventStatus":
      if (!state.events) return state;

      return {
        ...state,
        ...groupAndFilterEvents(
          changeEventStatus(state.events, action),
          state.selectedDate
        )
      };
  }
  return state;
};

const useCalendar = () => {
  const calendarRef = useRef<FullCalendar>(null);
  const { isMtLab } = useLab();
  const [loading, setLoading] = useState(false);
  const { apiGet, apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const [state, dispatch] = useReducer(calendarReducer, {
    selectedDate: new Date()
  });
  const [currentMonth, setCurrentMonth] = useState<string>();
  const [showDates, setShowDates] = useLocalStorage<ShowDates>(
    "calendarShowDates",
    {
      appointmentDate: !isMtLab,
      shipDate: isMtLab
    }
  );

  const fetchEvents = useCallback(
    (
      startDate: string,
      showDates: ShowDates,
      calendarType: CalendarType,
      plUrlProps: PlUrlProps,
      refreshEvent?: CustomEvent<RefresherEventDetail>
    ) => {
      setLoading(true);

      const endDate = moment(startDate).endOf("month").utc().toISOString();

      if (calendarType === "cases") {
        apiGet<CalendarCaseDto[]>(
          `calendar/getCases?start=${startDate}&end=${endDate}`
        )
          .then(data =>
            dispatch({
              type: "setEvents",
              events: createCaseEvents(data, showDates)
            })
          )
          .catch(handleError)
          .finally(() => {
            if (refreshEvent) refreshEvent.detail.complete();
            setLoading(false);
          });
      } else if (calendarType === "productionLogs") {
        apiPost<CalendarProductionLogDto[]>("calendar/getLogs", {
          start: startDate,
          end: endDate,
          status: plUrlProps?.status,
          employeeId: plUrlProps?.employeeId,
          taskId: plUrlProps?.taskId,
          getUnassigned: plUrlProps?.employeeId ? false : true
        })
          .then(data =>
            dispatch({
              type: "setEvents",
              events: createPlEvents(data)
            })
          )
          .catch(handleError)
          .finally(() => {
            if (refreshEvent) refreshEvent.detail.complete();
            setLoading(false);
          });
      }
    },
    [apiGet, handleError, apiPost]
  );

  const fireSelectDate = useCallback((date: Date) => {
    calendarRef.current?.getApi().select(date);
  }, []);

  const currentMonthUpdate = useCallback(
    (
      showDates: ShowDates,
      calendarType: CalendarType,
      plUrlProps: PlUrlProps,
      date?: Date
    ) => {
      const newDate = (date ? moment(date.toISOString()) : moment())
        .utc()
        .startOf("month")
        .toISOString();

      if (!currentMonth || newDate !== currentMonth) {
        setCurrentMonth(newDate);

        // if the selected date is in the current month, we do nothing
        const today = moment();
        const start = moment(newDate);
        // if the month is current, we select today, otherwise the first of the month
        fireSelectDate(
          new Date(
            start.year() === today.year() && start.month() === today.month()
              ? today.toISOString()
              : newDate
          )
        );

        fetchEvents(newDate, showDates, calendarType, plUrlProps);
      }
    },
    [currentMonth, fetchEvents, fireSelectDate]
  );

  const onShowDatesChange = useCallback(
    (
      showDates: ShowDates,
      calendarType: CalendarType,
      plUrlProps: PlUrlProps
    ) => {
      setShowDates(showDates);
      const currentDate = calendarRef.current?.getApi().getDate();
      if (!currentDate) return;

      const startDate = moment(currentDate.toISOString())
        .utc()
        .startOf("month")
        .toISOString();

      fetchEvents(startDate, showDates, calendarType, plUrlProps);
    },
    [fetchEvents, setShowDates]
  );

  const setSelectedDate = useCallback((date: Date) => {
    dispatch({ type: "selectDate", date });
  }, []);

  const changeEventStatus = useCallback((id: number, status: CaseStatus) => {
    dispatch({ type: "changeEventStatus", id, status });
  }, []);

  return {
    changeEventStatus,
    currentMonthUpdate,
    fetchEvents,
    onShowDatesChange,
    fireSelectDate,
    setSelectedDate,
    showDates,
    calendarRef,
    selectedDate: state.selectedDate,
    events: state.events,
    groupEvents: state.groupEvents,
    filteredEvents: state.filteredEvents,
    loading
  };
};

export default useCalendar;
