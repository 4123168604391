import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonBackButton,
  IonButtons,
  IonCol,
  IonRow,
  IonGrid,
  IonLoading,
  useIonViewDidEnter
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import Logo from "../Logo";
import Form from "../form/Form";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import useApi from "../../data/Api";
import { useAuthContext } from "../../context/AuthProvider";
import { useHistory } from "react-router";
import FormInput from "../form/FormIonInput";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUrlSearchParams from "../../hooks/useUrlSearchParams";
import InfoBox from "../InfoBox";

const AcceptInvitation: React.FC = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { resetUser, authenticated } = useAuthContext();
  const { apiPost } = useApi();
  const history = useHistory();
  const { showSuccessToast, handleError } = useNotificationContext();
  const { t, tInterpolated, tRequired, tPlaceholder } = useTranslation();
  const codeFromUrl = useUrlSearchParams("code");
  const [codeFromStorage, setCodeFromStorage] = useLocalStorage(
    "invitationCode",
    codeFromUrl
  );
  const [codeToUse, setCodeToUse] = useState(codeFromUrl);

  useEffect(() => {
    setCodeToUse(codeFromUrl ? codeFromUrl : codeFromStorage);
  }, [codeFromUrl, codeFromStorage]);

  const acceptInvitation = useCallback(
    (data: { code: string }) => {
      setSubmitting(true);
      return apiPost<string>(`invitation/accept?code=${data.code}`, {})
        .then(labName => {
          // remove acccepted code from local storage
          setCodeFromStorage("");

          history.replace("");
          resetUser().then(() => {
            if (labName)
              showSuccessToast(
                tInterpolated("invitation.acceptedMessage", { labName })
              );
          });
        })
        .catch(e => {
          handleError(e);
          setSubmitting(false);
        });
    },
    [
      history,
      apiPost,
      handleError,
      resetUser,
      setCodeFromStorage,
      showSuccessToast,
      tInterpolated
    ]
  );

  useIonViewDidEnter(() => {
    if (isSubmitting) return;
    if (!authenticated) {
      // save url to local storage
      if (codeFromUrl && codeFromUrl !== codeFromStorage)
        setCodeFromStorage(codeFromUrl);
      history.replace("/login");
      return;
    }

    if (codeToUse) {
      acceptInvitation({ code: codeToUse });
    }
  }, [isSubmitting, authenticated, codeToUse]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>{t("invitation.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={isSubmitting} message={t("invitation.accepting")} />
        <IonGrid>
          <IonRow>
            <IonCol
              size-sm="10"
              offset-sm="1"
              size-md="6"
              offset-md="3"
              size-xl="4"
              offset-xl="4"
            >
              <Logo lightBg style={{ width: 100, margin: "0 auto" }} />
              <InfoBox text={t("joinLab.description")} />

              <Form
                onSubmit={acceptInvitation}
                submitButtonText={t("invitation.submit")}
                initialData={{ code: codeToUse }}
              >
                <FormInput
                  name="code"
                  label={t("invitation.code")}
                  rules={{
                    required: tRequired("invitation.code")
                  }}
                  placeholder={tPlaceholder("invitation.code")}
                  type="text"
                />
              </Form>
              <IonButton routerLink="new-lab" expand="full" fill="clear">
                {t("invitation.createLab")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AcceptInvitation;
