import CalendarCaseDto from "../models/CalendarDto";
import { CaseStatus, ProductionLogStatus } from "../models/Case";
import {
  dateDiffInDays,
  deadlineIn6h,
  timeInFuture,
  timeInPast
} from "./dateHelpers";

interface DateColor {
  colorClass: string;
  bgClass: string;
}

interface PlColorProps {
  status: ProductionLogStatus;
  started?: string;
  finished?: string;
  scheduled?: string;
  created?: string;
}

const getScheduleDateColor = (
  color:
    | "warning"
    | "success"
    | "danger"
    | "primary"
    | "grey"
    | "readyForSend"
    | "empty"
): DateColor => {
  switch (color) {
    case "warning":
    case "success":
    case "danger":
    case "primary":
    case "grey":
    case "readyForSend":
      return {
        colorClass: `${color}Color`,
        bgClass: `${color}BgColor`
      };
    default:
      return {
        colorClass: "",
        bgClass: ""
      };
  }
};

export const scheduledDayColorClasses = (date: Date): DateColor => {
  const dayDiff = dateDiffInDays(new Date(), date);
  if (dayDiff === 0) return getScheduleDateColor("warning");
  if (dayDiff > 0) return getScheduleDateColor("danger");

  return getScheduleDateColor("primary");
};

export const scheduledTimeColorClasses = (date: Date): DateColor => {
  const now = new Date();
  const diff = Math.round((now.getTime() - date.getTime()) / (1000 * 60));
  //1440 minutes in one day
  if (diff < 0 && diff > -1440) return getScheduleDateColor("warning");
  if (diff > 0) return getScheduleDateColor("danger");

  return getScheduleDateColor("empty");
};

export const getPlColor = (data: PlColorProps): DateColor => {
  const getScheduledColor = () => {
    if (data.scheduled) {
      if (timeInPast(new Date(data.scheduled)))
        return getScheduleDateColor("danger");
      if (deadlineIn6h(new Date(data.scheduled)))
        return getScheduleDateColor("warning");
    }
    return getScheduleDateColor("grey");
  };

  switch (data.status) {
    case ProductionLogStatus.Scheduled:
      return getScheduledColor();

    case ProductionLogStatus.Started:
      return getScheduledColor();

    case ProductionLogStatus.Done:
      return getScheduleDateColor("success");
    case ProductionLogStatus.Failed:
      return getScheduleDateColor("danger");
    default:
      return getScheduleDateColor("grey");
  }
};

export const getCaseColor = (data: CalendarCaseDto) => {
  const eventDate = new Date(data.appointmentDate ?? data.shipDate ?? "");

  if (data.tryoutId) {
    if (data.received) return getScheduleDateColor("success");
    if (data.shipped) return getScheduleDateColor("success");

    if (timeInPast(eventDate) && !data.shipped)
      return getScheduleDateColor("danger");

    if (deadlineIn6h(new Date(eventDate)))
      return getScheduleDateColor("warning");
  } else {
    // finish date
    if (
      data.shipDate &&
      !data.appointmentDate &&
      data.status === CaseStatus.ReadyForShippingToDr
    ) {
      return getScheduleDateColor("readyForSend");
    }
    if (timeInPast(eventDate)) {
      if (![CaseStatus.Finished, CaseStatus.Cancelled].includes(data.status))
        return getScheduleDateColor("danger");
      if ([CaseStatus.Finished, CaseStatus.Cancelled].includes(data.status))
        return getScheduleDateColor("success");
    } else if (
      deadlineIn6h(new Date(eventDate)) &&
      ![CaseStatus.Finished].includes(data.status)
    ) {
      return getScheduleDateColor("warning");
    } else if (timeInFuture(eventDate) && data.status === CaseStatus.Finished) {
      return getScheduleDateColor("success");
    }
  }
  return getScheduleDateColor("grey");
};

export const getCaseDotColor = (data: CalendarCaseDto) => {
  const today = new Date();
  const eventDate = new Date(data.appointmentDate ?? data.shipDate ?? "");

  const diffDays = dateDiffInDays(today, eventDate);

  if (data.tryoutId) {
    // tryout
    if (diffDays > 0) {
      if (!data.shipped) return "tryout-not-shipped-dot";
      return data.received ? "tryout-received-dot" : "tryout-shipped-dot";
    }
    if (diffDays === 0 || diffDays === -1) return "tryout-today-dot";
    return "tryout-dot";
  } else {
    // finish date
    if (diffDays > 0) {
      return [CaseStatus.Finished].includes(data.status)
        ? "finish-date-made-dot"
        : [
            CaseStatus.OnHold,
            CaseStatus.InProgress,
            CaseStatus.OutOfLab
          ].includes(data.status)
        ? "finish-date-missed-dot"
        : "";
    }
    if (diffDays === 0 || diffDays === -1) return "finish-date-today-dot";
    return "finish-date-dot";
  }
};

export const getPlDate = (data: PlColorProps) => {
  if (data.finished) return data.finished;
  if (data.started) return data.scheduled ? data.scheduled : data.started;
  if (data.scheduled) return data.scheduled;
  if (data.created) return data.created;
  return "";
};
