import React from "react";
import {
  faCog,
  faDownload,
  faEdit,
  faFilter,
  faPaperPlane,
  faPlus,
  faPrint,
  faSave,
  faSpinner,
  faStamp,
  faTag,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import Icon from "./Icon";
import useTranslation from "../context/LanguageProvider";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

type ButtonTypes =
  | "cancel"
  | "createNew"
  | "add"
  | "addCase"
  | "edit"
  | "delete"
  | "save"
  | "filter"
  | "download"
  | "certificate"
  | "label"
  | "print"
  | "send"
  | "settings"
  | "newProduct";

interface Props {
  button: ButtonTypes;
  loading?: boolean;
  iconClass?: string;
  xlHide?: boolean;
}

const getProps = (button: ButtonTypes) => {
  switch (button) {
    case "cancel":
      return {
        icon: faTimes,
        title: "cancel"
      };
    case "createNew":
      return {
        icon: faPlus,
        title: "createNew"
      };
    case "save":
      return {
        icon: faSave,
        title: "save"
      };
    case "add":
      return {
        icon: faPlus,
        title: "add"
      };
    case "addCase":
      return {
        icon: faPlus,
        title: "cases.new"
      };
    case "edit":
      return {
        icon: faEdit,
        title: "edit"
      };
    case "delete":
      return {
        icon: faTrash,
        title: "delete"
      };
    case "filter":
      return {
        icon: faFilter,
        title: "filters"
      };
    case "download":
      return {
        icon: faDownload,
        title: "saveAsPdf"
      };
    case "label":
      return {
        icon: faTag,
        title: "cases.label"
      };
    case "print":
      return {
        icon: faPrint,
        title: "print"
      };
    case "certificate":
      return {
        icon: faStamp,
        title: "cases.certificateOfConformity"
      };
    case "send":
      return {
        icon: faPaperPlane,
        title: "send"
      };
    case "settings":
      return {
        icon: faCog,
        title: "settings"
      };
    case "newProduct":
      return {
        icon: faPlus,
        title: "products.new"
      };
  }
};

const ButtonTextIcon: React.FC<Props> = ({
  button,
  loading,
  iconClass,
  xlHide
}) => {
  const { t } = useTranslation();
  const { icon, title } = getProps(button);

  return (
    <>
      <span className="buttonTextIcon">
        {loading ? (
          <Icon spin icon={faSpinner} />
        ) : (
          <Icon icon={icon} className={iconClass ? iconClass : ""} />
        )}
      </span>
      <span className={xlHide ? "ion-hide-xl-down" : "ion-hide-sm-down"}>
        {t(title)}
      </span>
    </>
  );
};

export default ButtonTextIcon;
