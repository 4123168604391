import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { useAntdLocale } from "../context/AntdProvider";
import useTranslation from "../context/LanguageProvider";

const { RangePicker } = DatePicker;

interface DateRange {
  start?: string;
  end?: string;
}

interface Props {
  onChange: (v: DateRange) => void;
  onBlur?: () => void;
  start?: string;
  end?: string;
}

const thisWeekMoment: [Moment, Moment] = [
  moment().startOf("isoWeek"),
  moment().endOf("isoWeek")
];

const thisMonthMoment: [Moment, Moment] = [
  moment().startOf("month"),
  moment().endOf("month")
];

const thisYearMoment: [Moment, Moment] = [
  moment().startOf("year"),
  moment().endOf("year")
];

export const thisMonth = {
  fromDate: thisMonthMoment[0].toISOString(),
  toDate: thisMonthMoment[1].toISOString()
};

const PopupDateRangePicker: React.FC<Props> = ({
  onChange,
  onBlur,
  start,
  end
}) => {
  const { locale } = useAntdLocale();
  const { t } = useTranslation();
  const [ranges, setRanges] = useState<Record<string, [Moment, Moment]>>({});

  useEffect(() => {
    const ranges: Record<string, [Moment, Moment]> = {};
    ranges[t("dates.thisWeek")] = thisWeekMoment;
    ranges[t("dates.thisMonth")] = thisMonthMoment;
    ranges[t("dates.thisYear")] = thisYearMoment;
    setRanges(ranges);
  }, [t]);

  return (
    <RangePicker
      allowClear
      allowEmpty={[true, true]}
      locale={locale}
      format={locale.lang.dateFormat}
      defaultValue={[start ? moment(start) : null, end ? moment(end) : null]}
      onChange={r => {
        if (!r) {
          onChange({ start: undefined, end: undefined });
        } else {
          const range = {
            start: r[0] ? r[0].toISOString() : undefined,
            end: r[1] ? r[1].toISOString() : undefined
          };
          if (range.start !== start || range.end !== end) onChange(range);
        }
      }}
      onBlur={onBlur}
      ranges={ranges}
      inputReadOnly
    />
  );
};

export default PopupDateRangePicker;
