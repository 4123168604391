import { useCallback, useEffect, useState } from "react";
import useLab from "../context/LabProvider";
import { getCurrencySymbol } from "../models/Currency";

export const formatCurrencyValue = (value: number, decimals: number = 2) =>
  value.toFixed(decimals);

export const formatWithCurrency = (
  value?: number,
  currency?: string,
  currencySign?: string,
  decimals: number = 2
) => {
  if (value === undefined) return "";

  const formattedValue = formatCurrencyValue(value, decimals);
  if (!currency) return formattedValue;

  // Symbol to the left of the amount, no space
  if (currency === "USD" || currency === "GBP" || currency === "SGD")
    return `${currencySign}${formattedValue}`;

  // Symbol to the left of the amount, non-breaking space
  if (
    currency === "ZAR" ||
    currency === "CHF" ||
    currency === "TWD" ||
    currency === "NZD" ||
    currency === "NOK" ||
    currency === "PHP" ||
    currency === "MXN" ||
    currency === "MYR" ||
    currency === "KRW" ||
    currency === "JPY" ||
    currency === "ILS" ||
    currency === "INR" ||
    currency === "HKD" ||
    currency === "DKK" ||
    currency === "CNY" ||
    currency === "BRL" ||
    currency === "CAD" ||
    currency === "AUD" ||
    currency === "ARS"
  )
    return `${currencySign} ${formattedValue}`;

  // Symbol to the right of the amount, non-breaking space
  return `${formattedValue} ${currencySign}`;
};

const useCurrency = () => {
  const { lab } = useLab();
  const [currency, setCurrency] = useState<string>();
  const [currencySign, setCurrencySign] = useState<string>();

  useEffect(() => {
    setCurrency(lab?.currency);
    setCurrencySign(getCurrencySymbol(lab?.currency));
  }, [lab?.currency]);

  const formatWithCurrentCurrency = useCallback(
    (value?: number) => formatWithCurrency(value, currency, currencySign),
    [currency, currencySign]
  );

  return { formatWithCurrencySign: formatWithCurrentCurrency, currencySign };
};

export default useCurrency;
