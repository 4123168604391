import { getToken, deleteToken as fbDeleteToken } from "firebase/messaging";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import useApi from "./Api";
import {
  firebaseMessaging,
  notificationsSupported as nsPromise
} from "./firebase";
import { VAPID_KEY } from "./firebaseConfig";

export enum TokenSentToServer {
  No = 0,
  Yes = 1
}

interface ContextProps {
  notificationsSupported: boolean;
  notificationsGranted: boolean;
  tokenSentToServer: TokenSentToServer;
  userConsent: "default" | "denied" | "granted";
  loading: boolean;
  checkToken: () => Promise<void>;
  deleteToken: () => Promise<void>;
}

const PushContext = React.createContext<ContextProps>({
  notificationsSupported: false,
  notificationsGranted: false,
  tokenSentToServer: TokenSentToServer.No,
  userConsent: "default",
  loading: false,
  checkToken: () => Promise.resolve(),
  deleteToken: () => Promise.resolve()
});

const usePushNotifications = () => useContext(PushContext);

const PushProvider: React.FC = ({ children }) => {
  //to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
  //We initialize the userConsent with that value
  const [notificationsSupported, setNotificationsSupported] = useState(false);
  const [userConsent, setUserConsent] = useState(
    notificationsSupported ? Notification.permission : "denied"
  );
  //to manage async actions
  const [loading, setLoading] = useState(false);
  const [tokenSentToServer, setTokenSentToServer] =
    useLocalStorage<TokenSentToServer>(
      "tokenSentToServer",
      TokenSentToServer.No
    );
  const [notificationsGranted, setNotificationsGranted] = useState(false);
  const { apiPost } = useApi();

  useEffect(() => {
    nsPromise
      .then(setNotificationsSupported)
      .catch(() => setNotificationsSupported(false));
  }, []);

  useEffect(
    () =>
      setNotificationsGranted(
        notificationsSupported &&
          tokenSentToServer === TokenSentToServer.Yes &&
          userConsent === "granted"
      ),
    [notificationsSupported, tokenSentToServer, userConsent]
  );

  // Send the registration token your application server, so that it can:
  // - send messages back to this app
  // - subscribe/unsubscribe the token from topics
  const sendTokenToServer = useCallback(async () => {
    if (!notificationsSupported) return false;

    const currentToken = await getToken(firebaseMessaging, {
      vapidKey: VAPID_KEY
    });
    if (!currentToken) return false;

    if (tokenSentToServer !== TokenSentToServer.Yes) {
      // Send the current token to your server.
      await apiPost(`pushNotification/subscribe?token=${currentToken}`, {});
      setTokenSentToServer(TokenSentToServer.Yes);
    } else {
      console.log(
        "Token already sent to server so won't send it again unless it changes"
      );
    }
    return true;
  }, [
    apiPost,
    setTokenSentToServer,
    notificationsSupported,
    tokenSentToServer
  ]);

  const checkToken = useCallback(async () => {
    // If the push notifications are not supported do nothing
    if (!notificationsSupported || loading) return;

    try {
      setLoading(true);
      const tokenSent = await sendTokenToServer();
      if (tokenSent) return;

      // Show permission request.
      console.log(
        "No registration token available. Request permission to generate one.",
        userConsent
      );

      // user has already granted the permission
      if (userConsent === "granted") return;

      const consent = await Notification.requestPermission();
      // Retrieve a registration token for use with FCM
      setUserConsent(consent);
      if (consent !== "granted") {
        console.log({
          name: "Consent denied",
          message: `You denied the consent to receive notifications (${consent})`,
          code: 0
        });
        setTokenSentToServer(TokenSentToServer.No);
      } else {
        await sendTokenToServer();
      }
    } catch (err) {
      console.error(err);
      // have to do this to prevent constant reload
      setUserConsent("denied");
    } finally {
      setLoading(false);
      window.location.reload();
    }
  }, [
    notificationsSupported,
    loading,
    sendTokenToServer,
    userConsent,
    setTokenSentToServer
  ]);

  const deleteToken = useCallback(async () => {
    if (!notificationsSupported) return;
    try {
      setLoading(true);

      // Delete registration token.
      await fbDeleteToken(firebaseMessaging);
      setTokenSentToServer(TokenSentToServer.No);
      setUserConsent("default");
    } catch (err) {
      console.log("Error retrieving registration token. ", err);
    } finally {
      setLoading(false);
    }
  }, [setTokenSentToServer, notificationsSupported]);

  return (
    <PushContext.Provider
      value={{
        notificationsGranted,
        notificationsSupported,
        tokenSentToServer,
        userConsent,
        loading,
        checkToken,
        deleteToken
      }}
    >
      {children}
    </PushContext.Provider>
  );
};

export default usePushNotifications;
export { PushProvider };
