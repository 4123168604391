import {
  IonContent,
  IonPage,
  IonButton,
  IonText,
  IonRow,
  IonCol
} from "@ionic/react";
import React from "react";
import Form from "../form/Form";
import { useAuthContext } from "../../context/AuthProvider";
import { RegisterData } from "../../models/User";
import EmailFormItem from "../form/EmailFormItem";
import useTranslation from "../../context/LanguageProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import { useHistory } from "react-router";
import Icon from "../Icon";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "../form/FormIonInput";
import AuthLayout from "./AuthLayout";
import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";
import useLocalStorage from "../../hooks/useLocalStorage";

const Register: React.FC = () => {
  const { register } = useAuthContext();
  const { handleError } = useNotificationContext();
  const { t, tRequired, tPlaceholder } = useTranslation();
  const history = useHistory();
  const [code] = useLocalStorage("invitationCode", "");

  const registerLocal = (e: RegisterData) =>
    register(e, code)
      .then(() => {
        code && history.push(`/invitation?code=${code}`);
      })
      .catch(handleError);

  return (
    <IonPage>
      <IonContent>
        <AuthLayout>
          <h3 className="ion-padding">{t("register")}</h3>
          <Form
            onSubmit={registerLocal}
            submitButtonText={t("createAccount")}
            submitButtonIcon={<Icon slot="start" icon={faUserPlus} />}
          >
            <FormInput
              name="firstName"
              label=""
              rules={{
                required: tRequired("firstName")
              }}
              placeholder={tPlaceholder("firstName")}
            />
            <FormInput
              name="lastName"
              label=""
              rules={{
                required: tRequired("lastName")
              }}
              placeholder={tPlaceholder("lastName")}
            />
            <EmailFormItem required hideLabel />

            <FormInput
              name="password"
              label=""
              rules={{
                required: tRequired("password")
              }}
              placeholder={tPlaceholder("password")}
              clearInput
              type="password"
            />
          </Form>
          <div className="ion-text-center">
            <IonText className="greyColor">{t("orRegisterWith")}</IonText>
          </div>

          <IonRow>
            <IonCol className="ion-no-padding">
              <GoogleLoginButton />
            </IonCol>
            <IonCol className="ion-no-padding">
              <FacebookLoginButton />
            </IonCol>
          </IonRow>

          <IonButton
            className="ion-margin-top"
            fill="clear"
            routerLink="login"
            expand="block"
          >
            {t("goToLogin")}
          </IonButton>
        </AuthLayout>
      </IonContent>
    </IonPage>
  );
};

export default Register;
