import { IonCheckbox } from "@ionic/react";
import React from "react";
import { Permission } from "../../models/Permissions";

interface Props {
  value?: Permission;
  color: string;
  checked: boolean;
  onCheck: (id: number) => void;
  onUncheck: (id: number) => void;
}

const PermissionCheckbox: React.FC<Props> = ({
  value,
  color,
  checked,
  onCheck,
  onUncheck
}) =>
  value ? (
    <IonCheckbox
      color={color}
      checked={checked}
      onIonChange={e => {
        e.detail.checked ? onCheck(value) : onUncheck(value);
      }}
    />
  ) : (
    <></>
  );

export default PermissionCheckbox;
