import React from "react";
import { IonInput } from "@ionic/react";
import { RegisterOptions } from "react-hook-form";
import FormItem from "./FormItem";

export interface FormItemProps {
  name: string;
  label: string;
  rules?: RegisterOptions;
  hidden?: boolean;
  disabled?: boolean;
  clearInput?: boolean;
  placeholder?: string;
  debaunce?: number;
  className?: string;
  min?: string;
  max?: string;
  step?: string;
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  type?:
    | "date"
    | "email"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "url"
    | "time"
    | "week"
    | "month"
    | "datetime-local";
  inputmode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
}

const FormInput: React.FC<FormItemProps> = ({
  name,
  label,
  rules,
  hidden,
  disabled,
  ...props
}) => (
  <FormItem
    name={name}
    label={label}
    rules={rules}
    hidden={hidden}
    disabled={disabled}
    input={({ onChange, onBlur, ...rest }) => (
      <IonInput
        autocomplete="new-password"
        onIonChange={e => onChange(e.detail.value)}
        onIonBlur={onBlur}
        {...rest}
        {...props}
      />
    )}
  />
);

export default FormInput;
