import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  useIonViewWillEnter,
  IonButton
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import useApi from "../../data/Api";
import { ExtraDto } from "../../models/Product";
import { useNotificationContext } from "../../context/NotificationProvider";
import Icon from "../Icon";
import { faEdit, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import useCurrency from "../../hooks/useCurrency";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import MiddleBox from "../MiddleBox";
import ValueLabel from "../ValueLabel";
import ExtrasUpsertModal from "./ExtrasUpsertModal";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const ExtraView: React.FC<Props> = ({ match }) => {
  const [data, setData] = useState<ExtraDto>();
  const [showUpsertModal, setShowUpsertModal] = useState(false);

  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { formatWithCurrencySign } = useCurrency();
  const { t } = useTranslation();

  const getExtra = () => {
    apiGet<ExtraDto>("extras/get?id=" + match.params.id)
      .then(setData)
      .catch(handleError);
  };

  useIonViewWillEnter(() => {
    getExtra();
  }, [match.params.id]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/products" />
          </IonButtons>
          <IonTitle>{data && data.name}</IonTitle>
          <IonButtons slot="primary">
            <Can permission={Permission.ProductsUpdate}>
              <IonButton onClick={() => setShowUpsertModal(true)}>
                <ButtonTextIcon button="edit" />
              </IonButton>
            </Can>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data && (
          <>
            <MiddleBox className="box">
              <div className="ion-padding">
                <div className="ion-text-center ion-margin-top">
                  <Icon icon={faPaperclip} size="4x" />
                  <h3>{data.name}</h3>
                  <ValueLabel title={t("price")}>
                    {data.price !== undefined &&
                      formatWithCurrencySign(data.price)}
                  </ValueLabel>

                  <Can permission={Permission.ProductsUpdate}>
                    <IonButton
                      color="secondary"
                      onClick={() => setShowUpsertModal(true)}
                    >
                      <Icon icon={faEdit} /> {t("edit")}
                    </IonButton>
                  </Can>
                </div>
              </div>
            </MiddleBox>
            <div className="ion-text-center ion-margin-top"></div>

            <ExtrasUpsertModal
              showModal={showUpsertModal}
              initialData={data}
              onCancel={() => {
                setShowUpsertModal(false);
              }}
              onSuccess={() => {
                setShowUpsertModal(false);
                showSuccessToast(t("editedMessage"));
                getExtra();
              }}
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withPermission(ExtraView, Permission.ProductsRead);
