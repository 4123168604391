import { IonContent, IonPage, IonButton } from "@ionic/react";
import React, { useCallback } from "react";
import { useAuthContext } from "../../context/AuthProvider";
import useTranslation from "../../context/LanguageProvider";
import EmailFormItem from "../form/EmailFormItem";
import Form from "../form/Form";
import AuthLayout from "./AuthLayout";

interface Props {
  code: string | null;
}

const ForgotPassword: React.FC<Props> = ({ code }) => {
  const { forgotPassword } = useAuthContext();
  const { t } = useTranslation();

  const sendResetPasswordEmail = useCallback(
    (e: { email: string }) =>
      forgotPassword(e.email)
        .then(() => alert("email is sent"))
        .catch((error: any) => alert(error.message)),
    [forgotPassword]
  );

  return (
    <IonPage>
      <IonContent>
        <AuthLayout>
          <Form
            onSubmit={sendResetPasswordEmail}
            submitButtonText={t("sendResetPasswordEmail")}
          >
            <h3 className="ion-padding">{t("forgotYourPassword")}</h3>

            <EmailFormItem required hideLabel />
          </Form>
          <IonButton
            className="ion-margin-top"
            fill="clear"
            routerLink={code ? `login?code=${code}` : "login"}
            expand="block"
          >
            {t("goToLogin")}
          </IonButton>
        </AuthLayout>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
