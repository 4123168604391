import React from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import ButtonTextIcon from "../ButtonTextIcon";
import ModalWrapper from "../ModalWrapper";

interface Props {
  onCancel: () => void;
  showModal: boolean;
  fileName: string;
  imageUrl: string;
}

const ImagePreviewModal: React.FC<Props> = ({
  onCancel,
  showModal,
  fileName,
  imageUrl
}) => (
  <ModalWrapper
    modalOpened={showModal}
    dismiss={onCancel}
    modal="imagePreviewModal"
  >
    <IonModal isOpen={showModal} onDidDismiss={onCancel}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>
              <ButtonTextIcon button="cancel" />
            </IonButton>
          </IonButtons>
          <IonTitle>{fileName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonImg src={imageUrl}></IonImg>
      </IonContent>
    </IonModal>
  </ModalWrapper>
);

export default ImagePreviewModal;
