import React, { useEffect, useState } from "react";
import useLab from "../../context/LabProvider";
import useTranslation from "../../context/LanguageProvider";

interface Props {
  languages: string[];
  currencies?: string[];
  children: React.ReactNode;
}

const ShowForLanguage: React.FC<Props> = ({
  languages,
  currencies,
  children
}) => {
  const { language } = useTranslation();
  const { lab } = useLab();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(
      languages.includes(language) ||
        (lab?.currency !== undefined &&
          currencies !== undefined &&
          currencies.includes(lab.currency))
    );
  }, [language, lab?.currency]);

  return show ? <>{children}</> : null;
};

export default ShowForLanguage;
