import React, { useEffect, useState } from "react";
import { IonItem, IonSkeletonText, IonList } from "@ionic/react";
import useTranslation from "../../context/LanguageProvider";
import {
  CaseStatus,
  ProductionLogFormDto,
  ProductionLogStatus
} from "../../models/Case";
import useDate from "../../hooks/useDate";
import ProductionLogItem from "./ProductionLogItem";
import NoResults from "../NoResults";
import { CalendarProductionLogDto } from "../../models/CalendarDto";
import { useAuthContext } from "../../context/AuthProvider";
import { scheduledDayColorClasses } from "../../data/calendarColorHelpers";
import { useEmployeeTasks } from "./EmployeeStartedTasks";

interface Props {
  employeeId: number;
  editProductionLog?: (l: ProductionLogFormDto) => void;
}
interface TaskDay {
  day: string;
  count: number;
  colorClass: string;
  logs: CalendarProductionLogDto[];
}

const EmployeeScheduledTasks: React.FC<Props> = ({
  employeeId,
  editProductionLog
}) => {
  const { loading, productionLogs } = useEmployeeTasks(
    employeeId,
    ProductionLogStatus.Scheduled
  );
  const [taskDays, setTaskDays] = useState<TaskDay[]>([]);

  const { t } = useTranslation();
  const { toDateString } = useDate();
  const { user } = useAuthContext();
  const today = toDateString(new Date().toISOString());

  useEffect(() => {
    if (!productionLogs) return;
    const taskDays = productionLogs
      .filter(l => l.scheduled)
      .reduce<TaskDay[]>((result, item) => {
        const day = toDateString(item.scheduled);

        const group = result.find(g => g.day === day);
        if (group) {
          return [
            ...result.filter(g => g.day !== day),
            { ...group, count: group.count + 1, logs: [...group.logs, item] }
          ];
        } else {
          const colorClass = scheduledDayColorClasses(
            new Date(item.scheduled)
          ).bgClass;
          return [...result, { day, count: 1, colorClass, logs: [item] }];
        }
      }, [])
      .map(r => ({
        ...r,
        logs: r.logs.sort(
          (a, b) =>
            new Date(a.scheduled).getTime() - new Date(b.scheduled).getTime()
        )
      }))
      .sort((a, b) => new Date(a.day).valueOf() - new Date(b.day).valueOf());
    setTaskDays(taskDays);
  }, [productionLogs, toDateString]);

  return (
    <>
      {!productionLogs && loading && (
        <IonSkeletonText animated title={t("loading")} />
      )}
      <>
        <h3 className="form-header text_center">
          {t("scheduling.scheduledTasks")}
        </h3>
        {productionLogs && (
          <>
            {productionLogs.length === 0 &&
              user &&
              (user.userId === employeeId ? (
                <NoResults title={t("myTasks.noMyScheduledTasks")} />
              ) : (
                <NoResults title={t("myTasks.noScheduledTasks")} />
              ))}
            {productionLogs.length > 0 &&
              taskDays.map(taskDay => (
                <IonList key={taskDay.day}>
                  <h4 className={taskDay.colorClass + " date-header"}>
                    {taskDay.day === today ? t("today") : taskDay.day}
                  </h4>
                  {taskDay.logs.map(log => (
                    <ProductionLogItem
                      key={log.id}
                      $case={log.case}
                      showTimeOnly
                      {...log}
                      hideEmployee
                      showDoctorPatientData
                      caseStatus={CaseStatus.InProgress}
                      editProductionLog={log =>
                        editProductionLog && editProductionLog(log)
                      }
                      deleteProductionLog={() => {}}
                    />
                  ))}
                </IonList>
              ))}
            {productionLogs.filter(l => !l.scheduled).length > 0 && (
              <>
                <h3 className="date-header primaryBgColor">
                  {t("scheduling.deadline")}: {t("notDefined")}
                </h3>
                <IonList>
                  {productionLogs
                    .filter(l => !l.scheduled)
                    .map(log => (
                      <IonItem key={log.id}>
                        <ProductionLogItem
                          showTimeOnly
                          $case={log.case}
                          {...log}
                          hideEmployee
                          showDoctorPatientData
                          caseStatus={log.case.status}
                          editProductionLog={() =>
                            editProductionLog &&
                            editProductionLog({
                              ...log,
                              taskName: log.task?.name
                            })
                          }
                          deleteProductionLog={() => {}}
                        />
                      </IonItem>
                    ))}
                </IonList>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default EmployeeScheduledTasks;
