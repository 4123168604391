import React, { useCallback, useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonItemDivider
} from "@ionic/react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useTranslation from "../../context/LanguageProvider";
import useCurrency from "../../hooks/useCurrency";
import { InvoiceItemDto } from "../../models/Invoice";
import Icon from "../Icon";
import { calculateInvoiceItemTotal } from "./invoiceMath";
import ValueLabel from "../ValueLabel";
import { parseNumber } from "../../data/numberHelpers";

interface Errors {
  name?: string;
  discount?: string;
  quantity?: string;
  price?: string;
}

interface Props {
  item: InvoiceItemDto;
  onChange: (item: InvoiceItemDto) => void;
  deleteItem: () => void;
  showDelete: boolean;
}

type ChangeEventType = CustomEvent<{ value: string | null | undefined }>;

const InvoiceItemEdit: React.FC<Props> = ({
  item,
  onChange,
  deleteItem,
  showDelete
}) => {
  const { formatWithCurrencySign } = useCurrency();
  const { t, tRequired, tPlaceholder, tInterpolated } = useTranslation();
  const [errors, setErrors] = useState<Errors>({});

  const onNumberChange = (item: InvoiceItemDto) =>
    onChange({
      ...item,
      total: calculateInvoiceItemTotal(item)
    });

  const validateNumber = useCallback(
    (propName: string, value?: number, min?: number, max?: number) => {
      if (value === undefined) return tRequired(propName);

      if (min !== undefined && value < min)
        return tInterpolated("error.min", { min });
      if (max !== undefined && value > max)
        return tInterpolated("error.max", { max });

      return undefined;
    },
    [tInterpolated, tRequired]
  );

  const onNameChange = ({ detail: { value } }: ChangeEventType) => {
    onChange({
      ...item,
      name: value!
    });

    setErrors({
      ...errors,
      name: value ? undefined : tRequired("name")
    });
  };
  const onQuantityChange = ({ detail: { value } }: ChangeEventType) => {
    const quantity = parseNumber(value);
    onNumberChange({
      ...item,
      quantity: quantity
    });
    setErrors({
      ...errors,
      quantity: validateNumber("quantity", quantity, 0.01)
    });
  };
  const onPriceChange = ({ detail: { value } }: ChangeEventType) => {
    const price = parseNumber(value);
    onNumberChange({
      ...item,
      price: price
    });
    setErrors({
      ...errors,
      price: validateNumber("price", price, 0.01)
    });
  };
  const onDiscountChange = ({ detail: { value } }: ChangeEventType) => {
    const discount = parseNumber(value);
    onNumberChange({
      ...item,
      discount: discount
    });
    setErrors({
      ...errors,
      discount: validateNumber("discount", discount, 0, 99.99)
    });
  };

  const renderErrorMessage = (error?: string) =>
    error && (
      <span
        style={{
          color: "red",
          fontSize: "smaller"
        }}
      >
        {error}
      </span>
    );

  return (
    <IonRow>
      <IonCol size-xs="12" size-xl="4">
        <IonItem className="ion-no-padding" lines="none">
          <IonLabel position="stacked">{t("name")}</IonLabel>
          <IonInput
            autocomplete="new-password"
            required
            type="text"
            value={item.name}
            placeholder={tPlaceholder("name")}
            onIonChange={onNameChange}
          />
          {renderErrorMessage(errors.name)}
        </IonItem>
      </IonCol>
      <IonCol size-xs="6" size-md="3" size-xl="2">
        <IonItem lines="none">
          <IonLabel position="stacked">{t("quantity")}</IonLabel>
          <IonInput
            type="number"
            inputMode="numeric"
            step="1"
            min="1"
            value={item.quantity}
            placeholder={tPlaceholder("quantity")}
            onIonChange={onQuantityChange}
          />
          {renderErrorMessage(errors.quantity)}
        </IonItem>
      </IonCol>
      <IonCol size-xs="6" size-md="3" size-xl="2">
        <IonItem lines="none">
          <IonLabel position="stacked">{t("price")}</IonLabel>
          <IonInput
            type="number"
            inputMode="numeric"
            step="0.01"
            min="0"
            value={item.price}
            placeholder={tPlaceholder("price")}
            onIonChange={onPriceChange}
          />
          {renderErrorMessage(errors.price)}
        </IonItem>
      </IonCol>
      <IonCol size-xs="6" size-md="3" size-xl="2">
        <IonItem lines="none">
          <IonLabel position="stacked">{t("discount")}</IonLabel>
          <IonInput
            type="number"
            inputMode="numeric"
            step="0.1"
            min="0"
            max="100"
            value={item.discount}
            placeholder={tPlaceholder("discount")}
            onIonChange={onDiscountChange}
          />
          {renderErrorMessage(errors.discount)}
        </IonItem>
      </IonCol>
      <IonCol
        size-xs="6"
        size-md="3"
        size-xl="2"
        class="ion-align-self-center invoice-total-col"
      >
        <ValueLabel title={t("total")} large>
          {formatWithCurrencySign(item.total)}
          <IonButton
            onClick={deleteItem}
            size="small"
            color="danger"
            className="inv-delete-button no-icon-margin"
            hidden={!showDelete}
          >
            <Icon
              slot="end"
              icon={faTimes}
              className="white-icon"
              title={t("delete")}
            />
          </IonButton>
        </ValueLabel>
      </IonCol>
      <IonItemDivider hidden={!showDelete} className="ion-hide-lg-up" />
    </IonRow>
  );
};

export default InvoiceItemEdit;
