import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import useTranslation from "../context/LanguageProvider";
import Icon from "./Icon";

interface Props {
  children?: React.ReactNode;
  text: string;
  hidden?: boolean;
}

const WarningBox: React.FC<Props> = ({ children, text, hidden }) => {
  const { t } = useTranslation();
  return (
    <div hidden={hidden} className="box ion-text-center ion-margin-top border">
      <h4>
        <Icon icon={faExclamationCircle} className="warningColor" />{" "}
        {t("warning")}
      </h4>
      <p hidden={!text.length}>{text}</p>
      {children}
    </div>
  );
};

export default WarningBox;
