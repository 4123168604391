export const searchMatch = (searchingFor: string, searchingIn: string) =>
  !searchingFor ||
  searchingIn.toLowerCase().includes(searchingFor.toLowerCase());

export const makeLength = (str: string, length: number) => {
  var padding = Array(length).join(" "); // make a string of white spaces
  return (str + padding).substring(0, length);
};

export const isString = (x: any): x is string => typeof x === "string";

export const distinct = (input: string[]) => Array.from(new Set(input));

/**
 * Concatinates all non-empty values
 * @param values An array of values
 * @param separator The separator to be inserted between values
 * @returns A concatinated string
 */
export const concatinate = (
  values: (string | undefined)[],
  separator: string = ", "
) => values.filter(v => v).join(separator);

export const isImage = (name: string) =>
  [".jpg", ".png", ".gif", ".jpeg"].find(item =>
    name.toLowerCase().endsWith(item)
  ) !== undefined;
