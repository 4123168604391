import { IonGrid, IonCol, IonRow, IonSkeletonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import useTranslation from "../../context/LanguageProvider";
import { InvoicesUrlProps } from "../../models/UrlProps";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { Column, Pie } from "@ant-design/charts";
import useCurrency from "../../hooks/useCurrency";
import {
  InvoiceDateChartPointDto,
  InvoiceStatsDto
} from "../../models/Invoice";
import useColorPalette, { colorPalette } from "../../hooks/useColorPalette";
import NoResults from "../NoResults";
import { Permission } from "../../models/Permissions";
import withPermission from "../../data/withPermission";
import { useAuthContext } from "../../context/AuthProvider";

const pieChartConfig = {
  appendPadding: 10,
  angleField: "amount",
  colorField: "name",
  radius: 0.8,
  label: { type: "outer" },
  interactions: [{ type: "element-active" }]
};

interface Props extends InvoicesUrlProps {
  hidden?: boolean;
}

const InvoicesStats: React.FC<Props> = React.memo(
  ({ hidden, ...urlProps }) => {
    const [getColor, setValues] = useColorPalette<InvoiceDateChartPointDto>(
      t => t.name
    );
    const [loading, setLoading] = useState(false);
    const [stats, setStats] = useState<InvoiceStatsDto>();
    const { t, tPaymentType } = useTranslation();
    const { apiPost } = useApi();
    const { user } = useAuthContext();
    const { handleError } = useNotificationContext();
    const { formatWithCurrencySign } = useCurrency();

    useEffect(() => {
      setLoading(true);
      apiPost<InvoiceStatsDto>("invoice/getStats", urlProps)
        .then(setStats)
        .catch(handleError)
        .finally(() => setLoading(false));
    }, [
      urlProps.fromDate,
      urlProps.toDate,
      urlProps.doctorId,
      urlProps.search,
      urlProps.status,
      apiPost,
      handleError
    ]);

    useEffect(() => {
      if (!stats?.byDoctor) return;

      setValues([...stats.byDoctor.payments, ...stats.byDoctor?.invoices]);
    }, [setValues, stats]);

    return (
      <IonGrid hidden={hidden}>
        {stats ? (
          <>
            {stats.totals && (
              <IonRow class="box">
                <IonCol size-xs="12" size-md="12">
                  <h3 className="ion-text-center">
                    {t("stats.invoicesAndPayments")}
                  </h3>
                  <Column
                    xField="name"
                    yField="amount"
                    isGroup
                    seriesField="type"
                    color={d =>
                      d.type === t("invoices.title") ? "red" : "green"
                    }
                    data={[
                      ...stats.totals.invoices.map(m => ({
                        ...m,
                        type: t("invoices.title")
                      })),
                      ...stats.totals.payments.map(m => ({
                        ...m,
                        type: t("payments.title")
                      }))
                    ]}
                    meta={{
                      name: { alias: t("date") },
                      amount: {
                        alias: t("amount"),
                        formatter: formatWithCurrencySign
                      },
                      type: { alias: t("type") }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              </IonRow>
            )}
            {!user?.doctorId &&
              stats.byDoctor &&
              (stats.byDoctor.invoices.length > 0 ||
                stats.byDoctor.payments.length > 0) && (
                <IonRow class="box">
                  {stats.byDoctor.invoices.length > 0 && (
                    <IonCol
                      size-xs="12"
                      size-md={
                        stats.byDoctor.payments.length === 0 ? "12" : "6"
                      }
                    >
                      <h3 className="ion-text-center">
                        {t("stats.invoicesByDoctor")}
                      </h3>
                      <Pie
                        loading={loading}
                        {...pieChartConfig}
                        data={stats.byDoctor.invoices}
                        color={getColor}
                        meta={{
                          amount: {
                            alias: t("amount"),
                            formatter: formatWithCurrencySign
                          },
                          name: { alias: t("doctor") }
                        }}
                        legend={{
                          layout: "horizontal",
                          position: "top"
                        }}
                      />
                    </IonCol>
                  )}
                  {stats.byDoctor.payments.length > 0 && (
                    <IonCol
                      size-xs="12"
                      size-md={
                        stats.byDoctor.invoices.length === 0 ? "12" : "6"
                      }
                    >
                      <h3 className="ion-text-center">
                        {t("stats.paymentsByDoctor")}
                      </h3>
                      <Pie
                        loading={loading}
                        {...pieChartConfig}
                        data={stats.byDoctor.payments}
                        color={getColor}
                        meta={{
                          amount: {
                            alias: t("amount"),
                            formatter: formatWithCurrencySign
                          },
                          name: { alias: t("doctor") }
                        }}
                        legend={{
                          layout: "horizontal",
                          position: "top"
                        }}
                      />
                    </IonCol>
                  )}
                </IonRow>
              )}

            {stats.paymentType && stats.paymentType.length > 0 && (
              <IonRow class="box">
                <IonCol size-xs="12" size-md="12">
                  <h3 className="ion-text-center">{t("payments.type")}</h3>
                  <Pie
                    loading={loading}
                    {...pieChartConfig}
                    data={stats.paymentType}
                    color={colorPalette}
                    meta={{
                      amount: {
                        alias: t("amount"),
                        formatter: formatWithCurrencySign
                      },
                      name: {
                        alias: t("payments.type"),
                        formatter: v => tPaymentType(parseInt(v))
                      }
                    }}
                    legend={{
                      layout: "horizontal",
                      position: "top"
                    }}
                  />
                </IonCol>
              </IonRow>
            )}

            {stats.totals === undefined && <NoResults title={t("noData")} />}
          </>
        ) : (
          <IonSkeletonText animated />
        )}
      </IonGrid>
    );
  },
  (pe, ne) =>
    pe.fromDate === ne.fromDate &&
    pe.toDate === ne.toDate &&
    pe.doctorId === ne.doctorId &&
    pe.search === ne.search &&
    pe.status === ne.status &&
    pe.hidden === ne.hidden
);

export default withPermission(InvoicesStats, Permission.InvoicesTableRead);
