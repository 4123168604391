import React, { useCallback, useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonSkeletonText
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import Icon from "../Icon";
import { faEdit, faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import ValueLabel from "../ValueLabel";
import useTranslation from "../../context/LanguageProvider";
import MiddleBox from "../MiddleBox";
import ButtonTextIcon from "../ButtonTextIcon";
import TaskUpsertModal from "./TaskUpsertModal";
import TaskListDto from "../../models/Task";
import useCurrency from "../../hooks/useCurrency";

interface UrlProps
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const Task: React.FC<UrlProps> = ({ match }) => {
  const [segment, setSegment] = useState<"info" | "stats">("info");
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState<TaskListDto>();

  const [showEditModal, setShowEditModal] = useState(false);
  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { formatWithCurrencySign } = useCurrency();
  const { t } = useTranslation();

  const fetchData = useCallback(
    () =>
      apiGet<TaskListDto>(`task/get?id=${match.params.id}`)
        .then(setData)
        .catch(handleError),
    [apiGet, handleError, match.params.id]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, match.params.id]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tasks" />
          </IonButtons>
          <IonTitle>{data?.name}</IonTitle>
          <IonButtons slot="primary">
            <Can permission={Permission.TaskUpdate}>
              <IonButton onClick={() => setShowEditModal(true)}>
                <ButtonTextIcon button="edit" />
              </IonButton>
            </Can>
            <IonButton
              hidden={segment === "info"}
              color={showFilter ? "primary" : ""}
              onClick={() => setShowFilter(!showFilter)}
            >
              <ButtonTextIcon button="filter" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar hidden>
          <IonSegment
            onIonChange={e => {
              setSegment(e.detail.value as any);
            }}
            value={segment}
          >
            <IonSegmentButton value={"info"}>
              <IonLabel>{t("info")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="stats">
              <IonLabel>{t("stats")}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data ? (
          <>
            {segment === "info" && (
              <MiddleBox className="box">
                <div className="ion-padding">
                  <div className="ion-text-center ion-margin-top">
                    <Icon
                      icon={faNetworkWired}
                      size="4x"
                      className="secondaryColor"
                    />
                    <h3>{data.name}</h3>
                    {data.description && (
                      <ValueLabel title={t("description")}>
                        {data.description}
                      </ValueLabel>
                    )}
                    <ValueLabel title={t("task.pieceRate")}>
                      {formatWithCurrencySign(data.pieceRate)}
                    </ValueLabel>

                    <Can permission={Permission.TaskUpdate}>
                      <IonButton
                        color="secondary"
                        onClick={() => setShowEditModal(true)}
                      >
                        <Icon icon={faEdit} /> {t("edit")}
                      </IonButton>
                    </Can>
                  </div>
                </div>
              </MiddleBox>
            )}
            {segment === "stats" && <>todo...</>}

            <TaskUpsertModal
              initialData={data}
              showTaskModal={showEditModal}
              onSuccess={() => {
                fetchData();
                showSuccessToast(t("task.updated"));
                setShowEditModal(false);
              }}
              onCancel={() => setShowEditModal(false)}
            />
          </>
        ) : (
          <IonSkeletonText animated />
        )}
      </IonContent>
    </IonPage>
  );
};

export default withPermission(Task, Permission.TaskRead);
