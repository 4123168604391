import {
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  useIonViewWillEnter
} from "@ionic/react";
import React, { useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { faPlus, faTooth } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import { searchMatch } from "../../data/stringHelpers";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import { ProductViewDto } from "../../models/Product";
import SearchPage from "../SearchPage";
import ProductUpsertModal from "./ProductUpsertModal";
import { getMatchingProductTypes, ProductType } from "../../models/Teeth";
import useTranslation from "../../context/LanguageProvider";
import NoResults from "../NoResults";
import useCurrency from "../../hooks/useCurrency";

const initialData = {
  id: 0,
  name: "",
  price: 0,
  productTypes: getMatchingProductTypes(ProductType.Crown),
  extras: []
};

const Products: React.FC = () => {
  const [showNoResults, setShowNoResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);

  const [products, setproducts] = useState<ProductViewDto[]>([]);
  const { showSuccessToast } = useNotificationContext();
  const { formatWithCurrencySign } = useCurrency();

  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const { t } = useTranslation();

  const fetchProducts = () => {
    apiGet<ProductViewDto[]>("product/getAll")
      .then(products => {
        if (products.length === 0) {
          setShowNoResults(true);
        } else {
          setShowNoResults(false);
          setproducts(products);
        }
      })
      .catch(handleError);
  };
  useIonViewWillEnter(() => {
    fetchProducts();
  });

  return (
    <IonPage>
      <SearchPage
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        title={t("products.title")}
        addNewPermission={Permission.ProductsCreate}
        onAddClicked={() => setShowAddModal(true)}
        hideMenuButton
      >
        <IonList className="box lines" hidden={showNoResults}>
          {products
            .filter(d => searchMatch(searchTerm, d.name))
            .map(d => (
              <IonItem key={d.id} button detail routerLink={"product/" + d.id}>
                <Icon slot="start" icon={faTooth} />
                <IonLabel>{d.name}</IonLabel>
                <p slot="end">
                  {d.price !== undefined && formatWithCurrencySign(d.price)}
                </p>
              </IonItem>
            ))}
        </IonList>
        <NoResults title={showNoResults ? t("products.noData") : ""} />
        <Can permission={Permission.ProductsCreate}>
          <div className="ion-text-center">
            <IonButton onClick={() => setShowAddModal(true)}>
              <Icon icon={faPlus} />
              {t("products.new")}
            </IonButton>
          </div>
        </Can>

        <ProductUpsertModal
          showModal={showAddModal}
          onCancel={() => setShowAddModal(false)}
          onSuccess={() => {
            fetchProducts();
            showSuccessToast(t("products.added"));
            setShowAddModal(false);
          }}
          initialData={initialData}
        />
      </SearchPage>
    </IonPage>
  );
};

export default withPermission(Products, Permission.ProductsRead);
