enum PaymentType {
  Cash = 1,
  CreditCard = 2,
  BankTransfer = 3,
  PayPal = 4,
  OtherOnlinePayment = 5,
  OtherPaymentMethod = 20
}

export default PaymentType;
