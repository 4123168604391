import {
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  useIonViewWillEnter,
  IonText,
  IonSkeletonText
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";

import { faNetworkWired, faPlus } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import { Permission } from "../../models/Permissions";
import withPermission from "../../data/withPermission";
import Can from "../Can";
import SearchPage from "../SearchPage";
import useTranslation from "../../context/LanguageProvider";
import NoResults from "../NoResults";
import { SchedulingTemplateListDto } from "../../models/Task";
import SchedulingTemplateUpsertModal from "./SchedulingTemplateUpsertModal";

const SchedulingTemplates: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentTemplate, setCurrentTemplate] = useState<
    SchedulingTemplateListDto | undefined
  >();
  const [showUpsertModal, setShowUpsertModal] = useState(false);

  useEffect(() => {
    if (currentTemplate) setShowUpsertModal(true);
  }, [currentTemplate]);

  const [schedulingTemplates, setSchedulingTemplates] =
    useState<SchedulingTemplateListDto[]>();

  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const fetchData = () =>
    apiGet<SchedulingTemplateListDto[]>(`schedulingTemplate/getAll`)
      .then(data => {
        setSchedulingTemplates(data);
      })
      .catch(handleError);

  useIonViewWillEnter(() => {
    fetchData();
  });

  const showNewTemplate = () =>
    setCurrentTemplate({
      id: 0,
      name: "",
      tasks: [{ taskId: 0, userId: null }]
    });

  return (
    <IonPage>
      <SearchPage
        searchTerm={searchTerm}
        setSearchTerm={s => {
          setSearchTerm(s);
          // todo, filter data
        }}
        title={t("schedulingTemplate.titlePlural")}
        addNewPermission={Permission.SchedulingTemplateCreate}
        onAddClicked={showNewTemplate}
        hideMenuButton
      >
        <IonList className="box lines" hidden={!schedulingTemplates?.length}>
          {schedulingTemplates ? (
            schedulingTemplates.map((d, i) => (
              <IonItem
                button
                detail
                key={i}
                onClick={() => setCurrentTemplate(d)}
              >
                <Icon slot="start" icon={faNetworkWired} />
                <IonLabel className="ion-text-wrap">
                  <IonText title={d.name}>{d.name}</IonText>
                </IonLabel>
              </IonItem>
            ))
          ) : (
            <IonSkeletonText animated />
          )}
        </IonList>

        <Can permission={Permission.SchedulingTemplateRead}>
          <NoResults
            title={
              schedulingTemplates && schedulingTemplates.length === 0
                ? t("schedulingTemplate.noData")
                : ""
            }
          >
            <IonButton color="secondary" onClick={showNewTemplate}>
              <Icon icon={faPlus} />
              {t("schedulingTemplate.add")}
            </IonButton>
          </NoResults>
        </Can>

        {currentTemplate && (
          <SchedulingTemplateUpsertModal
            initialData={currentTemplate}
            showSchedulingTemplateModal={showUpsertModal}
            onSuccess={() => {
              fetchData();
              showSuccessToast(t("schedulingTemplate.added"));
              setShowUpsertModal(false);
            }}
            onCancel={() => setShowUpsertModal(false)}
          />
        )}
      </SearchPage>
    </IonPage>
  );
};

//todo change this to schedulingTemplate permission
export default withPermission(SchedulingTemplates, Permission.TaskRead);
