import React from "react";
import { CaseFlag } from "../../models/Case";
import Icon from "../Icon";
import { faTag } from "@fortawesome/free-solid-svg-icons";

interface Props {
  flag: CaseFlag;
}

const CaseFlagIcon: React.FC<Props> = ({ flag }) => {
  return (
    <>
      {flag !== CaseFlag.None && (
        <Icon icon={faTag} className={`case-flag case-flag-${flag}`} />
      )}
    </>
  );
};

export default CaseFlagIcon;
