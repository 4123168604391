import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useAntdLocale } from "../context/AntdProvider";

interface Props {
  value?: string;
  className?: string;
  dateAndTime?: boolean;
  minuteStep?: number;
  placeholder?: string;
  onChange: (v?: string) => void;
  onBlur?: () => void;
}

const PopupDatePicker: React.FC<Props> = ({
  value,
  dateAndTime,
  minuteStep = 5,
  onChange,
  ...rest
}) => {
  const { locale } = useAntdLocale();

  return (
    <DatePicker
      allowClear
      showTime={
        dateAndTime && {
          defaultValue: moment("12:00", "HH:mm"),
          format: "HH:mm",
          minuteStep
        }
      }
      size="large"
      value={value ? moment(value) : null}
      onChange={m => onChange(m?.toISOString())}
      locale={locale}
      format={dateAndTime ? locale.lang.dateTimeFormat : locale.lang.dateFormat}
      inputReadOnly
      {...rest}
    />
  );
};

export default PopupDatePicker;
