import React from "react";

interface Props {
  children?: React.ReactNode;
  title: string;
}

const NoResults: React.FC<Props> = ({ children, title }) => {
  return (
    <div className="ion-text-center ion-margin-top">
      <h4 hidden={!title.length} className="opacity-07">
        {title}
      </h4>
      {children}
    </div>
  );
};

export default NoResults;
