import React, { useCallback, useEffect, useState } from "react";
import { IonSkeletonText, IonList } from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core/components";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import { ProductionLogFormDto, ProductionLogStatus } from "../../models/Case";
import ProductionLogItem from "./ProductionLogItem";
import NoResults from "../NoResults";
import { CalendarProductionLogDto } from "../../models/CalendarDto";
import { useAuthContext } from "../../context/AuthProvider";
import Refresher from "../Refresher";
import useLab from "../../context/LabProvider";

export const useEmployeeTasks = (
  employeeId: number,
  status: ProductionLogStatus
) => {
  const [loading, setLoading] = useState(false);
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const [productionLogs, setProductionLogs] =
    useState<CalendarProductionLogDto[]>();
  const { myTasksSummary } = useLab();

  const fetchProductionLogs = useCallback(
    (refreshEvent?: CustomEvent<RefresherEventDetail>) => {
      setLoading(true);
      return apiPost<CalendarProductionLogDto[]>(`calendar/getLogs`, {
        employeeId,
        status,
        getUnassigned: true
      })
        .then(setProductionLogs)
        .catch(handleError)
        .finally(() => {
          if (refreshEvent) refreshEvent.detail.complete();
          setLoading(false);
        });
    },
    [apiPost, handleError, employeeId, status]
  );

  useEffect(() => {
    fetchProductionLogs();
  }, [employeeId, status, myTasksSummary, fetchProductionLogs]);

  return { productionLogs, loading, fetchProductionLogs };
};

interface Props {
  employeeId: number;
  editProductionLog?: (l: ProductionLogFormDto) => void;
}

const EmployeeStartedTasks: React.FC<Props> = ({
  employeeId,
  editProductionLog
}) => {
  const { loading, productionLogs, fetchProductionLogs } = useEmployeeTasks(
    employeeId,
    ProductionLogStatus.Started
  );

  const { t } = useTranslation();
  const { user } = useAuthContext();

  return (
    <>
      {!productionLogs && loading && (
        <IonSkeletonText animated title={t("loading")} />
      )}

      <Refresher onRefresh={e => fetchProductionLogs(e)} />
      <h3 className="form-header text_center">{t("myTasks.inProgress")}</h3>
      {productionLogs && (
        <>
          {productionLogs.length === 0 &&
            user &&
            (user.userId === employeeId ? (
              <NoResults title={t("myTasks.noMyInProgressTasks")} />
            ) : (
              <NoResults title={t("myTasks.noInProgressTasks")} />
            ))}

          {productionLogs.length > 0 && (
            <IonList className="ion-no-padding">
              {productionLogs.map(log => (
                <ProductionLogItem
                  key={log.id}
                  $case={log.case}
                  {...log}
                  hideEmployee
                  showDoctorPatientData
                  caseStatus={1}
                  editProductionLog={e =>
                    editProductionLog && editProductionLog(e)
                  }
                  deleteProductionLog={() => {}}
                />
              ))}
            </IonList>
          )}
        </>
      )}
    </>
  );
};

export default EmployeeStartedTasks;
