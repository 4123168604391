import { IonButton, IonIcon } from "@ionic/react";
import { logoGoogle } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../../context/AuthProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import useLocalStorage from "../../hooks/useLocalStorage";

const GoogleLoginButton: React.FC = () => {
  const { googleLogin } = useAuthContext();
  const { handleError } = useNotificationContext();
  const history = useHistory();
  const [invitationCode] = useLocalStorage("invitationCode", "");

  const googleLoginLocal = () => {
    googleLogin()
      .then(() => invitationCode && history.push("/invitation"))
      .catch(handleError);
  };

  return (
    <IonButton
      style={{
        "--background": "#de5246"
      }}
      type="submit"
      expand="block"
      size="default"
      onClick={googleLoginLocal}
    >
      <IonIcon icon={logoGoogle} slot="start" />
      Google
    </IonButton>
  );
};

export default GoogleLoginButton;
