import { IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { getCaseColor } from "../../data/calendarColorHelpers";
import useTranslation from "../../context/LanguageProvider";
import useDate from "../../hooks/useDate";
import CalendarCaseDto, {
  CalendarProductionLogDto
} from "../../models/CalendarDto";
import { CaseStatus, ProductionLogFormDto } from "../../models/Case";
import CaseStatusIcon from "../case/CaseStatusIcon";
import ProductionLogItem from "../productionLog/ProductionLogItem";
import CaseName from "../case/CaseName";
import { useHistory } from "react-router";

interface Props {
  caseEvent?: CalendarCaseDto;
  plEvent?: CalendarProductionLogDto;
  editProductionLog: (l: ProductionLogFormDto) => void;
  onStatusChange: (id: number, status: CaseStatus) => void;
}

const CalendarListItem: React.FC<Props> = ({
  caseEvent,
  plEvent,
  editProductionLog,
  onStatusChange
}) => {
  const { t, tInterpolated } = useTranslation();
  const { toDateString } = useDate();
  const colorClass = caseEvent ? getCaseColor(caseEvent)?.colorClass : "";
  const history = useHistory();

  return (
    <>
      {plEvent && (
        <ProductionLogItem
          {...plEvent}
          caseStatus={plEvent.case.status}
          $case={plEvent.case}
          showTimeOnly
          // hideTime
          editProductionLog={() =>
            editProductionLog({
              ...plEvent,
              taskName: plEvent.task ? plEvent.task.name : ""
            })
          }
          deleteProductionLog={() => {}}
        />
      )}

      {caseEvent && (
        <IonItem button detail>
          <IonLabel
            className="ion-text-wrap"
            onClick={() => history.push("/case/" + caseEvent.caseId)}
          >
            {caseEvent.tryoutId ? (
              <>
                <p className={colorClass}>
                  <b>{t("cases.tryout")}</b> ({caseEvent.tryoutName})
                </p>

                {caseEvent.shipped ? (
                  <p>
                    {tInterpolated("cases.shippedOn", {
                      date: toDateString(caseEvent.shipped)
                    })}
                  </p>
                ) : (
                  <p className={colorClass}>{t("cases.notShipped")}</p>
                )}
                {caseEvent.shipped && caseEvent.received ? (
                  <p>
                    {tInterpolated("cases.receivedOn", {
                      date: toDateString(caseEvent.received)
                    })}
                  </p>
                ) : (
                  caseEvent.shipped && (
                    <p className={colorClass}>{t("cases.notReceived")}</p>
                  )
                )}
              </>
            ) : (
              <p className={colorClass}>
                <b>{t("dates.finishDate")}</b>
              </p>
            )}
            <p className="cal-list-case-title">
              <h3 style={{ marginLeft: 0 }}>
                <CaseName {...caseEvent} />
              </h3>
            </p>
            <p className="cal-list-case-title" title={caseEvent.caseName}>
              {caseEvent.caseName}
            </p>
          </IonLabel>
          <p className="case-status ion-no-margin">
            <CaseStatusIcon
              {...caseEvent}
              id={caseEvent.caseId}
              onChange={status => onStatusChange(caseEvent.caseId, status)}
            />
          </p>
        </IonItem>
      )}
    </>
  );
};

export default CalendarListItem;
