import { UserError } from "../models/Errors";
import { API_URL } from "./Constants";
import { useAuthContext } from "../context/AuthProvider";
import { saveAs } from "file-saver";
import useTranslation from "../context/LanguageProvider";
import { useCallback } from "react";

const createTokenHeader: (token?: string) => HeadersInit | undefined = (
  token?: string
) => (token ? { Authorization: `Bearer ${token}` } : undefined);
const createContentHeader: (
  nonJsonBody?: boolean
) => HeadersInit | undefined = (nonJsonBody?: boolean) =>
  nonJsonBody ? undefined : { "Content-Type": "application/json" };

const createHeaders = (token?: string, nonJsonBody?: boolean) => ({
  headers: new Headers({
    Accept: "application/json",
    ...createTokenHeader(token),
    ...createContentHeader(nonJsonBody)
  })
});

const extractError = async (r: Response) => {
  if (r.status > 500) {
    throw new UserError(
      r.status,
      "An unexpected error has occured, please write to our support with the description and the steps to reproduce it."
    );
  }

  if (r.status === 400) {
    const error = await r.json();
    throw new UserError(error.code, error.message);
  }
  if (r.status === 403) {
    throw new UserError(r.status, "You are not allowed to see this resource!");
  }
  if (r.status === 404) {
    throw new UserError(r.status, "The resource does not exist");
  }

  const errorText = await r.text();
  throw new Error(errorText);
};

const unwrapResponse = async <TResult,>(r: Response) => {
  if (!r.ok) await extractError(r);

  const json = await r.json();
  return json as TResult;
};

const getFileName = (r: Response, defaultValue: string) => {
  const headers = r.headers.get("content-disposition");
  var filename = headers?.match(/filename="(.+)"/);
  // sometimes the filename omits the ""
  if (!filename) filename = headers?.match(/filename=(.+)/);

  return filename ? filename[1] : defaultValue;
};

const unwrapBlobResponse = async (r: Response) => {
  if (!r.ok) await extractError(r);

  const blob = await r.blob();
  var fileName = getFileName(r, "logo");

  return { blob, fileName };
};

const unwrapImageResponse = async (r: Response) => {
  if (!r.ok) await extractError(r);
  if (r.status === 204) return undefined;

  const blob = await r.blob();
  var filename = getFileName(r, "logo");

  return new File([blob], filename);
};

const fetchNoUnwrap = async (
  token: string | undefined,
  body: any,
  path: string,
  method: string,
  nonJsonBody?: boolean,
  language?: string
) => {
  const headers = createHeaders(token, nonJsonBody);
  language && headers.headers.set("lng", language);

  const bodyString = body
    ? nonJsonBody
      ? body
      : JSON.stringify(body)
    : undefined;

  return fetch(API_URL + path, {
    ...headers,
    method,
    body: bodyString
  });
};

const apiFetch = async <TResult,>(
  method: string,
  path: string,
  token?: string,
  body?: any,
  nonJsonBody?: boolean,
  language?: string
) => {
  const result = await fetchNoUnwrap(
    token,
    body,
    path,
    method,
    nonJsonBody,
    language
  );
  return await unwrapResponse<TResult>(result);
};

const apiBlobDownload = async (
  method: string,
  path: string,
  token?: string,
  body?: any,
  language?: string
) => {
  const { blob, fileName } = await apiBlobGet(
    method,
    path,
    token,
    body,
    language
  );

  // Download the file
  saveAs(blob, fileName);
};

const apiBlobGet = async (
  method: string,
  path: string,
  token?: string,
  body?: any,
  language?: string
) => {
  const result = await fetchNoUnwrap(
    token,
    body,
    path,
    method,
    false,
    language
  );
  return await unwrapBlobResponse(result);
};

const apiGetImage = async (path: string, token?: string) => {
  const result = await fetch(API_URL + path, {
    ...createHeaders(token, true),
    method: "GET"
  });

  return await unwrapImageResponse(result);
};

const useApi = () => {
  const { user } = useAuthContext();
  const { language } = useTranslation();

  const apiGet = useCallback(
    async <TResult,>(path: string) => {
      const token = await user?.getIdToken();
      return await apiFetch<TResult>(
        "GET",
        path,
        token,
        undefined,
        undefined,
        language
      );
    },
    [user, language]
  );
  const apiPost = useCallback(
    async <TResult,>(path: string, body: any) => {
      const token = await user?.getIdToken();
      return await apiFetch<TResult>(
        "POST",
        path,
        token,
        body,
        undefined,
        language
      );
    },
    [user, language]
  );
  const apiPut = useCallback(
    async <TResult,>(path: string, body: any) => {
      const token = await user?.getIdToken();
      return await apiFetch<TResult>(
        "PUT",
        path,
        token,
        body,
        undefined,
        language
      );
    },
    [user, language]
  );
  const apiDelete = useCallback(
    async <TResult,>(path: string) => {
      const token = await user?.getIdToken();
      return await apiFetch<TResult>(
        "DELETE",
        path,
        token,
        undefined,
        undefined,
        language
      );
    },
    [user, language]
  );

  const apiBFetch = useCallback(
    async (method: string, path: string, body: any) => {
      const token = await user?.getIdToken();
      return await apiBlobDownload(method, path, token, body, language);
    },
    [user, language]
  );

  const apiBGet = useCallback(
    async (method: string, path: string, body: any) => {
      const token = await user?.getIdToken();
      return await apiBlobGet(method, path, token, body, language);
    },
    [user, language]
  );

  const apiPostMultiForm = useCallback(
    async <TResult,>(path: string, body: FormData) => {
      const token = await user?.getIdToken();
      return await apiFetch<TResult>("POST", path, token, body, true, language);
    },
    [user, language]
  );
  const apiPutMultiForm = useCallback(
    async <TResult,>(path: string, body: FormData) => {
      const token = await user?.getIdToken();
      return await apiFetch<TResult>("PUT", path, token, body, true, language);
    },
    [user, language]
  );
  const apiGetImg = useCallback(
    async (path: string) => {
      const token = await user?.getIdToken();
      return await apiGetImage(path, token);
    },
    [user]
  );

  return {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
    apiBlobDownload: apiBFetch,
    apiGetBlob: apiBGet,
    apiGetImage: apiGetImg,
    apiPostMultiForm,
    apiPutMultiForm
  };
};

export { apiFetch, apiGetImage };
export default useApi;
