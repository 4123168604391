import { IonButton, IonIcon } from "@ionic/react";
import { logoFacebook } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../../context/AuthProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import useLocalStorage from "../../hooks/useLocalStorage";

const FacebookLoginButton: React.FC = () => {
  const { facebookLogin } = useAuthContext();
  const { handleError } = useNotificationContext();
  const history = useHistory();
  const [invitationCode] = useLocalStorage("invitationCode", "");

  const loginLocal = () => {
    facebookLogin()
      .then(() => invitationCode && history.push("/invitation"))
      .catch(handleError);
  };

  return (
    <IonButton
      style={{
        "--background": "#3b5998"
      }}
      type="submit"
      expand="block"
      size="default"
      onClick={loginLocal}
    >
      <IonIcon icon={logoFacebook} slot="start" />
      Facebook
    </IonButton>
  );
};

export default FacebookLoginButton;
