import React, {
  useEffect,
  useState,
  ReactNode,
  useContext,
  useCallback
} from "react";
import {
  firebaseAuth,
  extractUserInfo,
  hasIdInToken,
  signInWithGoogle,
  currentUser,
  signInWithFacebook
} from "../data/firebase";
import {
  User as FbUser,
  sendEmailVerification,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  onAuthStateChanged
} from "firebase/auth";
import { RegisterData, User } from "../models/User";
import { apiFetch } from "../data/Api";
import { APP_URL } from "../data/Constants";
import usePushNotifications from "../data/pushNotifications";
import { useNotificationContext } from "./NotificationProvider";
import { UserError } from "../models/Errors";

interface ContextProps {
  user: User | undefined;
  authenticated: boolean;
  loadingAuthState: boolean;
  labId: number | undefined;
  register: (data: RegisterData, code: string | null) => Promise<void>;
  login: (email: string, pass: string) => Promise<void>;
  googleLogin: () => Promise<void>;
  facebookLogin: () => Promise<void>;
  logout: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resetUser: () => Promise<void>;
  ensureNoLoadingState: () => void;
}

const AuthContext = React.createContext<ContextProps>({
  user: undefined,
  authenticated: false,
  loadingAuthState: false,
  labId: undefined,
  register: _ => Promise.resolve(),
  login: _ => Promise.resolve(),
  googleLogin: () => Promise.resolve(),
  facebookLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  forgotPassword: _ => Promise.resolve(),
  resetUser: () => Promise.resolve(),
  ensureNoLoadingState: () => {}
});

const useAuthContext = () => useContext(AuthContext);

const sendUserToSocialProof = async (fbUser: FbUser) => {
  try {
    const res = await fetch("https://www.cloudflare.com/cdn-cgi/trace");
    const data = await res.text();
    const loc = data
      .split("\n")
      .filter(el => el.startsWith("loc="))
      .join("")
      .split("=")[1];
    const ip = data
      .split("\n")
      .filter(el => el.startsWith("ip="))
      .join("")
      .split("=")[1];

    const names = fbUser.displayName?.split(" ") ?? [];
    const userData = {
      email: fbUser.email ?? "test@gmail.com",
      firstName: names.length > 0 ? names[0] : undefined,
      lastName: names.length > 1 ? names[1] : undefined,
      ip: ip,
      countryCode: loc
    };

    await fetch(
      "https://api.provesrc.com/webhooks/track/8e343bff0e9696cd30071712142f8aeb",
      {
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        method: "POST",
        body: JSON.stringify(userData)
      }
    );
  } catch (err) {
    // ignore errors
  }
};

const ensureUserId = async (fbUser: FbUser) => {
  // check if the user has an ID
  if (await hasIdInToken(fbUser)) return;

  sendUserToSocialProof(fbUser);

  // create user in our database
  const token = await fbUser.getIdToken(true);
  await apiFetch<number>("POST", "user/create", token);
};

interface Props {
  children: ReactNode | ReactNode[] | Element;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const { deleteToken } = usePushNotifications();
  const { handleError } = useNotificationContext();

  const setUserInfoFromUser = useCallback(async (fbUser: FbUser | null) => {
    if (!fbUser) {
      setUser(undefined);
      return;
    }

    const localUser = await extractUserInfo(fbUser);

    setUser(localUser);
  }, []);

  useEffect(
    () =>
      onAuthStateChanged(firebaseAuth, async fbUser => {
        try {
          if (fbUser && !user) {
            // this is a login
            await ensureUserId(fbUser);
          }
          await setUserInfoFromUser(fbUser);
          setLoadingAuthState(false);
        } catch (err) {
          err instanceof Error && handleError(err);
        }
      }),
    []
  );

  const login = useCallback((email: string, pass: string) => {
    setLoadingAuthState(true);
    return signInWithEmailAndPassword(firebaseAuth, email, pass)
      .then(() => {}) // small hack to make it Promise<void>
      .catch(error => {
        setLoadingAuthState(false);

        console.log(error);
        // if (error.code === "auth/wrong-password") {
        //   throw new UserError(1601, error.message);
        // }
        throw new UserError(1600, error.message);
      });
  }, []);

  const register = useCallback(
    async (data: RegisterData, code: string | null) => {
      try {
        setLoadingAuthState(true);
        const { user } = await createUserWithEmailAndPassword(
          firebaseAuth,
          data.email,
          data.password
        );
        if (user && user !== null) {
          await updateProfile(user, {
            displayName: `${data.firstName} ${data.lastName}`
          });

          // TODO: Check this for more options https://firebase.google.com/docs/auth/web/passing-state-in-email-actions
          const actionCodeSettings = {
            url: code
              ? `${APP_URL}invitation?code=${code}`
              : `${APP_URL}new-lab/`
            // TODO: Add options for opening Continue button in the app
          };
          // we can send the email and create the user in the same time
          await sendEmailVerification(user, actionCodeSettings);
        }
      } catch (e) {
        setLoadingAuthState(false);
        throw e;
      }
    },
    []
  );

  const googleLogin = useCallback(() => {
    setLoadingAuthState(true);
    return signInWithGoogle()
      .then(() => {}) // small hack to make it Promise<void>
      .catch(e => {
        setLoadingAuthState(false);
        throw e;
      });
  }, []);

  const facebookLogin = useCallback(() => {
    setLoadingAuthState(true);
    return signInWithFacebook()
      .then(() => {}) // small hack to make it Promise<void>
      .catch(e => {
        setLoadingAuthState(false);
        throw e;
      });
  }, []);

  const forgotPassword = useCallback(
    (email: string) => sendPasswordResetEmail(firebaseAuth, email),
    []
  );

  const logout = useCallback(() => {
    setLoadingAuthState(true);
    return firebaseAuth
      .signOut()
      .then(deleteToken)
      .catch(e => {
        setLoadingAuthState(false);
        throw e;
      });
  }, [deleteToken]);

  const resetUser = useCallback(async () => {
    try {
      setLoadingAuthState(true);
      const fbUser = currentUser();
      await setUserInfoFromUser(fbUser);
    } finally {
      setLoadingAuthState(false);
    }
  }, [setUserInfoFromUser]);

  const ensureNoLoadingState = useCallback(
    () => loadingAuthState && setLoadingAuthState(false),
    [loadingAuthState]
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: user ? true : false,
        loadingAuthState,
        labId: user?.labId,
        register,
        login,
        googleLogin,
        facebookLogin,
        forgotPassword,
        logout,
        resetUser,
        ensureNoLoadingState
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuthContext };
