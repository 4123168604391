import React, { useContext, useEffect, useState } from "react";
import en from "antd/es/date-picker/locale/en_US";
import cs from "antd/es/date-picker/locale/cs_CZ";
import de from "antd/es/date-picker/locale/de_DE";
import da from "antd/es/date-picker/locale/da_DK";
import el from "antd/es/date-picker/locale/el_GR";
import es from "antd/es/date-picker/locale/es_ES";
import fi from "antd/es/date-picker/locale/fi_FI";
import fr from "antd/es/date-picker/locale/fr_FR";
import hr from "antd/es/date-picker/locale/hr_HR";
import hu from "antd/es/date-picker/locale/hu_HU";
import it from "antd/es/date-picker/locale/it_IT";
import nb from "antd/es/date-picker/locale/nb_NO";
import nl from "antd/es/date-picker/locale/nl_NL";
import pl from "antd/es/date-picker/locale/pl_PL";
import pt from "antd/es/date-picker/locale/pt_PT";
import ro from "antd/es/date-picker/locale/ro_RO";
import ru from "antd/es/date-picker/locale/ru_RU";
import sv from "antd/es/date-picker/locale/sv_SE";
import sr from "antd/es/date-picker/locale/sr_RS";
import tr from "antd/es/date-picker/locale/tr_TR";
import { Locale } from "antd/lib/locale-provider";
import useTranslation, { Language } from "./LanguageProvider";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";

interface ContextProps {
  locale: PickerLocale;
}

const AntdContext = React.createContext<ContextProps>({
  locale: en
});

const chooseAntdLocale = (language: Language) => {
  switch (language) {
    case "cs":
      return cs;
    case "de":
      return de;
    case "da":
      return da;
    case "el":
      return el;
    case "es":
      return es;
    case "fi":
      return fi;
    case "fr":
      return fr;
    case "hr":
      return hr;
    case "hu":
      return hu;
    case "it":
      return it;
    case "nb":
      return nb;
    case "nl":
      return nl;
    case "pl":
      return pl;
    case "pt":
      return pt;
    case "ro":
      return ro;
    case "ru":
      return ru;
    case "sr":
      return sr;
    case "sv":
      return sv;
    case "tr":
      return tr;

    default:
      return en;
  }
};

const AntdProvider: React.FC = ({ children }) => {
  const { language } = useTranslation();
  const [locale, setLocale] = useState(en);

  useEffect(() => {
    setLocale(chooseAntdLocale(language));
  }, [language]);

  return (
    <AntdContext.Provider
      value={{
        locale
      }}
    >
      {children}
    </AntdContext.Provider>
  );
};

const useAntdLocale = () => useContext(AntdContext);

export default AntdContext;
export { AntdProvider, useAntdLocale };
