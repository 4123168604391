import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonButton,
  IonContent,
  IonListHeader,
  IonLabel,
  IonBackButton
} from "@ionic/react";
import Can from "./Can";
import { Permission } from "../models/Permissions";
import useTranslation from "../context/LanguageProvider";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Icon from "./Icon";
import ButtonTextIcon from "./ButtonTextIcon";
import SearchBar from "./SearchBar";

export interface SearchPageProps {
  title: string;
  addNewPermission: Permission;
  children: React.ReactNode;
  additionalToolbar?: React.ReactNode;
  searchTerm: string;
  addNewRouterLink?: string;
  addNewTitle?: string;
  hideBackButton?: boolean;
  hideMenuButton?: boolean;
  noDefaultBackLink?: boolean;
  onAddClicked?: () => void;
  setSearchTerm: (term: string) => void;
  onCancel?: () => void;
}

const SearchPage: React.FC<SearchPageProps> = ({
  title,
  addNewPermission,
  additionalToolbar,
  searchTerm,
  children,
  addNewRouterLink,
  addNewTitle,
  hideBackButton,
  hideMenuButton,
  noDefaultBackLink,
  onAddClicked,
  setSearchTerm,
  onCancel
}) => {
  const [showSearchbar, setShowSearchbar] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <SearchBar
            showSearchbar={showSearchbar}
            onSearch={setSearchTerm}
            onCancel={() => setShowSearchbar(false)}
          >
            <IonButtons slot="start">
              {onCancel ? (
                <IonButton onClick={onCancel}>
                  <ButtonTextIcon button="cancel" />
                </IonButton>
              ) : (
                !hideMenuButton && <IonMenuButton />
              )}
              {!hideBackButton && (
                <IonBackButton
                  defaultHref={noDefaultBackLink ? undefined : "/settings"}
                />
              )}
            </IonButtons>
            <IonTitle onClick={() => setShowSearchbar(true)}>{title}</IonTitle>

            <IonButtons slot="primary">
              <IonButton onClick={() => setShowSearchbar(true)}>
                <Icon slot="icon-only" icon={faSearch} />
              </IonButton>
              <Can permission={addNewPermission}>
                <IonButton routerLink={addNewRouterLink} onClick={onAddClicked}>
                  <Icon icon={faPlus} />
                  <span className="ion-hide-sm-down">
                    {addNewTitle ? addNewTitle : t("add")}
                  </span>
                </IonButton>
              </Can>
            </IonButtons>
          </SearchBar>
        </IonToolbar>
        {additionalToolbar}
      </IonHeader>
      <IonContent>
        <IonListHeader hidden={!showSearchbar || !searchTerm}>
          <IonLabel>
            {t("searchResultsFor")} <b>{searchTerm}</b>
          </IonLabel>
        </IonListHeader>
        {children}
      </IonContent>
    </>
  );
};

export default SearchPage;
