import {
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  useIonViewWillEnter
} from "@ionic/react";
import React, { useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { faPaperclip, faPlus } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import { searchMatch } from "../../data/stringHelpers";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import { ExtraDto } from "../../models/Product";
import SearchPage from "../SearchPage";
import useTranslation from "../../context/LanguageProvider";
import NoResults from "../NoResults";
import ExtrasUpsertModal from "./ExtrasUpsertModal";
import useCurrency from "../../hooks/useCurrency";

const Extras: React.FC = () => {
  const [showNoResults, setShowNoResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [initialExtrasModalData, setInitialExtrasModalData] =
    useState<ExtraDto>({ id: 0, price: 0, name: "" });

  const [extras, setExtras] = useState<ExtraDto[]>([]);
  const { showSuccessToast } = useNotificationContext();

  const { formatWithCurrencySign } = useCurrency();

  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const { t } = useTranslation();

  const fetchExtras = () => {
    apiGet<ExtraDto[]>("extras/getAll")
      .then(extras => {
        if (extras.length === 0) {
          setShowNoResults(true);
        } else {
          setShowNoResults(false);
          setExtras(extras);
        }
      })
      .catch(handleError);
  };
  useIonViewWillEnter(() => {
    fetchExtras();
  });

  return (
    <IonPage>
      <SearchPage
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        title={t("extras.title")}
        addNewPermission={Permission.ProductsCreate}
        onAddClicked={() => setShowUpsertModal(true)}
        hideMenuButton
      >
        <IonList className="box lines" hidden={showNoResults}>
          {extras
            .filter(d => searchMatch(searchTerm, d.name))
            .map(d => (
              <IonItem key={d.id} button detail routerLink={"extra/" + d.id}>
                <Icon slot="start" icon={faPaperclip} />
                <IonLabel>{d.name}</IonLabel>
                <p slot="end">
                  {d.price !== undefined && formatWithCurrencySign(d.price)}
                </p>
              </IonItem>
            ))}
        </IonList>
        <NoResults title={showNoResults ? t("extras.noData") : ""} />
        <Can permission={Permission.ProductsCreate}>
          <div className="ion-text-center">
            <IonButton onClick={() => setShowUpsertModal(true)}>
              <Icon icon={faPlus} />
              {t("extras.new")}
            </IonButton>
          </div>
        </Can>

        <ExtrasUpsertModal
          showModal={showUpsertModal}
          initialData={initialExtrasModalData}
          onCancel={() => {
            setShowUpsertModal(false);
          }}
          onSuccess={async id => {
            setShowUpsertModal(false);
            fetchExtras();
            showSuccessToast(t("addedMessage"));
            setInitialExtrasModalData({ id: 0, price: 0, name: "" });
          }}
        />
      </SearchPage>
    </IonPage>
  );
};

export default withPermission(Extras, Permission.ProductsRead);
