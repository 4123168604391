import React, { useState } from "react";
import Form from "../form/Form";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import PaymentDto from "../../models/Payment";
import PaymentType from "../../models/PaymentType";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import { getEnumValues } from "../../data/numberHelpers";
import ButtonTextIcon from "../ButtonTextIcon";
import useCurrency from "../../hooks/useCurrency";
import ModalWrapper from "../ModalWrapper";
import FormInput from "../form/FormIonInput";
import FormIonSelect from "../form/FormIonSelect";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showPaymentModal: boolean;
  initialData?: PaymentDto;
}
const PaymentUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showPaymentModal
}) => {
  const [submit, setSubmit] = useState<() => void>(() => {});
  const [isSubmitting, setSubmitting] = useState(false);
  const { currencySign } = useCurrency();
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tRequired, tPaymentType, tPlaceholder } = useTranslation();

  const upsertPayment = (data: PaymentDto) => {
    return apiPost<number>("payment/upsert", data)
      .then(onSuccess)
      .catch(handleError);
  };

  return (
    <ModalWrapper
      modalOpened={showPaymentModal}
      dismiss={onCancel}
      modal="paymentUpsert"
    >
      <IonModal isOpen={showPaymentModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            {initialData && (
              <IonTitle>
                {initialData.id > 0 ? t("payments.edit") : t("payments.add")}
              </IonTitle>
            )}
            <IonButtons slot="primary">
              <IonButton onClick={submit} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Form
            initialData={initialData}
            onSubmit={upsertPayment}
            submitButtonText={t("save")}
            exposeSubmit={setSubmit}
            setSubmitting={setSubmitting}
          >
            <FormInput name="id" label="" hidden />
            <FormInput name="doctorId" label="" hidden />
            <FormInput name="invoiceId" label="" hidden />
            <FormIonSelect
              name="paymentType"
              label={t("payments.type")}
              rules={{
                required: tRequired("payments.type"),
                valueAsNumber: true
              }}
              okText={t("ok")}
              cancelText={t("dismiss")}
              interface="action-sheet"
            >
              {getEnumValues(PaymentType).map(type => (
                <IonSelectOption key={type} value={type}>
                  {tPaymentType(type)}
                </IonSelectOption>
              ))}
            </FormIonSelect>
            <FormInput
              name="amount"
              label={t("amount") + " (" + currencySign + ")"}
              rules={{
                required: tRequired("amount"),
                valueAsNumber: true
              }}
              clearInput
              type="number"
              max={initialData?.amount.toFixed(2)}
              min="0.01"
              step="0.01"
            />

            <FormInput
              name="notes"
              label={t("notes")}
              placeholder={tPlaceholder("notes")}
            />
          </Form>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default PaymentUpsertModal;
