import React from "react";
import { Permission } from "../models/Permissions";
import { useAuthContext } from "../context/AuthProvider";

interface Props {
  permission: Permission;
  children: React.ReactNode;
}

const Can: React.FC<Props> = ({ permission, children }) => {
  const { user } = useAuthContext();

  // return component if the permission is there
  if (user && user.hasPermission(permission)) return <>{children}</>;

  return null;
};

export default Can;
