import React from "react";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core/components";

interface Props {
  onRefresh: (onRefresh: CustomEvent<RefresherEventDetail>) => void;
}

const Refresher: React.FC<Props> = ({ onRefresh }) => {
  return (
    <IonRefresher slot="fixed" onIonRefresh={e => onRefresh(e)}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
  );
};

export default Refresher;
