import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonBackButton,
  IonText,
  useIonViewWillEnter
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import InvitationDto from "../../models/Invitation";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import withPermission from "../../data/withPermission";
import useTranslation from "../../context/LanguageProvider";
import ValueLabel from "../ValueLabel";
import MiddleBox from "../MiddleBox";
import Icon from "../Icon";
import { APP_URL } from "../../data/Constants";
import { faLink } from "@fortawesome/free-solid-svg-icons";

interface InvitationUrlProps
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const ViewInvitation: React.FC<InvitationUrlProps> = ({ match }) => {
  const { apiGet, apiDelete } = useApi();
  const [invitation, setInvitation] = useState<InvitationDto>();
  const { handleError, showAlert, showSuccessToast } = useNotificationContext();
  const history = useHistory();
  const { t, tInterpolated, tRole } = useTranslation();

  const getInvitation = () =>
    apiGet<InvitationDto>("invitation/get?id=" + match.params.id)
      .then(setInvitation)
      .catch(handleError);

  useIonViewWillEnter(getInvitation);

  const createInvitationLink = (code: string) =>
    `${APP_URL}invitation?code=${code}`;

  const copyInvitationCodeToClipboard = (code: string) =>
    navigator.clipboard
      .writeText(createInvitationLink(code))
      .then(() =>
        showSuccessToast(
          tInterpolated("copied", { prop: createInvitationLink(code) })
        )
      )
      .catch(handleError);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/employees" />
          </IonButtons>
          <IonTitle>{t("invitation.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {invitation && (
          <MiddleBox className="box ion-text-center">
            <p className="ion-margin-bottom">
              {t("viewInvitation.invitationCodeInstructions")}
            </p>
            <ValueLabel title={t("invitation.code")}>
              <h3>
                {invitation.invitationCode}
                {"\t"}
                {!invitation.acceptedByUserId && (
                  <Icon
                    size="1x"
                    icon={faLink}
                    onClick={() =>
                      copyInvitationCodeToClipboard(invitation.invitationCode)
                    }
                    title={createInvitationLink(invitation.invitationCode)}
                  />
                )}
              </h3>
            </ValueLabel>

            <ValueLabel title={t("name")}>{invitation.name}</ValueLabel>
            <ValueLabel title={t("role")}>
              {tRole(invitation.roleId)}
            </ValueLabel>
            <ValueLabel title={t("email")}>{invitation.email}</ValueLabel>

            <ValueLabel title={t("status")}>
              {invitation.acceptedByUserId > 0 ? (
                <IonText color="success">{t("accepted")}</IonText>
              ) : (
                <IonText color="warning">{t("pending")}</IonText>
              )}
            </ValueLabel>

            {!invitation.acceptedByUserId && (
              <Can permission={Permission.InvitationsDelete}>
                <IonButton
                  color="danger"
                  onClick={() => {
                    showAlert({
                      title: t("confirm"),
                      message: t("viewInvitation.confirmDelete"),
                      buttons: [
                        {
                          text: t("cancel"),
                          role: "cancel",
                          handler: () => {}
                        },
                        {
                          text: t("confirm"),
                          handler: () => {
                            apiDelete(
                              "invitation/delete?id=" + match.params.id
                            ).then(() => {
                              showSuccessToast(
                                t("viewInvitation.invitationDeleted")
                              );
                              history.replace("/employees");
                            });
                          }
                        }
                      ]
                    });
                  }}
                >
                  {t("viewInvitation.deleteInvitation")}
                </IonButton>
              </Can>
            )}
          </MiddleBox>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withPermission(ViewInvitation, Permission.InvitationsRead);
