import React from "react";
import { Permission } from "../models/Permissions";
import { useAuthContext } from "../context/AuthProvider";

interface Props {
  permission: Permission;
  yes: () => React.ReactNode;
  no?: () => React.ReactNode;
}

const Has: React.FC<Props> = ({ permission, yes, no }) => {
  const { user } = useAuthContext();
  return <>{user?.hasPermission(permission) === true ? yes() : no && no()}</>;
};

export default Has;
