import React from "react";
import { IonImg } from "@ionic/react";

interface Props extends React.HTMLAttributes<HTMLIonImgElement> {
  lightBg?: boolean;
}

const Logo: React.FC<Props> = ({ lightBg, ...props }) => (
  <IonImg
    {...props}
    className="app-logo ion-justify-content-center"
    src={lightBg ? "assets/img/logo-dark.png" : "assets/img/logo.png"}
  />
);

export default Logo;
