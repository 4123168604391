import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonSkeletonText
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import useApi from "../../data/Api";
import DoctorDto, { DoctorInLabDto } from "../../models/Doctor";
import { useNotificationContext } from "../../context/NotificationProvider";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import { SortDirection } from "../../models/PagedData";
import useTranslation from "../../context/LanguageProvider";
import { useAuthContext } from "../../context/AuthProvider";
import DoctorUpsertModal from "./DoctorUpsertModal";
import InvoicesTable from "../invoice/InvoicesTable";
import CasesTable from "../case/CasesTable";
import ButtonTextIcon from "../ButtonTextIcon";
import { RefresherEventDetail } from "@ionic/core/components";
import DoctorInvitationModal from "./DoctorInvitationModal";
import DoctorInfo from "./DoctorInfo";

interface DoctorUrlProps
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const DoctorProfile: React.FC<DoctorUrlProps> = ({ match }) => {
  const { user } = useAuthContext();
  const [segment, setSegment] = useState<"info" | "cases" | "invoices">(
    user?.hasPermission(Permission.CasesTableRead) ? "cases" : "info"
  );
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [doctorData, setDoctorData] = useState<DoctorDto>();
  const [doctorAccess, setDoctorAccess] = useState<DoctorInLabDto>();
  const doctorId = useMemo(() => parseInt(match.params.id), [match.params.id]);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showInvitationModal, setShowInvitationModal] = useState(false);

  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const fetchDoctorAccess = useCallback(
    () =>
      apiGet<DoctorInLabDto>(`doctor/getLabAccess?id=${doctorId}`)
        .then(setDoctorAccess)
        .catch(handleError),
    [apiGet, handleError, doctorId]
  );

  const fetchDoctor = useCallback(
    (refreshEvent?: CustomEvent<RefresherEventDetail>) => {
      if (!doctorId) return;

      setLoading(true);
      fetchDoctorAccess();
      return apiGet<DoctorDto>(`doctor/get?id=${doctorId}`)
        .then(setDoctorData)
        .catch(handleError)
        .finally(() => {
          setLoading(false);
          if (refreshEvent) refreshEvent.detail.complete();
        });
    },
    [doctorId, fetchDoctorAccess, apiGet, handleError]
  );

  useEffect(() => {
    fetchDoctor();
  }, [fetchDoctor]);

  const onDoctorAccessChanged = useCallback(() => {
    fetchDoctorAccess();
    showSuccessToast(t("doctors.updated"));
    setShowInvitationModal(false);
  }, [fetchDoctorAccess, showSuccessToast, t]);

  const onDoctorUpserted = useCallback(() => {
    fetchDoctor();
    showSuccessToast(t("doctors.updated"));
    setShowEditModal(false);
  }, [fetchDoctor, showSuccessToast, t]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/doctors" />
          </IonButtons>
          <IonTitle>{doctorData?.name}</IonTitle>
          <IonButtons slot="primary">
            <Can permission={Permission.DoctorUpdate}>
              <IonButton onClick={() => setShowEditModal(true)}>
                <ButtonTextIcon button="edit" />
              </IonButton>
            </Can>
            <IonButton
              hidden={segment === "info"}
              color={showFilter ? "primary" : ""}
              onClick={() => setShowFilter(!showFilter)}
            >
              <ButtonTextIcon button="filter" />
            </IonButton>
            <Can permission={Permission.CaseCreate}>
              <IonButton routerLink={`/new-case?doctorId=${doctorId}`}>
                <ButtonTextIcon button="addCase" />
              </IonButton>
            </Can>
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="header_toolbar2">
          <IonSegment
            onIonChange={e => {
              setSegment(e.detail.value as any);
            }}
            value={segment}
          >
            <IonSegmentButton value={"info"}>
              <IonLabel>{t("info")}</IonLabel>
            </IonSegmentButton>
            <Can permission={Permission.CasesTableRead}>
              <IonSegmentButton value="cases">
                <IonLabel>{t("cases.title")}</IonLabel>
              </IonSegmentButton>
            </Can>
            <Can permission={Permission.InvoicesTableRead}>
              <IonSegmentButton value="invoices">
                <IonLabel>{t("invoices.title")}</IonLabel>
              </IonSegmentButton>
            </Can>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {segment === "info" && loading && (
          <IonSkeletonText animated title={t("loading")} />
        )}
        {segment === "info" && doctorData && (
          <DoctorInfo
            fetchDoctor={fetchDoctor}
            doctorData={doctorData}
            doctorAccess={doctorAccess}
            loading={loading}
            setShowEditModal={setShowEditModal}
            setShowInvitationModal={setShowInvitationModal}
          />
        )}
        {segment === "invoices" && doctorId && (
          <>
            <InvoicesTable
              initialUrlProps={{
                page: 1,
                doctorId: doctorId,
                pageSize: 10,
                status: null,
                sortBy: "created",
                sortDirection: SortDirection.Desc
              }}
              mode="doctorInvoices"
              showFilterProp={showFilter}
              searchProp=""
            />
          </>
        )}
        {segment === "cases" && doctorId && (
          <>
            <CasesTable
              initialUrlProps={{
                page: 1,
                doctorId: doctorId,
                pageSize: 10,
                status: null,
                sortBy: "received",
                sortDirection: SortDirection.Desc
              }}
              mode="doctorCases"
              showFilterProp={showFilter}
              searchProp=""
            />

            <Can permission={Permission.CaseCreate}>
              <div className="ion-text-center ion-margin-top">
                <IonButton routerLink={`/new-case?doctorId=${doctorId}`}>
                  {t("cases.new")}
                </IonButton>
              </div>
            </Can>
          </>
        )}
        {doctorData && (
          <DoctorUpsertModal
            initialData={doctorData}
            showDoctorModal={showEditModal}
            onSuccess={onDoctorUpserted}
            onCancel={() => setShowEditModal(false)}
          />
        )}
        {doctorAccess && (
          <DoctorInvitationModal
            initialData={doctorAccess}
            showDoctorInvitationModal={showInvitationModal}
            onSuccess={onDoctorAccessChanged}
            onCancel={() => setShowInvitationModal(false)}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default withPermission(DoctorProfile, Permission.DoctorRead);
