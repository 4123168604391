import { toothIdInLower, toothIdInUpper } from "../../hooks/useDentalNotation";
import { CaseHasConditionDto, CaseProductDto } from "../../models/Case";
import { ProductType, ToothProps } from "../../models/Teeth";

type Action =
  | {
      type: "setCondition";
      newTeeth: ToothProps[];
    }
  | { type: "deleteCondition"; newTeeth: ToothProps[] }
  | { type: "deleteConditionFromTooth"; newTeeth: ToothProps[] }
  | { type: "fullUpper" }
  | { type: "fullLower" }
  | { type: "unselect" }
  | { type: "toothSelectChange"; toothId: number }
  | { type: "reset"; data: ArchesData }
  | { type: "deleteSelected"; newProducts: CaseProductDto[] }
  | { type: "deleteProduct"; newProducts: CaseProductDto[] }
  | { type: "onProductSelectClose" }
  | { type: "onProductUpsert"; newProducts: CaseProductDto[] };

interface ArchesData {
  teeth: ToothProps[];
  products: CaseProductDto[];
  teethConditions: CaseHasConditionDto[];
  anySelected: boolean;
  productSelected: number;
  anyConditionSelected: boolean;
  conditionSelected?: number;
  canBuildBridge: boolean;
  canAddVisil?: boolean;
}

const toothInUpper = (tooth: ToothProps) => toothIdInUpper(tooth.id);
const toothInLower = (tooth: ToothProps) => toothIdInLower(tooth.id);

const findSelectedIndices = (teeth: ToothProps[]) =>
  teeth
    .map((t, i) => ({ index: i, selected: t.selected }))
    .filter(t => t.selected)
    .map(t => t.index)
    .sort((a, b) => a - b);

const findFirstAndLastIndex = (teeth: ToothProps[]) => {
  const selectedIndices = findSelectedIndices(teeth);
  if (selectedIndices.length <= 1) return undefined;

  const first = selectedIndices[0];
  const last = selectedIndices[selectedIndices.length - 1];
  return { first, last };
};

const hasProductInBetweenSelected = (teeth: ToothProps[]) => {
  const firstLast = findFirstAndLastIndex(teeth);
  if (!firstLast) return false;

  return (
    teeth
      .slice(firstLast.first, firstLast.last)
      .find(t => t.productId !== undefined && t.type !== ProductType.Visil) !==
    undefined
  );
};

const canBuildBridge = (teeth: ToothProps[]) => {
  const selected = teeth.filter(t => t.selected);
  return (
    !hasProductInBetweenSelected(teeth) &&
    selected.length === 2 &&
    (selected.every(toothInUpper) || selected.every(toothInLower))
  );
};

const canAddVisil = (teeth: ToothProps[], products: CaseProductDto[]) => {
  const selected = teeth.filter(t => t.selected);
  const visils = products.filter(t => t.productTypeId === ProductType.Visil);

  return (
    selected.length > 0 &&
    // all selected teeth are in the UPPER and visil has not been added yet
    ((selected.every(toothInUpper) &&
      !visils.some(v => v.toothIds.some(toothIdInUpper))) ||
      // all selected teeth are in the LOWER and visil has not been added yet
      (selected.every(toothInLower) &&
        !visils.some(v => v.toothIds.some(toothIdInLower))))
  );
};

export const calculateQuantity = (product: CaseProductDto) => {
  return product.productTypeId === ProductType.Bridge ||
    product.productTypeId === ProductType.Crown ||
    product.productTypeId === ProductType.Inlay
    ? product.toothIds.length
    : 1;
};

const teethStateUpdate: (data: ArchesData) => ArchesData = (
  data: ArchesData
) => {
  const anySelected = data.teeth.find(t => t.selected) !== undefined;
  return {
    ...data,
    anySelected: anySelected,
    productSelected:
      findSelectedProductsIds(data.teeth, data.products).find(pid => pid) ?? 0,
    anyConditionSelected: anySelected
      ? data.teeth.find(t =>
          t.selected === true ? t.condition !== undefined : false
        ) !== undefined
      : false,
    canBuildBridge: canBuildBridge(data.teeth),
    canAddVisil: canAddVisil(data.teeth, data.products)
  };
};

export const summarizeConditions = (teeth: ToothProps[]) =>
  teeth
    .filter(t => t.condition)
    .map(t => ({ toothId: t.id, conditionId: t.condition! }));

/**
 * Creates new teeth and conditions.
 * @param data The current state of the data.
 * @param conditionChanged Wether the teeth conditions have changed or we should take them from the teethConditions list.
 * @return The new state of the data.
 */
export const buildTeeth = (data: ArchesData, conditionChanged: boolean) => {
  const newTeeth = data.teeth.map(t => {
    const product = data.products
      .filter(p => p.productTypeId !== ProductType.Visil) // we don't want to show Visil on teeth
      .find(product => product.toothIds.includes(t.id));
    // the condition is either in the conditions or provided by the tooth itself
    const conditionId = conditionChanged
      ? t.condition
      : data.teethConditions.find(c => c.toothId === t.id)?.conditionId;

    return {
      ...t,
      selected: false,
      type: product?.productTypeId,
      shade: product?.shade,
      productId: product?.productId,
      condition: conditionId
    };
  });

  return teethStateUpdate({
    ...data,
    teeth: newTeeth,
    teethConditions: summarizeConditions(newTeeth)
  });
};

export const findSelectedProductsIds = (
  teeth: ToothProps[],
  products: CaseProductDto[]
) => {
  const selectedIds = teeth.filter(t => t.selected).map(t => t.id);
  return products
    .filter(p => p.productTypeId !== ProductType.Visil)
    .filter(p => p.toothIds.find(ptId => selectedIds.includes(ptId)))
    .map(p => p.id);
};

export const findTeethForProductSelect = (
  teeth: ToothProps[],
  productType: ProductType
) => {
  switch (productType) {
    case ProductType.Bridge:
      const firstLast = findFirstAndLastIndex(teeth);
      if (!firstLast) return undefined;
      const { first, last } = firstLast;

      const bridgeTeeth = teeth
        .filter((_, i) => i >= first && i <= last)
        .map(t => t.id);

      return bridgeTeeth;

    case ProductType.Denture:
    case ProductType.Miscellaneous:
    case ProductType.Crown:
    case ProductType.Inlay:
    case ProductType.Visil:
    default:
      return teeth.filter(p => p.selected).map(t => t.id);
  }
};

const archesReducer = (data: ArchesData, action: Action): ArchesData => {
  switch (action.type) {
    case "setCondition":
      return buildTeeth(
        {
          ...data,
          teeth: action.newTeeth
        },
        true
      );
    case "deleteCondition":
      return buildTeeth(
        {
          ...data,
          teeth: action.newTeeth
        },
        true
      );
    case "deleteConditionFromTooth":
      return buildTeeth(
        {
          ...data,
          teeth: action.newTeeth
        },
        true
      );

    case "toothSelectChange":
      const teeth = data.teeth.map(t =>
        t.id === action.toothId ? { ...t, selected: !t.selected } : t
      );
      return teethStateUpdate({
        ...data,
        teeth
      });
    case "unselect":
      return {
        ...data,
        teeth: data.teeth.map(t => ({ ...t, selected: false })),
        anySelected: false,
        canBuildBridge: false,
        productSelected: 0,
        anyConditionSelected: false
      };
    case "fullUpper":
      return teethStateUpdate({
        ...data,
        teeth: data.teeth.map(t => ({ ...t, selected: toothInUpper(t) }))
      });
    case "fullLower":
      return teethStateUpdate({
        ...data,
        teeth: data.teeth.map(t => ({ ...t, selected: toothInLower(t) }))
      });

    case "onProductSelectClose":
      return data;
    case "onProductUpsert":
      return buildTeeth(
        {
          ...data,
          products: action.newProducts,
          anySelected: false,
          productSelected: 0,
          canBuildBridge: false
        },
        false
      );

    case "reset":
      return buildTeeth(action.data, false);
    case "deleteProduct":
      return buildTeeth(
        {
          ...data,
          products: action.newProducts
        },
        false
      );
    case "deleteSelected":
      const newData = {
        ...data,
        teeth: data.teeth.map(t =>
          t.selected ? { ...t, condition: undefined } : t
        ),
        products: action.newProducts
      };

      return buildTeeth(newData, true);
  }
};

export default archesReducer;
