import React from "react";
import { ProductionLogStatus } from "../../models/Case";
import Icon from "../Icon";
import {
  faCheck,
  faClock,
  faCog,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";

export const productionLogStatusColor = (status: ProductionLogStatus) => {
  switch (status) {
    case ProductionLogStatus.Started:
      return "mediumColor";
    case ProductionLogStatus.Done:
      return "successColor";
    case ProductionLogStatus.Failed:
      return "dangerColor";
    case ProductionLogStatus.Scheduled:
      return "mediumColor";
    default:
      return "";
  }
};

interface Props {
  status: ProductionLogStatus;
  taskId: number | null;
  scheduled?: string;
}

const getColor = (date: string) => {
  const now = new Date();
  const scheduledDate = new Date(date);
  const diff = Math.round(
    (now.getTime() - scheduledDate.getTime()) / (1000 * 60)
  );
  if (diff < 0 && diff > -360) return "warningColor";
  if (diff > 0) return "dangerColor";
  return "mediumColor";
};

const ProductionLogIcon: React.FC<Props> = ({ status, taskId, scheduled }) => {
  if (!taskId) return <Icon icon={faComment} className="mediumColor" />;

  switch (status) {
    case ProductionLogStatus.Started:
      return <Icon spin icon={faCog} className="secondaryColor" />;
    case ProductionLogStatus.Done:
      return <Icon icon={faCheck} className="successColor" />;
    case ProductionLogStatus.Failed:
      return <Icon icon={faTimes} className="dangerColor" />;
    case ProductionLogStatus.Scheduled:
      return (
        <Icon
          icon={faClock}
          className={scheduled ? getColor(scheduled) : "mediumColor"}
        />
      );
    default:
      return <></>;
  }
};

export default ProductionLogIcon;
