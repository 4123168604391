import { IonGrid, IonRow, IonCol } from "@ionic/react";
import React from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
  hidden?: boolean;
}

const MiddleBox: React.FC<Props> = ({ children, className, hidden }) => {
  return (
    <IonGrid hidden={hidden}>
      <IonRow>
        <IonCol
          className={className}
          size-sm="10"
          offset-sm="1"
          size-md="6"
          offset-md="3"
          size-xl="4"
          offset-xl="4"
        >
          {children}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MiddleBox;
