import React, { useState } from "react";
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import { IonList, IonItem, IonLabel, IonButton, IonText } from "@ionic/react";
import useTranslation from "../../context/LanguageProvider";
import { CaseViewDto } from "../../models/Case";
import Icon from "../Icon";
import Can from "../Can";
import { Permission } from "../../models/Permissions";
import useLab from "../../context/LabProvider";
import CaseMaterialsUpsertModal from "./CaseMaterialsUpsertModal";
import { useNotificationContext } from "../../context/NotificationProvider";
import NoResults from "../NoResults";

interface Props {
  $case: CaseViewDto;
  onChange: () => void;
}

const CaseMaterials: React.FC<Props> = ({ $case, onChange }) => {
  const { t } = useTranslation();
  const { materials } = useLab();
  const { showSuccessToast } = useNotificationContext();
  const [showEditMaterials, setShowEditMaterials] = useState(false);

  return (
    <>
      <IonList class="box ion-no-padding" hidden={!materials.length}>
        {$case.materials.map(d => {
          const material = materials.find(m => m.id === d.materialId);
          return (
            <IonItem
              key={d.materialId}
              lines="none"
              button
              detail
              routerLink={`/material/${d.materialId}`}
            >
              <Icon
                slot="start"
                size="1x"
                className="ion-hide-sm-down"
                icon={faPuzzlePiece}
              />
              <IonLabel>{material?.name}</IonLabel>
              <IonText slot="end">
                {d.quantity} {material?.unit}
              </IonText>
            </IonItem>
          );
        })}
        {$case.materials.length === 0 && <NoResults title={t("noData")} />}
      </IonList>

      <Can permission={Permission.CaseUpdate}>
        <div className="ion-text-center ion-margin-top">
          <IonButton
            onClick={() => setShowEditMaterials(true)}
            color="secondary"
          >
            <Icon icon={faPuzzlePiece} />
            {t($case.materials.length ? "edit" : "add")}
          </IonButton>
        </div>
      </Can>

      <CaseMaterialsUpsertModal
        showModal={showEditMaterials}
        initialData={$case}
        onCancel={() => setShowEditMaterials(false)}
        onSuccess={() => {
          setShowEditMaterials(false);
          onChange();
          showSuccessToast("Materials updated");
        }}
      />
    </>
  );
};

export default CaseMaterials;
