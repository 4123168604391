import React from "react";
import { IonRouterLink } from "@ionic/react";
import { getCaseColor } from "../../data/calendarColorHelpers";
import useTranslation from "../../context/LanguageProvider";
import useDate from "../../hooks/useDate";
import CalendarCaseDto from "../../models/CalendarDto";
import { CaseStatus } from "../../models/Case";
import CaseFlagIcon from "../case/CaseFlagIcon";
import CaseStatusIcon from "../case/CaseStatusIcon";

interface Props {
  caseData: CalendarCaseDto;
  hideTime?: boolean;
  date: string;
  onStatusChange: (caseId: number, status: CaseStatus) => void;
}
const CaseEvent: React.FC<Props> = ({
  caseData,
  date,
  onStatusChange,
  hideTime
}) => {
  const { t } = useTranslation();
  const { toTimeString } = useDate();

  return (
    <>
      <div
        className={`case_dot fc-daygrid-event-dot ${
          getCaseColor(caseData)?.colorClass
        }`}
      />
      <div
        className={
          (caseData.tryoutId ? "tryout_date" : "finish_date") +
          " case_event " +
          getCaseColor(caseData)?.bgClass
        }
      >
        <IonRouterLink
          routerLink={"/case/" + caseData.caseId}
          title={t("calendar.clickToOpenCase")}
        >
          <span className="ion-margin-bottom">
            <span className="cal-event-title">
              {caseData.doctorName}
              <i>{caseData.patient && `, ${caseData.patient}`}</i>
            </span>
          </span>
        </IonRouterLink>

        <IonRouterLink
          routerLink={"/case/" + caseData.caseId}
          title={t("calendar.clickToOpenCase")}
        >
          <span
            className={`cal-event-sub-title  ion-margin-top2`}
            // className={`cal-event-title ${colorClass}`}
            // color="medium"
            // title={t("clickToOpenCase")}
          >
            <CaseFlagIcon flag={caseData.flag} />
            {caseData.pan?.length && <b>{caseData.pan}, </b>}
            {caseData.caseName}
            <br />
          </span>
        </IonRouterLink>
        <span className="case-status ion-no-margi3n cal-event-sub-title">
          <CaseStatusIcon
            {...caseData}
            id={caseData.caseId}
            onChange={status => onStatusChange(caseData.caseId, status)}
          />
        </span>
        <span className="cal-event-sub-title">
          <span>
            {!hideTime &&
              (caseData.tryoutId
                ? toTimeString(date) + ", "
                : toTimeString(date))}
            {caseData.tryoutId && <b>{caseData.tryoutName}</b>}
          </span>
        </span>
      </div>
    </>
  );
};

export default CaseEvent;
