import React from "react";

export interface BridgeProps {
  path: string;
}

interface Props extends BridgeProps {}

const BridgeConnection: React.FC<Props> = ({ path }) => {
  const style = {
    strokeWidth: 15,
    stroke: "#810c0c",
    fill: "#00ADB5"
  };
  return <path d={path} {...style} strokeLinecap="round"></path>;
};

export default BridgeConnection;
