import React, { useEffect, useState } from "react";
import { CaseStatus } from "../../models/Case";
import Icon from "../Icon";
import {
  faBox,
  faCheckCircle,
  faCog,
  faDoorOpen,
  faEdit,
  faHourglassHalf,
  faPause,
  faTeethOpen,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import { IonAlert, IonRouterLink } from "@ionic/react";
import { useAuthContext } from "../../context/AuthProvider";
import { getEnumValues } from "../../data/numberHelpers";
import useTranslation from "../../context/LanguageProvider";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { Permission } from "../../models/Permissions";

interface Props {
  id: number;
  status: CaseStatus;
  underConstruction: boolean;
  hideTitle?: boolean;
  className?: string;
  onChange?: (status: CaseStatus) => void;
}

const getProps = (status: CaseStatus, underConstruction: boolean) => {
  switch (status) {
    case CaseStatus.Finished:
      return {
        // hidden: true,
        className: "successColor",
        icon: faCheckCircle
      };
    case CaseStatus.OnHold:
      return {
        // hidden: true,
        className: "mediumColor",
        icon: faPause
      };
    case CaseStatus.InProgress:
      if (underConstruction)
        return {
          // hidden: true,
          className: "mediumColor",
          icon: faCog,
          spin: true
        };
      else
        return {
          // hidden: true,
          className: "secondaryColor",
          icon: faTeethOpen
        };
    case CaseStatus.OutOfLab:
      return {
        // hidden: true,
        className: "mediumColor",
        icon: faDoorOpen
      };
    case CaseStatus.Cancelled:
      return {
        className: "dangerColor",
        icon: faWindowClose
      };
    case CaseStatus.PendingAccept:
      return {
        className: "warningColor",
        icon: faHourglassHalf
      };
    case CaseStatus.ReadyForShippingToDr:
      return {
        className: "successColor",
        icon: faBox
      };
    case CaseStatus.ReadyForShippingToLab:
      return {
        className: "successColor",
        icon: faBox
      };
  }
};

const CaseStatusIcon: React.FC<Props> = ({
  id,
  status,
  underConstruction,
  hideTitle,
  className,
  onChange
}) => {
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { user } = useAuthContext();
  const { t, tCaseStatus } = useTranslation();
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [showReceivedFromClient, setShowReceiveFromTryout] = useState(false);

  const props = getProps(status, underConstruction);

  return (
    <>
      <IonRouterLink
        class={
          user!.doctorId === undefined ? "pointer mediumColor" : "mediumColor"
        }
        onClick={e => {
          if (
            user!.doctorId === undefined &&
            user!.hasPermission(Permission.CaseUpdate)
          )
            status === CaseStatus.OutOfLab ||
            status === CaseStatus.ReadyForShippingToLab
              ? setShowReceiveFromTryout(true)
              : setShowChangeStatus(true);
          e.stopPropagation();
        }}
      >
        <Icon
          {...props}
          title={tCaseStatus(status, user?.doctorId !== undefined)}
          className={className ?? props.className}
        />{" "}
        {hideTitle === undefined &&
          tCaseStatus(status, user?.doctorId !== undefined)}{" "}
        {hideTitle === undefined &&
          user!.hasPermission(Permission.CaseUpdate) && <Icon icon={faEdit} />}
      </IonRouterLink>
      <IonAlert
        isOpen={showChangeStatus}
        onDidDismiss={() => setShowChangeStatus(false)}
        header={t("cases.changeStatus")}
        inputs={getEnumValues(CaseStatus)
          .filter(s => s !== status)
          .filter(s =>
            user?.doctorId
              ? s !== CaseStatus.ReadyForShippingToDr
              : s !== CaseStatus.ReadyForShippingToLab
          )
          .filter(s => s !== CaseStatus.PendingAccept)
          .map(status => {
            return {
              type: "radio",
              value: status,
              label: tCaseStatus(status, user?.doctorId !== undefined)
            };
          })}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
            cssClass: "secondary"
          },
          {
            text: t("ok"),
            handler: newStatus => {
              apiPost<boolean>(
                `case/changeStatus?id=${id}&status=${newStatus}`,
                {}
              )
                .then(_ => onChange && onChange(newStatus))
                .catch(handleError);
            }
          }
        ]}
      />
      {showReceivedFromClient && (
        <IonAlert
          isOpen={showReceivedFromClient}
          onDidDismiss={() => setShowReceiveFromTryout(false)}
          header={t("cases.markAsReceivedHeader")}
          message={t("cases.markAsReceivedMessage")}
          buttons={[
            {
              text: t("cancel"),
              role: "cancel",
              cssClass: "secondary"
            },
            {
              text: t("cases.received"),
              handler: () => {
                apiPost<boolean>(`tryout/receive?caseId=${id}`, {})
                  .then(_ => onChange && onChange(CaseStatus.InProgress))
                  .catch(handleError);
              }
            }
          ]}
        />
      )}
    </>
  );
};

export default CaseStatusIcon;
