import { useIonViewDidLeave } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

interface Props {
  children: React.ReactNode;
  modalOpened: boolean;
  dismiss: () => void;
  modal: string;
}

const ModalWrapper: React.FC<Props> = ({
  children,
  modalOpened,
  dismiss,
  modal
}) => {
  const history = useHistory();
  const location = useLocation();
  const [urlPathUpdated, setUrlPathUpdated] = useState(false);

  useEffect(() => {
    //if modal is opened and no push to history
    if (modalOpened && !location.search.includes(modal + "=1")) {
      const apendChar = history.location.search.indexOf("?") > -1 ? "&" : "?";
      history.push(
        history.location.pathname +
          history.location.search +
          apendChar +
          modal +
          "=1"
      );
      setUrlPathUpdated(true);
    } else {
      // if modal is closed and there is url prop then go back
      if (location.search.includes(modal + "=1")) {
        history.goBack();
      } else {
        // dismiss();
      }
    }
  }, [modalOpened]);

  useIonViewDidLeave(() => {
    if (location.search.includes(modal + "=1")) {
      history.goBack();
    }
  });

  useEffect(() => {
    // if location is updated (back button pressed) and modal is closed
    if (!location.search.includes(modal + "=1") && urlPathUpdated) {
      dismiss();
    }
  }, [location]);
  return <>{children}</>;
};

export default ModalWrapper;
