import { IonRow, IonCol } from "@ionic/react";
import React, { useEffect, useState } from "react";
import useTranslation from "../../context/LanguageProvider";
import useCurrency from "../../hooks/useCurrency";
import useDate from "../../hooks/useDate";
import { ProductionLogSummaryDto } from "../../models/Case";
import Employee from "../../models/Employee";
import { ProductionLogTableUrlProps } from "../../models/UrlProps";
import ValueLabel from "../ValueLabel";

interface Props {
  urlProps: ProductionLogTableUrlProps;
  employees?: Employee[];
  data: ProductionLogSummaryDto;
}
const ProductionLogSummary: React.FC<Props> = ({
  data,
  employees,
  urlProps
}) => {
  const { formatWithCurrencySign } = useCurrency();
  const { t } = useTranslation();
  const { toDateString } = useDate();

  const [selectedEmployee, setSelectedEmployee] = useState(t("all"));
  const [selectedFrom, setSelectedFrom] = useState(t("notDefined"));
  const [selectedTo, setSelectedTo] = useState(t("notDefined"));

  useEffect(() => {
    if (employees) {
      const e = employees?.find(e => urlProps.employeeId === e.id);
      e
        ? setSelectedEmployee(e.firstName + " " + e.lastName)
        : setSelectedEmployee(t("all"));
    } else {
      // setSelectedEmployee(user);
    }
  }, [employees, t, urlProps.employeeId]);

  useEffect(() => {
    urlProps.fromDate
      ? setSelectedFrom(toDateString(urlProps.fromDate))
      : setSelectedFrom(t("notDefined"));
  }, [t, toDateString, urlProps.fromDate]);

  useEffect(() => {
    urlProps.toDate
      ? setSelectedTo(toDateString(urlProps.toDate))
      : setSelectedTo(t("notDefined"));
  }, [t, toDateString, urlProps.toDate]);

  return (
    <>
      <IonRow class="box">
        <IonCol className="ion-padding">
          {employees && (
            <ValueLabel title={t("employee")}>
              <b>{selectedEmployee}</b>
            </ValueLabel>
          )}
          <ValueLabel title={t("dates")}>
            {selectedFrom} - {selectedTo}
          </ValueLabel>
          <ValueLabel title={t("total")}>
            <b>{formatWithCurrencySign(data.total)}</b>
          </ValueLabel>
        </IonCol>

        <IonCol size-xs="12">
          <IonRow>
            <IonCol className="smaller-font">{t("task.title")}</IonCol>
            <IonCol className="smaller-font">
              {t("workLog.finishedSteps")}
            </IonCol>
            <IonCol className="smaller-font">
              {t("workLog.totalPieceRate")}
            </IonCol>
          </IonRow>
          {data.tasks
            .sort((a, b) => b.count - a.count)
            .map(step => (
              <IonRow key={step.id}>
                <IonCol>
                  <b>{step.name}</b>
                </IonCol>
                <IonCol>{step.count}</IonCol>
                <IonCol>
                  {step.total ? formatWithCurrencySign(step.total) : ""}
                </IonCol>
              </IonRow>
            ))}
        </IonCol>
      </IonRow>
    </>
  );
};
export default ProductionLogSummary;
