import moment from "moment";

/**
 * Calculates the difference in days between two dates
 * @param a First date (usually current)
 * @param b Second date
 * @returns A number in days
 */
export const dateDiffInDays = (a: Date, b: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc1 - utc2) / _MS_PER_DAY);
};

export const timeInPast = (date: Date) => {
  const diff = Math.round((new Date().getTime() - date.getTime()) / (1000 * 0));
  return diff > 0 ? true : false;
};

export const timeInFuture = (date: Date) => {
  const diff = Math.round(
    (new Date().getTime() - date.getTime()) / (1000 * 60)
  );
  return diff < 0 ? true : false;
};

export const deadlineIn6h = (date: Date) => {
  const diff = Math.round(
    (new Date().getTime() - date.getTime()) / (1000 * 60)
  );
  return diff < 0 && diff > -360 ? true : false;
};

export const dateRangeInPast = (current: moment.Moment) => {
  const customDate = moment().format("YYYY-MM-DD");
  return current && current < moment(customDate, "YYYY-MM-DD");
};

export const formatToIso8601 = (date: string) => {
  return moment(date, moment.ISO_8601).format();
};
