export enum CourierNotificationMethod {
  Sms = 1,
  Email = 2
}

export interface CourierFormDto {
  id: number;
  name: string;
  notificationMethod: CourierNotificationMethod;
  email?: string;
  phone?: string;
  smsTemplate?: string;
  emailTemplate?: string;
  active: boolean;
}

export interface CourierListDto extends CourierFormDto {
  created: string;
}

export default CourierFormDto;
