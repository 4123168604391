import InvoiceFormDto, { InvoiceItemDto } from "../../models/Invoice";

const toDecimal = (input: number, decimalPlaces = 2) =>
  parseFloat(input.toFixed(decimalPlaces));

const checkForZero = (value?: number) => (value ? value : 0);

const calculateItemTotal = (
  quantity?: number,
  price?: number,
  discount?: number
) =>
  toDecimal(
    checkForZero(quantity) *
      checkForZero(price) *
      (1 - checkForZero(discount) / 100)
  );

const calculateInvoiceItemTotal = (item: InvoiceItemDto) =>
  calculateItemTotal(item.quantity, item.price, item.discount);

const calculateInvoiceItemsTotals = (items: InvoiceItemDto[]) =>
  items.map(item => ({
    ...item,
    total: calculateInvoiceItemTotal(item)
  }));

const calculateTaxTotal = (total: number, taxRate: number) =>
  toDecimal((total * (taxRate / 100)) / (1 + taxRate / 100));

const calculateTotal = (items: InvoiceItemDto[]) =>
  items
    .map(calculateInvoiceItemTotal)
    .map(checkForZero)
    .reduce((total, item) => total + item, 0);

type Action =
  | { type: "fetch"; data: InvoiceFormDto }
  | { type: "caseUpdate"; data: Partial<InvoiceFormDto> }
  | { type: "setTax"; taxRate: number }
  | { type: "setNotes"; notes: string }
  | { type: "setNumber"; number: string }
  | { type: "countTotal" }
  | { type: "add" }
  | { type: "delete"; i: number }
  | { type: "set"; i: number; item: InvoiceItemDto };

/**
 * Function that manages all state changes for an Invoice.
 * @param invoice The current state of the Invoice.
 * @param action Action to be performed.
 * @returns The new state of the Invoice.
 */
const invoiceReducer = (invoice: InvoiceFormDto, action: Action) => {
  switch (action.type) {
    case "fetch":
      return {
        ...action.data,
        items: calculateInvoiceItemsTotals(action.data.items)
      };
    case "caseUpdate":
      return {
        ...invoice,
        ...action.data,
        items: calculateInvoiceItemsTotals(action.data.items ?? invoice.items)
      };
    case "setTax":
      return { ...invoice, taxRate: action.taxRate };
    case "setNotes":
      return { ...invoice, notes: action.notes };
    case "setNumber":
      return { ...invoice, number: action.number };
    case "countTotal":
      const total = calculateTotal(invoice.items);
      const taxTotal = calculateTaxTotal(total, invoice.taxRate);
      return {
        ...invoice,
        subTotal: toDecimal(total - taxTotal),
        taxTotal,
        total
      };
    case "add":
      return {
        ...invoice,
        items: [
          ...invoice.items,
          {
            id: 1,
            name: "",
            quantity: 1,
            price: 1,
            discount: 0,
            total: 1
          }
        ]
      };

    case "delete":
      // do not delete the last item
      if (invoice.items.length > 1) {
        return {
          ...invoice,
          items: invoice.items.filter((_, ind) => ind !== action.i)
        };
      }
      return invoice;

    case "set":
      return {
        ...invoice,
        items: invoice.items.map((current, ind) =>
          action.i === ind ? action.item : current
        )
      };
  }
};

export {
  toDecimal,
  calculateInvoiceItemTotal,
  calculateInvoiceItemsTotals,
  calculateTaxTotal,
  calculateTotal,
  invoiceReducer
};
