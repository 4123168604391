import React, { useReducer, useState, useCallback } from "react";
import { ToothCondition, ProductType } from "../../models/Teeth";
import { IonButton, IonCol, IonGrid, IonList, IonRow } from "@ionic/react";
import Icon from "../Icon";
import {
  faChevronDown,
  faChevronUp,
  faEraser,
  faInfoCircle,
  faUndo
} from "@fortawesome/free-solid-svg-icons";
import archesReducer, {
  buildTeeth,
  calculateQuantity,
  findSelectedProductsIds,
  findTeethForProductSelect,
  summarizeConditions
} from "./archesReducer";
import useTranslation from "../../context/LanguageProvider";
import ProductSelectModal from "./ProductSelectModal";
import useDentalNotation from "../../hooks/useDentalNotation";
import CaseFormDto, {
  CaseHasConditionDto,
  CaseProductDto
} from "../../models/Case";
import ProductList from "./ProductList";
import ButtonTextIcon from "../ButtonTextIcon";
import ArchesSvg from "./ArchesSvg";
import ConditionList from "./ConditionList";
import useLab from "../../context/LabProvider";
import { getRandomId } from "../../data/numberHelpers";

interface Props {
  $case: CaseFormDto;
  onProductsUpdated: (data: CaseProductDto[]) => void;
  onConditionsUpdated: (data: CaseHasConditionDto[]) => void;
}

const Arches: React.FC<Props> = ({
  $case,
  onProductsUpdated,
  onConditionsUpdated
}) => {
  const { t, tProductType } = useTranslation();
  const { products: labProducts } = useLab();
  const [productSelectOpen, setProductSelectOpen] = useState(false);
  const [productSelectData, setProductSelectData] = useState<CaseProductDto>({
    id: 0,
    productId: 0,
    toothIds: [],
    productTypeId: 1,
    shade: "",
    quantity: 0,
    extras: []
  });

  const { teeth: starterTeeth } = useDentalNotation();
  const [
    {
      teeth,
      products,
      teethConditions,
      anySelected,
      productSelected,
      anyConditionSelected,
      canBuildBridge,
      canAddVisil
    },
    dispatch
  ] = useReducer(
    archesReducer,
    buildTeeth(
      {
        teeth: starterTeeth,
        products: $case.products,
        teethConditions: $case.teethCondition,
        anySelected: false,
        productSelected: 0,
        anyConditionSelected: false,
        canBuildBridge: false
      },
      false
    )
  );

  const setCondition = useCallback(
    (status: ToothCondition) => {
      const newTeeth = teeth.map(t =>
        t.selected ? { ...t, condition: status, selected: false } : t
      );
      dispatch({ type: "setCondition", newTeeth });
      onConditionsUpdated(summarizeConditions(newTeeth));
    },
    [teeth]
  );
  const deleteCondition = useCallback(() => {
    const newTeeth = teeth.map(t =>
      t.selected ? { ...t, condition: undefined, selected: false } : t
    );
    dispatch({ type: "deleteCondition", newTeeth });
    onConditionsUpdated(summarizeConditions(newTeeth));
  }, [teeth]);
  const deleteConditionFromTooth = useCallback((toothId: number) => {
    const newTeeth = teeth.map(t =>
      t.id === toothId ? { ...t, condition: undefined } : t
    );
    dispatch({ type: "deleteConditionFromTooth", newTeeth });
    onConditionsUpdated(summarizeConditions(newTeeth));
  }, []);

  const editCaseProduct = useCallback(
    (id: number) => {
      const p = products.find(p => p.id === id);
      if (p) {
        setProductSelectData(p);
        setProductSelectOpen(true);
      }
    },
    [products]
  );

  const addCaseProduct = useCallback(
    (type: ProductType) => {
      const toothIds = findTeethForProductSelect(teeth, type);
      if (!toothIds) return;

      setProductSelectData({
        id: 0,
        productId: 0,
        toothIds: toothIds,
        productTypeId: type,
        shade: "",
        quantity: 0,
        extras: []
      });

      setProductSelectOpen(true);
    },
    [teeth]
  );

  const onToothClick = useCallback(
    (id: number) => dispatch({ type: "toothSelectChange", toothId: id }),
    []
  );

  const fullUpper = useCallback(() => dispatch({ type: "fullUpper" }), []);
  const fullLower = useCallback(() => dispatch({ type: "fullLower" }), []);

  const unselect = useCallback(() => dispatch({ type: "unselect" }), []);

  const deleteSelected = useCallback(() => {
    const selectedProducts = findSelectedProductsIds(teeth, products);
    const newProducts = products.filter(p => !selectedProducts.includes(p.id));
    dispatch({ type: "deleteSelected", newProducts });
  }, [products, teeth]);
  const deleteCaseProduct = useCallback(
    (id: number) => {
      const newProducts = products.filter(p => p.id !== id);
      dispatch({ type: "deleteProduct", newProducts });

      onProductsUpdated(products);
    },
    [products]
  );
  const onProductSelectClose = useCallback(() => {
    dispatch({ type: "onProductSelectClose" });
    setProductSelectOpen(false);
  }, []);

  const onProductUpsert = useCallback(
    (data: CaseProductDto) => {
      const newProduct = {
        ...data,
        id: getRandomId(products.map(p => p.id)),
        quantity: calculateQuantity(data)
      };
      const newProducts =
        data.id === 0
          ? [...products, newProduct]
          : products.map(p => (p.id === data.id ? newProduct : p));

      dispatch({ type: "onProductUpsert", newProducts: newProducts });
      setProductSelectOpen(false);
      onProductsUpdated(newProducts);
    },
    [products]
  );

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size-xs="12" size-sm="10" size-md="6" size-lg="7" size-xl="4">
            <IonButton
              size="small"
              fill="clear"
              hidden={anySelected}
              color="medium"
              className="select-full-upper no-icon-margin"
              onClick={fullUpper}
            >
              <Icon size="2x" icon={faChevronUp} />
            </IonButton>
            <IonButton
              size="small"
              fill="clear"
              hidden={anySelected}
              color="medium"
              className="select-full-lower no-icon-margin"
              onClick={fullLower}
            >
              <Icon size="2x" icon={faChevronDown} />
            </IonButton>
            <IonButton
              size="small"
              fill="clear"
              hidden={!anySelected}
              color="medium"
              className="unselect-tooth-arches no-icon-margin"
              onClick={unselect}
            >
              <Icon size="2x" icon={faUndo} />
            </IonButton>

            <div hidden={anySelected} className="please-select-tooth">
              {products.length > 0
                ? t("arches.selectTeethOrProduct")
                : t("arches.selectTeeth")}
            </div>
            <ArchesSvg
              products={products}
              teeth={teeth}
              onToothClick={onToothClick}
            />
          </IonCol>
          <IonCol
            size-xs="12"
            size-sm="2"
            size-md="6"
            size-lg="5"
            size-xl="8"
            className="ion-no-padding"
          >
            <div
              hidden={products.length > 0 || anySelected}
              style={{ marginTop: 60 }}
              className="ion-text-center ion-margin-top border"
            >
              <h5 className="opacity-07">
                <Icon icon={faInfoCircle} className="secondaryColor" />{" "}
                {t("arches.pleaseAddProduct")}
              </h5>
            </div>
            <span hidden={!anySelected}>
              <span hidden={!productSelected}>
                <h3 className="form-header">{t("product")}</h3>
                <IonList className="ion-no-padding">
                  <IonButton
                    size="small"
                    fill="outline"
                    color="secondary"
                    onClick={() => editCaseProduct(productSelected)}
                  >
                    <ButtonTextIcon button="edit" />
                  </IonButton>

                  <IonButton
                    size="small"
                    fill="outline"
                    color="danger"
                    onClick={deleteSelected}
                  >
                    <ButtonTextIcon button="delete" />
                  </IonButton>
                </IonList>
              </span>
              <IonList className="ion-no-margin" hidden={productSelected > 0}>
                <h3 className="form-header">{t("arches.addNewProduct")}</h3>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => addCaseProduct(ProductType.Crown)}
                >
                  {tProductType(ProductType.Crown)}
                </IonButton>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!canBuildBridge}
                  onClick={() => addCaseProduct(ProductType.Bridge)}
                >
                  {tProductType(ProductType.Bridge)}
                </IonButton>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => addCaseProduct(ProductType.Inlay)}
                >
                  {tProductType(ProductType.Inlay)}
                </IonButton>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => addCaseProduct(ProductType.Denture)}
                >
                  {tProductType(ProductType.Denture)}
                </IonButton>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!canAddVisil}
                  onClick={() => addCaseProduct(ProductType.Visil)}
                >
                  {tProductType(ProductType.Visil)}
                </IonButton>
                <IonButton
                  size="small"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => addCaseProduct(ProductType.Miscellaneous)}
                >
                  {tProductType(ProductType.Miscellaneous)}
                </IonButton>
              </IonList>

              <h3 className="form-header">{t("arches.describeCondition")}</h3>
              <IonList className="ion-no-padding">
                <IonButton
                  size="small"
                  disabled={!anySelected}
                  onClick={() => setCondition(ToothCondition.NoTooth)}
                >
                  {t("arches.noTooth")}
                </IonButton>
                <IonButton
                  size="small"
                  disabled={!anySelected}
                  onClick={() => setCondition(ToothCondition.DeadTooth)}
                >
                  {t("arches.deadTooth")}
                </IonButton>
                <IonButton
                  size="small"
                  disabled={!anySelected}
                  onClick={() => {
                    setCondition(ToothCondition.Implant);
                  }}
                >
                  {t("arches.implant")}
                </IonButton>

                <IonButton
                  hidden
                  size="small"
                  fill="outline"
                  color="secondary"
                  disabled={!anySelected}
                  onClick={() => setCondition(ToothCondition.Hook)}
                >
                  {t("arches.hook")}
                </IonButton>
                <IonButton
                  size="small"
                  fill="outline"
                  color="danger"
                  hidden={!anyConditionSelected}
                  onClick={() => deleteCondition()}
                >
                  <Icon icon={faEraser} />
                  {t("arches.deleteCondition")}
                </IonButton>
              </IonList>
            </span>
            <h3 hidden={!products.length} className="form-header">
              {t("arches.productList")}
            </h3>
            <ProductList
              products={products}
              showActions
              onEdit={editCaseProduct}
              onDelete={deleteCaseProduct}
            />
            <h3 hidden={!teethConditions.length} className="form-header">
              {t("arches.conditionList")}
            </h3>
            <ConditionList
              conditions={teethConditions}
              showActions
              onDelete={deleteConditionFromTooth}
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      {labProducts && (
        <ProductSelectModal
          isOpen={productSelectOpen}
          initialData={productSelectData}
          onCancel={onProductSelectClose}
          onSuccess={onProductUpsert}
        />
      )}
    </>
  );
};

export default Arches;
