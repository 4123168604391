import React from "react";
import { IonContent, IonPage, IonButton, IonIcon } from "@ionic/react";
import { logOut } from "ionicons/icons";
import Logo from "../../components/Logo";
import { useAuthContext } from "../../context/AuthProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import useUrlSearchParams from "../../hooks/useUrlSearchParams";
import MiddleBox from "../MiddleBox";

const VerifyEmail: React.FC = () => {
  const { user, logout } = useAuthContext();
  const { showToast } = useNotificationContext();
  const { t, tInterpolated } = useTranslation();
  const code = useUrlSearchParams("code");

  return (
    <IonPage>
      <IonContent>
        <MiddleBox className="box">
          <Logo lightBg style={{ width: 130, margin: "20px auto" }} />
          <p>
            {tInterpolated("verifyEmail.description", {
              user: user ? ` (${user.email})` : ""
            })}
          </p>
          <IonButton
            expand="block"
            type="submit"
            onClick={() =>
              user
                ?.sendEmailVerification(code)
                .then(() => showToast({ message: t("verifyEmail.emailSent") }))
            }
          >
            {t("verifyEmail.resendVerification")}
          </IonButton>
          <IonButton expand="block" color="secondary" onClick={() => logout()}>
            <IonIcon icon={logOut}></IonIcon>
            {t("logout")}
          </IonButton>
        </MiddleBox>
      </IonContent>
    </IonPage>
  );
};

export default VerifyEmail;
