import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  useIonViewWillEnter,
  IonButton
} from "@ionic/react";
import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import Icon from "../Icon";
import { faEdit, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import MaterialUpsertModal from "./MaterialUpsertModal";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import MiddleBox from "../MiddleBox";
import ValueLabel from "../ValueLabel";
import { MaterialDto } from "../../models/Materials";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const Material: React.FC<Props> = ({ match }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [data, setData] = useState<MaterialDto>();

  const { apiGet } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const getMaterial = useCallback(() => {
    apiGet<MaterialDto>(`material/get?id=${match.params.id}`)
      .then(setData)
      .catch(handleError);
  }, [apiGet, handleError, match.params.id]);

  useIonViewWillEnter(() => {
    getMaterial();
  }, [match.params.id]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/materials" />
          </IonButtons>
          <IonTitle>{t("material")}</IonTitle>
          <IonButtons slot="primary">
            <Can permission={Permission.MaterialsUpdate}>
              <IonButton onClick={() => setShowEditModal(true)}>
                <ButtonTextIcon button="edit" />
              </IonButton>
            </Can>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data && (
          <>
            <MiddleBox className="box">
              <div className="ion-padding">
                <div className="ion-text-center ion-margin-top">
                  <Icon icon={faPuzzlePiece} size="4x" />
                  <h3>{data.name}</h3>
                  <ValueLabel title={t("materials.code")}>
                    {data.code !== undefined && data.code}
                  </ValueLabel>
                  <ValueLabel title={t("materials.unit")}>
                    {data.unit}
                  </ValueLabel>
                  <ValueLabel title={t("description")}>
                    {data.description !== undefined && data.description}
                  </ValueLabel>

                  <Can permission={Permission.MaterialsUpdate}>
                    <IonButton
                      color="secondary"
                      onClick={() => setShowEditModal(true)}
                    >
                      <Icon icon={faEdit} /> {t("edit")}
                    </IonButton>
                  </Can>
                </div>
              </div>
            </MiddleBox>

            {data && (
              <MaterialUpsertModal
                showModal={showEditModal}
                initialData={data}
                onCancel={() => setShowEditModal(false)}
                onSuccess={() => {
                  getMaterial();
                  showSuccessToast("Material data updated");
                  setShowEditModal(false);
                }}
              />
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withPermission(Material, Permission.MaterialsRead);
