enum Permission {
  NotSet = 0,
  AccessAll = 1,

  CasesTableRead = 10,
  DashboardRead = 11,
  CalendarRead = 12,
  DoctorsTableRead = 13,
  InvoicesTableRead = 15,
  ProductionLogsTableRead = 16,
  StatsRead = 17,

  DoctorNameRead = 20,
  PatientNameRead = 21,

  CaseCreate = 30,
  CaseRead = 31,
  CaseUpdate = 32,
  CaseDelete = 33,

  ProductionLogCreate = 40,
  ProductionLogRead = 41,
  ProductionLogUpdate = 42,
  ProductionLogDelete = 43,

  TryoutCreate = 50,
  TryoutRead = 51,
  TryoutUpdate = 52,
  TryoutDelete = 53,

  DoctorCreate = 60,
  DoctorRead = 61,
  DoctorUpdate = 62,
  DoctorDelete = 63,

  InvoiceCreate = 80,
  InvoiceRead = 81,
  InvoiceUpdate = 82,
  InvoiceDelete = 83,

  PaymentsCreate = 90,
  PaymentsRead = 91,
  PaymentsUpdate = 92,
  PaymentsDelete = 93,

  EmployeeRead = 101,
  EmployeeUpdate = 102,
  EmployeeDelete = 103,

  LabUpdate = 112,
  LabDelete = 113,

  InvitationsCreate = 120,
  InvitationsRead = 121,
  InvitationsDelete = 123,

  TaskCreate = 130,
  TaskRead = 131, // todo, check do we need it
  TaskUpdate = 132,
  TaskDelete = 133,

  ProductsCreate = 140,
  ProductsRead = 141,
  ProductsUpdate = 142,
  ProductsDelete = 143,

  SchedulingTemplateCreate = 150,
  SchedulingTemplateRead = 151,
  SchedulingTemplateUpdate = 152,
  SchedulingTemplateDelete = 153,

  SchedulingCreate = 160,
  SchedulingRead = 161,
  SchedulingUpdate = 162,
  SchedulingDelete = 163,

  SubscriptionsCreate = 170,
  SubscriptionsRead = 171,
  SubscriptionsUpdate = 172,
  SubscriptionsDelete = 173,

  MaterialsCreate = 180,
  MaterialsRead = 181,
  MaterialsUpdate = 182,
  MaterialsDelete = 183
}

interface CRUD {
  create?: Permission;
  createLabel?: string;
  read?: Permission;
  readLabel?: string;
  update?: Permission;
  delete?: Permission;
}
interface PermissionRow {
  title: string;
  desc?: string;
  permissions?: CRUD;
  // selected: Permission[]
}

const allPermisssions: PermissionRow[] = [
  { title: "pagesSubtitle", desc: "pagesSubtitleDesc" },
  // {
  //   title: "dashboard",
  //   permissions: {
  //     read: Permission.DashboardRead
  //   }
  // },
  // {
  //   title: "calendar",
  //   permissions: {
  //     read: Permission.CalendarRead
  //   }
  // },

  {
    title: "cases",
    permissions: {
      read: Permission.CasesTableRead
    }
  },
  {
    title: "workLogs",
    permissions: {
      read: Permission.ProductionLogsTableRead
    }
  },
  {
    title: "invoices",
    permissions: {
      read: Permission.InvoicesTableRead
    }
  },
  {
    title: "doctors",
    permissions: {
      read: Permission.DoctorsTableRead
    }
  },
  {
    title: "stats",
    permissions: {
      read: Permission.StatsRead
    }
  },

  { title: "dataSubtitle" },

  {
    title: "case",
    permissions: {
      create: Permission.CaseCreate,

      read: Permission.CaseRead,
      update: Permission.CaseUpdate,
      delete: Permission.CaseDelete
    }
  },
  {
    title: "doctorName",
    permissions: {
      read: Permission.DoctorNameRead
    }
  },
  {
    title: "patientName",
    permissions: {
      read: Permission.PatientNameRead
    }
  },
  {
    title: "tryout",
    permissions: {
      create: Permission.TryoutCreate,
      read: Permission.TryoutRead,
      update: Permission.TryoutUpdate,
      delete: Permission.TryoutDelete
    }
  },
  {
    title: "productionLog",
    permissions: {
      create: Permission.ProductionLogCreate,
      read: Permission.ProductionLogRead,
      update: Permission.ProductionLogUpdate,
      delete: Permission.ProductionLogDelete
    }
  },
  {
    title: "invoice",
    permissions: {
      create: Permission.InvoiceCreate,
      read: Permission.InvoiceRead,
      update: Permission.InvoiceUpdate,
      delete: Permission.InvoiceDelete
    }
  },
  {
    title: "payment",
    permissions: {
      create: Permission.PaymentsCreate,
      read: Permission.PaymentsRead,
      update: Permission.PaymentsUpdate,
      delete: Permission.PaymentsDelete
    }
  },
  {
    title: "doctor",
    permissions: {
      create: Permission.DoctorCreate,
      read: Permission.DoctorRead,
      update: Permission.DoctorUpdate,
      delete: Permission.DoctorDelete
    }
  },

  { title: "accountSettingsSubtitle" },

  {
    title: "tasks",
    permissions: {
      create: Permission.TaskCreate,
      read: Permission.TaskRead,
      update: Permission.TaskUpdate,
      delete: Permission.TaskDelete
    }
  },
  {
    title: "products",
    permissions: {
      create: Permission.ProductsCreate,
      read: Permission.ProductsRead,
      update: Permission.ProductsUpdate,
      delete: Permission.ProductsDelete
    }
  },
  {
    title: "materials",
    permissions: {
      create: Permission.MaterialsCreate,
      read: Permission.MaterialsRead,
      update: Permission.MaterialsUpdate,
      delete: Permission.MaterialsDelete
    }
  },
  {
    title: "employee",
    permissions: {
      read: Permission.EmployeeRead,
      update: Permission.EmployeeUpdate,
      delete: Permission.EmployeeDelete
    }
  },
  {
    title: "invitation",
    permissions: {
      create: Permission.InvitationsCreate,
      read: Permission.InvitationsRead,
      delete: Permission.InvitationsDelete
    }
  }
];

const unpackPermissionsFromString = (packedPermissions: string) => {
  if (!packedPermissions) return [];

  const permissions: Permission[] = [];
  for (let i = 0; i < packedPermissions.length; i++) {
    const code = packedPermissions.charCodeAt(i);
    permissions.push(code);
  }

  return permissions;
};

const containsPermission = (
  usersPermissions: Permission[],
  permissionToCheck: Permission
) =>
  usersPermissions.includes(permissionToCheck) ||
  usersPermissions.includes(Permission.AccessAll);

export {
  Permission,
  unpackPermissionsFromString,
  containsPermission,
  allPermisssions
};
