export enum BillingPeriod {
  Annual = 0,
  Monthly = 1
}

export enum SubscriptionBillingMethod {
  Cash = 0,
  BankTransfer = 1,
  Paypal = 2
}

export enum LicenseType {
  FreeTrial = 0,
  Individual = 1,
  M = 2,
  L = 3,
  XL = 4,
  Free = 10
}

export interface SubscriptionPlanDto {
  license: LicenseType;
  title: string;
  cases: number;
  currency: string;
  monthlyPrice: number;
  annualPrice: number;
}

export interface SubscriptionDto {
  caseCount: number;
  billingPeriod: BillingPeriod;
  billingMethod: SubscriptionBillingMethod;
  license: LicenseType;
  autoReccuring: boolean;
  expiryDate: string;
}

export enum DentalNotation {
  ISO = 1,
  Universal = 2,
  Palmer = 3
}

interface LabDto {
  id: number;
  name: string;
  phone: string | null;
  email: string | null;
  website: string | null;
  currency: string;
  taxRate: number;
  dentalNotation: DentalNotation;
  logo?: File;
  address?: string;
}

export enum BillingIntegrationType {
  SmartBill = 1,
  PayPal = 2
}

export interface BillingIntegrationViewDto {
  id: number;
  provider: BillingIntegrationType;
  clientId: string;
  created: string;
}

export interface BillingIntegrationFormDto {
  id: number;
  provider: BillingIntegrationType;
  clientId: string;
  secret: string;
}

export default LabDto;
