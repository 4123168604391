import DoctorDto from "./Doctor";
import Employee from "./Employee";
import { CaseRecordDto } from "./Case";
import PagedData from "./PagedData";
import { BillingIntegrationType } from "./Lab";

export enum InvoiceStatus {
  Unpaid = 0,
  PartlyPaid = 1,
  Paid = 2
}

export interface InvoiceItemDto {
  id: number;
  name: string;
  discount?: number;
  quantity?: number;
  price?: number;
  total: number;
}

export interface InvoicePaymentDto {
  id: number;
  amount: number;
  doctorId: number;
  invoiceId: number;
  notes: string;
  paymentType: number;
}

export interface InvoicePaymentListDto extends InvoicePaymentDto {
  created: string;
  updated: string;
}

export interface InvoiceSendingDataDto {
  invoiceId: number;
  sentBy: number;
  invoiceUrl: string;
  provider: BillingIntegrationType;
  created: string;
}

export interface InvoiceSendFormDto {
  invoiceId: number;
  clientId: string;
  clientName: string;
  issueDate: string;
  dueDate: string;
  notes: string;
  isDraft: boolean;
  isProforma: boolean;
  provider: BillingIntegrationType;
}

export interface InvoiceFormDto {
  id: number;
  caseId: number;
  doctorId: number;
  number?: string;
  taxRate: number;
  taxTotal?: number;
  subTotal?: number;
  total?: number;
  notes?: string;
  items: InvoiceItemDto[];
}
export interface InvoiceViewDto extends InvoiceFormDto {
  status: InvoiceStatus;
  case: CaseRecordDto;
  doctor: DoctorDto;
  taxRate: number;
  totalPaid: number;
  total: number;
  notes?: string;
  items: InvoiceItemDto[];
  payments: InvoicePaymentListDto[];
  sendingData?: InvoiceSendingDataDto;
  createdBy: number;
  createdByUser: Employee;
  created: string;
  updated: string;
  shipped?: string;
}

export interface InvoiceListDto {
  id: number;
  caseId: number;
  doctorId: number;
  number?: string;
  status: InvoiceStatus;
  total: number;
  totalPaid: number;
  notes?: string;
  created: string;
  updated: string;
  case: CaseRecordDto;
  doctor: DoctorDto;
}

export interface InvoiceSummaryDto {
  data: PagedData<InvoiceListDto>;
  total: number;
  totalPaid: number;
  balanceDue: number;
}
export interface InvoiceStatsDto {
  totals?: InvoiceChartDto;
  byDoctor?: InvoiceChartDto;
  paymentType: InvoiceDateChartPointDto[];
}

export interface InvoiceChartDto {
  invoices: InvoiceDateChartPointDto[];
  payments: InvoiceDateChartPointDto[];
}

export interface InvoiceDateChartPointDto {
  name: string;
  amount: number;
  type: string;
}

export default InvoiceFormDto;
