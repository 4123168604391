import { IonRouterLink } from "@ionic/react";
import React from "react";
import { getPlColor } from "../../data/calendarColorHelpers";
import useTranslation from "../../context/LanguageProvider";
import useDate from "../../hooks/useDate";
import { CalendarProductionLogDto } from "../../models/CalendarDto";
import { CaseStatus, ProductionLogStatus } from "../../models/Case";
import CaseFlagIcon from "../case/CaseFlagIcon";
import CaseStatusIcon from "../case/CaseStatusIcon";
import ProductionLogIcon from "../productionLog/ProductionLogIcon";

/*
PL = payment log
if status = null get PL where status = null and [created or finished or started or scheduled] >start && <end
if status = scheduled get PL where status = scheduled and [scheduled >start && <end]
if status = started get PL where status = started and ([started >start && <end] and scheduled = null) or (scheduled >start && <end)
if status = done or failed get by PL where  status = done (or failed) and ([finished >start && <end] and scheduled = null) or (scheduled >start && <end)

*/

interface Props {
  plData: CalendarProductionLogDto;
}
const PlEvent: React.FC<Props> = ({ plData }) => {
  const { t, tProductionLogStatus } = useTranslation();
  const { toTimeString } = useDate();

  return (
    <>
      <div
        className={
          getPlColor({ ...plData }).colorClass + " fc-daygrid-event-dot"
        }
      />

      <div className={getPlColor({ ...plData }).bgClass + " pl_event "}>
        {plData.status !== ProductionLogStatus.None && (
          <IonRouterLink>
            <span className={`cal-event-title`}>
              <ProductionLogIcon
                status={plData.status}
                taskId={plData.taskId}
                scheduled={plData.scheduled}
              />
              {plData.task && plData.task.name}
            </span>
          </IonRouterLink>
        )}

        {plData.status !== ProductionLogStatus.None && (
          <span className={`cal-event-sub-title`}>
            {`${toTimeString(plData.date)}`},{" "}
            {tProductionLogStatus(plData.status)}
          </span>
        )}
        <span className={`cal-event-sub-title`}>
          {plData.status === ProductionLogStatus.None && (
            <ProductionLogIcon
              status={plData.status}
              taskId={plData.taskId}
              scheduled={plData.scheduled}
            />
          )}
          {plData.notes && (
            <i>
              {plData.notes}
              <br />
            </i>
          )}
          {plData.user && (
            <b>
              {plData.user.firstName} {plData.user.lastName}
            </b>
          )}
        </span>
        <IonRouterLink
          routerLink={"/case/" + plData.case.id}
          title={t("calendar.clickToOpenCase")}
        >
          <span
            className={`cal-event-title smaller ion-margin-top`}
            // className={`cal-event-title ${colorClass}`}
            color="medium"
            // title={t("clickToOpenCase")}
          >
            <CaseFlagIcon flag={plData.case.flag} />
            {plData.case.name}
            <br />
          </span>
        </IonRouterLink>
        <span className="case-status ion-no-margin cal-event-sub-title">
          <CaseStatusIcon
            {...plData.case}
            underConstruction={plData.case.status === CaseStatus.InProgress}
          />
        </span>
      </div>
    </>
  );
};

export default PlEvent;
